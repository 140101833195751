import { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { NotificationHooks } from '../../../hooks';
import {
  activityNotificationBuilder,
  notificationActionTitle,
} from '../../../types/notification.types';
import { StakeholderType, TerminationCause } from '../../../types/stakeholderTypes';
import Loader from '../../Loader';
import { ResolvedNotification } from '../Notification';

export type ResolvedTabProps = {
  companyId: string;
};

export const ResolvedTab: FC<ResolvedTabProps> = ({ companyId }) => {
  const { notifications, fetchNextPage, hasNextPage } = NotificationHooks.useNotifications({
    companyId,
    resolved: true,
  });

  return (
    <InfiniteScroll
      className="flex w-full flex-col gap-2"
      dataLength={notifications.length || 0}
      hasMore={hasNextPage}
      loader={<Loader />}
      next={fetchNextPage}
      scrollableTarget="scroll-notifications-target"
    >
      {notifications.map(
        ({
          createdAt,
          action,
          company,
          stakeholderName = '',
          adminId,
          stakeholderType = StakeholderType.OTHER_INST,
          adminName = '',
          id,
        }) => (
          <ResolvedNotification
            createdAt={new Date(createdAt)}
            key={id}
            message={activityNotificationBuilder({
              action,
              adminName,
              stakeholderName,
              isAdminYou: adminId === company?.stakeholder?.id,
              stakeholderType,
              terminationType: TerminationCause.DEATH,
            })}
            requestDate={new Date(createdAt)}
            requestMessage=""
            requestType=""
            type={(action && notificationActionTitle[action]) || ''}
          />
        ),
      )}
    </InfiniteScroll>
  );
};
