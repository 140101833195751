import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../assets/icons';
import { useFormat } from '../../hooks';
import { PageRoute } from '../../types/pageTypes';
import { Snapshot } from '../../types/snapshot.types';
import { toNumber } from '../../utils/toNumber';
import { SummaryItem } from './SummaryItem';

export type OverAllStatusProps = {
  wrapperClassName?: string;
  snapshot?: Snapshot;
  shouldNavigate?: boolean;
};

export const OverAllStatus: FC<OverAllStatusProps> = ({
  wrapperClassName,
  snapshot,
  shouldNavigate = false,
}) => {
  const { format } = useFormat();
  const navigate = useNavigate();

  return (
    <div
      className={twMerge(
        'flex min-h-full flex-col items-center rounded-lg bg-gray-50 p-1',
        wrapperClassName,
      )}
    >
      <div className="flex w-full items-start justify-between gap-4 px-4 py-3">
        <span className="w-full text-left text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
          overall status
        </span>
        {shouldNavigate && (
          <ChevronDownIcon
            className="-rotate-90 transform cursor-pointer"
            onClick={() => navigate(PageRoute.CAP_TABLE)}
          />
        )}
      </div>

      <div className="flex h-full w-full flex-wrap gap-3 rounded-md bg-white p-3 shadow-sm max-lg:flex-col">
        <SummaryItem
          title="Post-Money Valuation"
          value={`$${toNumber(snapshot?.postMoneyValuation).toLocaleString('en-US')}`}
        />
        <SummaryItem
          title="valuation date"
          value={format(snapshot?.valuation?.date, 'dd/MM/yyyy') || '-'}
        />

        <SummaryItem
          title="Lifetime equity investment"
          value={toNumber(snapshot?.lifetimeInvestmentEquity).toLocaleString('en-US')}
        />
        <SummaryItem
          title="Issued shares"
          value={toNumber(snapshot?.issuedShares).toLocaleString('en-US')}
        />

        <SummaryItem
          title="Investors"
          value={toNumber(snapshot?.investorsCount).toLocaleString('en-US')}
        />

        <SummaryItem
          title="stakeholders"
          value={toNumber(snapshot?.stats?.stakeholders?.length).toLocaleString('en-US')}
        />

        <SummaryItem
          title="Diluted shares"
          value={toNumber(snapshot?.dilutedShares).toLocaleString('en-US')}
        />
      </div>
    </div>
  );
};
