import { FC, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useFakePagination, useLocale } from '../../../../../hooks';
import { stakeholderTypeTranslation } from '../../../../../translations/stakeholderTranslation';
import {
  Column,
  DetailsSortVariant,
  ShareClassColumns,
  StakeholderStats,
  ViewBy,
} from '../../../../../types/snapshot.types';
import { toRound } from '../../../../../utils/getRoundedNumber';
import { toNumber } from '../../../../../utils/toNumber';
import { AmountOfFetchedItems } from '../../../variables';
import { TableRow } from '../TableRow';
import { PaginationItem } from './PaginationItem';

type StakeholderTableItemProps = {
  visibleColumns: Column[];
  viewBy: Record<ViewBy, boolean>;
  fields: Record<ShareClassColumns, string | number | null>;
  stakeholders?: StakeholderStats[];
  className?: string;
};

export const ShareClassTableItem: FC<StakeholderTableItemProps> = ({
  viewBy,
  visibleColumns,
  fields,
  stakeholders = [],
  className,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsToFetch, setItemsToFetch] = useState(AmountOfFetchedItems.THREE);
  const { data, totalPages } = useFakePagination({
    currentPage,
    data: stakeholders,
    take: Number(itemsToFetch),
  });
  const { messagesLocale } = useLocale();
  const [isExpanded, setExpanded] = useState(false);

  const isStakeholdersEmpty = stakeholders.length === 0;

  return (
    <>
      <TableRow
        fields={fields}
        handleExpandRow={setExpanded}
        isExpanded={isExpanded}
        isExpandedRow={isStakeholdersEmpty}
        tdClassName={className}
        trClassName={twMerge(isExpanded && 'bg-brand-25')}
        type={DetailsSortVariant.BY_SHARE_CLASS}
        viewBy={viewBy}
        visibleColumns={visibleColumns}
      />
      {isExpanded &&
        data.map(
          (
            {
              diluted,
              dilutedPercentage,
              issued,
              issuedPercentage,
              name,
              votingPercentage,
              type,
              votingRight,
              shareClasses,
            },
            i,
          ) => (
            <TableRow
              className="bg-[#F9FAFB] shadow-[inset_2px_0px_0px_0px_#2565C8]"
              fields={{
                [Column.NAME]: name,
                [Column.SHARE_CLASS_NAME]: null,
                [Column.STAKEHOLDER_NAME]: name,
                [Column.TYPE]: messagesLocale[stakeholderTypeTranslation[type]],
                [Column.ISSUED]: toRound(issuedPercentage * 100),
                [Column.DILUTED]: toRound(dilutedPercentage * 100),
                [Column.VOTING]: toRound(votingPercentage * 100),
                [Column.NO_ISSUED]: issued.toLocaleString('en-US'),
                [Column.DILUTED_SHARES]: diluted.toLocaleString('en-US'),
                [Column.INVESTMENT]: (0).toLocaleString('en-US'),
                [Column.AVERAGE_SHARE_PRICE]:
                  shareClasses.reduce((acc, { shares }) => (acc += shares), 0) /
                  shareClasses.length,
                [Column.NO_OF_STAKEHOLDERS]: '-',
                [Column.VOTING_RIGHTS]: toNumber(votingRight).toLocaleString('en-US'),
              }}
              isExpandedRow={true}
              key={i}
              trClassName="bg-brand-25"
              type={DetailsSortVariant.BY_SHARE_CLASS}
              viewBy={viewBy}
              visibleColumns={visibleColumns}
            />
          ),
        )}

      {isExpanded && stakeholders.length > 3 && (
        <tr className="shadow-[inset_0px_-1px_0px_0px_#F2F4F7]">
          <td colSpan={visibleColumns.length}>
            <PaginationItem
              currentPage={currentPage}
              handleChangeCurrentPage={setCurrentPage}
              handleFetchAmountChange={setItemsToFetch}
              itemsToFetch={itemsToFetch}
              totalPages={totalPages}
            />
          </td>
        </tr>
      )}
    </>
  );
};
