import { FC } from 'react';

import { ExerciseIcon } from '../../../assets/icons';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../components/HoverCard';
import { TableRow, TableRowData } from '../../../components/Table';
import { useFormat, ValuationHooks, VestingTaskHooks } from '../../../hooks';
import {
  SharePlanType,
  sharePlanTypeTitle,
  sharePlanVestingTypeTitle,
} from '../../../types/pool-plans.types';
import { VestingTask } from '../../../types/vestingTasks.types';
import { toRound } from '../../../utils/getRoundedNumber';
import { percentage } from '../../../utils/percentage';
import { toNumber } from '../../../utils/toNumber';
import { SummaryItem } from '../SummaryItem';
import { InfoCell } from './InfoCell';
import {
  AllPlanColumnEnums,
  AllVestingColumnEnums,
  table,
  VestingExerciseStructureColumn,
} from './tableVariant';

type TableItemProps = {
  vestingTask: VestingTask;
  stakeholderId: string;
  type?: 'vestingInfo' | 'planInfo';
  columns: (AllVestingColumnEnums | AllPlanColumnEnums)[];
  companyId: string;
  onRequestExercise: (props: { id: string; type: SharePlanType }) => void;
} & Optional<{
  mode: 'phoneView';
  sharePlanType: SharePlanType;
}>;

export const VestingTableItem: FC<TableItemProps> = ({
  companyId,
  stakeholderId,
  columns = [],
  sharePlanType,
  type = 'planInfo',
  onRequestExercise,
  mode,
  vestingTask: {
    id,
    nextTriggerTime,
    grantItem: {
      plan: {
        vesting,
        basePrice,
        id: planId,
        name,
        hardleValue,
        type: planType,
        rsaPurchasePrice,
        strikePrice,
        warrantPrice,
        pool: { shareClass },
      },
    },
  },
}) => {
  const { format } = useFormat();

  const { valuation } = ValuationHooks.useCurrent({ companyId, enabled: type === 'planInfo' });

  const { vestingStructure } = VestingTaskHooks.useVestingStructure({
    companyId,
    planId,
    stakeholderId,
    enabled: type === 'vestingInfo',
  });

  const vestedPercentage = percentage(
    toNumber(vestingStructure?.granted),
    toNumber(vestingStructure?.granted) - toNumber(vestingStructure?.totalGrantVested),
  );

  const remainingPercentage = percentage(
    toNumber(vestingStructure?.granted),
    toNumber(vestingStructure?.granted) - toNumber(vestingStructure?.totalGrantVested),
  );

  const vestingRowData: TableRowData<AllVestingColumnEnums, { planType: SharePlanType }> = {
    remaining: (
      <InfoCell
        description={`(${toRound(remainingPercentage || 0)})%`}
        title={(
          toNumber(vestingStructure?.granted) - toNumber(vestingStructure?.totalGrantVested)
        ).toLocaleString('en-US')}
      />
    ),
    vested: (
      <InfoCell
        description={`(${toRound(vestedPercentage || 0)})%`}
        title={toNumber(vestingStructure?.totalGrantVested).toLocaleString('en-US')}
      />
    ),
    'full-vesting': 'None',
    exercised: toNumber(vestingStructure?.exercised).toLocaleString('en-US'),
    exercisable: toNumber(vestingStructure?.exercisable).toLocaleString('en-US'),
    granted: toNumber(vestingStructure?.granted).toLocaleString('en-US'),
    'to-be-vested': (
      toNumber(vestingStructure?.granted) -
      (toNumber(vestingStructure?.exercised) + toNumber(vestingStructure?.exercisable))
    ).toLocaleString('en-US'),
    'next-vesting': format(nextTriggerTime, 'dd/MM/yyyy'),
    type: vesting ? (
      <InfoCell
        description={sharePlanVestingTypeTitle[vesting.type].description}
        title={sharePlanVestingTypeTitle[vesting.type].name}
      />
    ) : (
      'Vesting in this plan is missing'
    ),
    'exercise-button': (id, { planType }) => (
      <HoverCard>
        <HoverCardTrigger className="h-fit w-fit">
          <div
            className="centered mr-5 flex size-8 rounded border border-[#F2F4F7]"
            style={{
              boxShadow: '0px 0.89px 1.78px 0px #1018280D',
            }}
          >
            <ExerciseIcon />
          </div>
          <HoverCardContent
            align="end"
            className="centered flex h-[34px] rounded-lg bg-[#101828] px-3 py-[2px] text-xs font-semibold text-white"
            onClick={() => onRequestExercise({ id, type: planType })}
          >
            Exercise
          </HoverCardContent>
        </HoverCardTrigger>
      </HoverCard>
    ),
    id,
    planType,
  };

  const planRowData: TableRowData<AllPlanColumnEnums, { planType: SharePlanType }> = {
    'base-price': basePrice,
    'current-price': valuation?.sharePrice,
    'current-valuation': valuation?.sharePrice,
    'grant-id': planId.split('-')[0].toUpperCase(),
    hurdle: hardleValue,
    plan: name,
    'plan-type': sharePlanTypeTitle[planType],
    'rsa-price': toNumber(rsaPurchasePrice),
    'share-class': shareClass.name,
    'strike-price': toNumber(strikePrice),
    'warrant-price': toNumber(warrantPrice),
    id,
    planType,
  };

  if (!mode) return <TableRow columns={columns} fields={{ ...vestingRowData, ...planRowData }} />;

  if (type === 'planInfo')
    return table[sharePlanType].planColumns.map((column) => (
      <SummaryItem
        id={planRowData.id}
        key={column}
        planType={planRowData.planType}
        title={table[sharePlanType].planColumnsTitle[column]}
        value={planRowData[column]}
      />
    ));

  return table[sharePlanType].vestingColumns.map(
    (column) =>
      column !== VestingExerciseStructureColumn.EXERCISE_BUTTON && (
        <SummaryItem
          id={planRowData.id}
          key={column}
          planType={planRowData.planType}
          title={table[sharePlanType].vestingColumnsTitle[column]}
          value={vestingRowData[column]}
        />
      ),
  );
};
