import React, { FC } from 'react';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { useFormat } from '../../../../hooks';
import { Snapshot } from '../../../../types/snapshot.types';
import { activeColors, fillColors, strokeColors } from '../../../CapTable/Graphs';
import { GraphVariant } from '../../variables';

type EventGraphProps = {
  snapshots: Snapshot[];
  variant: GraphVariant;
};

interface GraphData {
  date: string;
  postMoneyValuation: number;
  name: string;
  stakeholders: {
    id: string;
    name: string;
    shares: number;
    percentage: number;
    value: number;
  }[];
}

interface Stakeholder {
  id: string;
  name: string;
  shares: number;
  percentage: number;
  value: number;
}

interface CustomTickProps {
  x: number;
  y: number;
  payload: { value: string };
  isActive: boolean;
}

const CustomTick: FC<any> = ({ x, y, payload, isActive }: CustomTickProps) => {
  const isHighlighted = isActive ? '#1151B4' : '#475467';

  return (
    <text fill={isHighlighted} fontSize={10} fontWeight={500} textAnchor="middle" x={x} y={y}>
      {payload.value}
    </text>
  );
};

interface CustomToolTipProps extends TooltipProps<number | string, keyof GraphData> {
  variant: GraphVariant;
}

export const CustomToolTip: FC<CustomToolTipProps> = ({ active, payload, variant }) => {
  if (active && payload && payload.length) {
    const { date, postMoneyValuation, name, stakeholders } = payload[0].payload;
    return (
      <div className="flex w-[364px] flex-col gap-2 rounded-lg bg-gray-25 p-1 pt-2 shadow-2xl">
        <div className="flex justify-between gap-1 border-b-[1px] border-gray-200 px-4 pb-2">
          <span className="text-label-sm font-semibold uppercase text-gray-700">{name}</span>
          <span className="text-label-sm font-semibold text-gray-700">{date}</span>
        </div>
        <div className="flex justify-between gap-1 px-4">
          <span className="text-label-sm font-semibold uppercase text-gray-700">
            post money valuation
          </span>
          <span className="text-label-sm font-semibold text-forest-600">
            ${postMoneyValuation.toLocaleString('en-US')}
          </span>
        </div>
        <div className="grid grid-cols-3">
          <div className="border-b-[1px] border-[#F2F2F2] bg-gray-50 px-4 py-2 text-label-sm font-[500] text-[#172335]">
            Shareholder
          </div>
          <div className="border-b-[1px] border-[#F2F2F2] bg-gray-50 px-4 py-2 text-center text-label-sm font-[500] text-[#172335]">
            Shares
          </div>
          <div className="border-b-[1px] border-[#F2F2F2] bg-gray-50 px-4 py-2 text-center text-label-sm font-[500] text-[#172335]">
            {variant === GraphVariant.VALUATION ? 'Value' : '% of Ownership'}
          </div>

          {stakeholders
            .filter(
              (stakeholder: Stakeholder) =>
                stakeholder.name !== 'notExisting' && stakeholder.shares !== 0,
            )
            .slice(0, variant === GraphVariant.VALUATION ? 5 : 10)
            .map(({ name, percentage, shares, value }: Stakeholder, index: number) => (
              <React.Fragment key={`${name}_${index}`}>
                <div className="truncate border-b border-gray-50 px-4 py-1 text-label-sm font-[500] text-[#172335]">
                  {name}
                </div>
                <div className="border-b border-gray-50 px-4 py-1 text-center text-label-sm font-[500] text-[#172335]">
                  {(shares * 1000).toLocaleString('en-US')}
                </div>
                <div className="border-b border-gray-50 px-4 py-1 text-center text-label-sm font-[500] text-[#172335]">
                  {variant === GraphVariant.VALUATION
                    ? (value * 1000000).toLocaleString('en-US')
                    : `${percentage}%`}
                </div>
              </React.Fragment>
            ))}
        </div>
      </div>
    );
  }
};

export const EventGraph: FC<EventGraphProps> = ({ variant, snapshots }) => {
  const { format } = useFormat();
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const sortedByDateSnapshots = snapshots.sort((a, b) => {
    const dateA = new Date(a.valuation.date).getTime();
    const dateB = new Date(b.valuation.date).getTime();

    if (dateA === dateB) {
      return a.postMoneyValuation - b.postMoneyValuation;
    }

    return dateA - dateB;
  });

  const data = sortedByDateSnapshots.map(
    ({ stats, valuation, postMoneyValuation }, index, array) => {
      const totalPercentage = stats.stakeholders.reduce((total, stakeholder) => {
        return total + stakeholder.issuedPercentage;
      }, 0);

      const formattedDate =
        index === array.length - 1 ? 'Fully Diluted' : format(valuation.date, 'dd/MM/yyyy');

      return {
        date: formattedDate,
        postMoneyValuation: postMoneyValuation,
        name: valuation.name,
        stakeholders: stats.stakeholders.map((stakeholder) => ({
          id: stakeholder.id,
          name: stakeholder.name,
          shares: stakeholder.issued / 1000,
          percentage:
            totalPercentage > 0
              ? Math.round(((stakeholder.issuedPercentage || 0) * 100) / totalPercentage) || 0
              : 0,
          value: (stakeholder.issued * valuation.sharePrice) / 1000000,
        })),
      };
    },
  );
  const yAxisDomain = variant === GraphVariant.OWNERSHIP_PERCENT ? [0, 100] : ['auto', 'auto'];

  const allStakeholders = Array.from(
    new Set(data.flatMap((day) => day.stakeholders.map((stakeholder) => stakeholder.id))),
  );

  const normalizedData = data.map((day, index) => {
    const stakeholders = allStakeholders
      .map((id) => {
        const existing = day.stakeholders.find((stakeholder) => stakeholder.id === id);
        return existing
          ? existing
          : {
              id,
              name: 'notExisting',
              shares: 0,
              percentage: 0,
              value: 0,
            };
      })
      .sort((a, b) => b.shares - a.shares);

    return {
      ...day,
      stakeholders,
      dateIndex: index,
    };
  });

  const handleMouseMove = (e: any) => {
    const { activePayload } = e;
    if (activePayload && activePayload.length) {
      const index = activePayload[0].payload.dateIndex;
      setActiveIndex(index);
    }
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <ResponsiveContainer height="100%" width="100%">
      <AreaChart
        data={normalizedData}
        margin={{
          top: 0,
          right: 0,
          left: 8,
          bottom: 8,
        }}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      >
        <XAxis
          dataKey="date"
          stroke="#475467"
          tick={({ x, y, payload }) => (
            <CustomTick isActive={activeIndex === payload.index} payload={payload} x={x} y={y} />
          )}
          tickLine={false}
          tickMargin={16}
        />
        <YAxis
          domain={yAxisDomain}
          stroke="#475467"
          tick={{ fontSize: 10, fontWeight: 500 }}
          tickFormatter={(value) =>
            variant === GraphVariant.OWNERSHIP_PERCENT ? `${value}%` : value
          }
          tickLine={false}
          tickMargin={16}
        />
        {allStakeholders.map((_, index) => (
          <Area
            activeDot={{
              strokeWidth: 1,
              fill: activeColors[index % activeColors.length],
            }}
            dataKey={
              variant === GraphVariant.VALUATION
                ? `stakeholders[${index}].value`
                : variant === GraphVariant.OWNERSHIP
                  ? `stakeholders[${index}].shares`
                  : `stakeholders[${index}].percentage`
            }
            fill={fillColors[index % fillColors.length]}
            fillOpacity={1}
            key={index}
            stroke={strokeColors[index % strokeColors.length]}
            type="monotone"
          />
        ))}
        <Tooltip
          content={<CustomToolTip variant={variant} />}
          cursor={{ stroke: '#D0D5DD', strokeDasharray: '5 3', strokeWidth: 1 }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
