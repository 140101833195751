import { useCallback, useState } from 'react';

type ModalStateProps<TSuccess = unknown, Terror = unknown, TOpen = unknown> = {
  initialValue?: boolean;
  onClose?: () => void;
  onOpen?: (T?: TOpen) => void;
  onSuccess?: (T?: TSuccess) => void;
  onDismiss?: (T?: Terror) => void;
};

export const useModalState = <TSuccess = unknown, Terror = unknown, TOpen = unknown>(
  params?: ModalStateProps<TSuccess, Terror, TOpen>,
) => {
  const onClose = params?.onClose;
  const onOpen = params?.onOpen;
  const onSuccess = params?.onSuccess;
  const onDismiss = params?.onDismiss;

  const [isOpen, setOpen] = useState(params?.initialValue || false);
  const [modalData, setModalData] = useState<TOpen | undefined>(undefined);

  const handleCloseModal = useCallback(() => {
    onClose?.();
    setOpen(false);
    setModalData(undefined);
  }, [onClose]);

  const handleOpenModal = useCallback(
    (data?: TOpen) => {
      onOpen?.(data);
      setModalData(data);
      setOpen(true);
    },
    [onOpen],
  );

  const handleSuccessModal = useCallback(
    (data?: TSuccess) => {
      onSuccess?.(data);
      setOpen(false);
    },
    [onSuccess],
  );
  const handleDismissModal = useCallback(
    (data?: Terror) => {
      onDismiss?.(data);
      setOpen(false);
    },
    [onDismiss],
  );

  return {
    isOpen,
    toggler: setOpen,
    handleCloseModal,
    handleOpenModal,
    handleSuccessModal,
    handleDismissModal,
    modalData,
  };
};
