import { FC, useMemo } from 'react';

import { Table, TableRowData } from '../../components/Table';
import { SnapshotHooks } from '../../hooks';
import { toRound } from '../../utils/getRoundedNumber';
import { SummaryItem } from './SummaryItem';

enum Column {
  SHARE_CLASS = 'share-class',
  ISSUED = 'issued',
  DILUTED = 'diluted',
  VOTING = 'voting',
  ISSUED_SHARES = 'issued-shares',
  DILUTED_SHARES = 'diluted-shares',
  INVESTMENT = 'investment',
}

const tableColumn: Column[] = Object.values(Column);

const tableColumnTitle: Record<Column, string> = {
  [Column.SHARE_CLASS]: 'Share Class',
  [Column.ISSUED]: 'Issued',
  [Column.DILUTED]: 'Diluted',
  [Column.VOTING]: 'Voting',
  [Column.ISSUED_SHARES]: 'Issued Shares',
  [Column.DILUTED_SHARES]: 'Diluted Shares',
  [Column.INVESTMENT]: 'Investment',
};

export type ConsolidatedPositionProps = {
  companyId: string;
};

export const ConsolidatedPosition: FC<ConsolidatedPositionProps> = ({ companyId }) => {
  const { shareClasses } = SnapshotHooks.useStats({ companyId });

  const tableData: TableRowData<Column>[] = useMemo(
    () =>
      shareClasses?.map(
        ({
          name,
          id,
          diluted,
          dilutedPercentage,
          investment,
          issued,
          issuedPercentage,
          votingPercentage,
        }) => ({
          [Column.SHARE_CLASS]: name,
          [Column.ISSUED]: `${toRound(issuedPercentage * 100)}%`,
          [Column.DILUTED]: `${toRound(dilutedPercentage * 100)}%`,
          [Column.VOTING]: `${toRound(votingPercentage * 100)}%`,
          [Column.ISSUED_SHARES]: `${issued.toLocaleString('en-US')}`,
          [Column.DILUTED_SHARES]: `${diluted.toLocaleString('en-US')}`,
          [Column.INVESTMENT]: `$${investment.toLocaleString('en-US')}`,
          id,
        }),
      ),
    [shareClasses],
  );

  return (
    <>
      <div className="flex flex-grow flex-col gap-3 overflow-hidden rounded-lg bg-gray-50 p-[2px] pt-3 max-lg:hidden lg:basis-[60%]">
        <span className="pl-4 text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
          Your Consolidated Position
        </span>
        <div className="flex h-full w-full flex-wrap gap-3 rounded-md bg-white p-1 shadow-sm">
          <div className={'h-full w-full overflow-x-auto rounded-md border border-gray-100'}>
            <Table columns={tableColumn} columnsTitle={tableColumnTitle} rows={tableData} />
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col gap-3 rounded-lg bg-gray-50 p-1 pt-3 lg:hidden">
        <span className="pl-4 text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
          Your Consolidated Position
        </span>
        <div className="flex flex-col gap-2">
          {tableData.map((data) => (
            <div
              className="flex w-full flex-col divide-y divide-gray-100 rounded-md bg-white shadow-sm"
              key={data.id}
            >
              {tableColumn.map((column) => (
                <SummaryItem key={column} title={tableColumnTitle[column]} value={data[column]} />
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
