import { Field, Label } from '@headlessui/react';
import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../../assets/icons';
import MinusIcon from '../../../assets/icons/MinusIcon';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { Checkbox } from '../../../components/Checkbox';
import { EmptySearchList } from '../../../components/EmptySearchList';
import { StringKey } from '../../../lang';
import {
  Stakeholder,
  StakeholderOrder,
  StakeholderRole,
  StakeholderSortBy,
} from '../../../types/stakeholderTypes';
import StakeholderTableItem from './StakeholderTableItem';

export type StakeholderTableProps = {
  data: Stakeholder[] | undefined;
  isDemo: boolean;
  searchValue?: string;
  className?: string;
  onSortClick: (field: StakeholderSortBy) => void;
  handleEdit: (id: string) => void;
  handleView: (id: string) => void;
  handleDelete: (id: string) => void;
  handleMakeContributor: (id: string) => void;
  handleRemoveContributorRole: (id: string) => void;
  handleResendInvitation: (id: string) => void;
  handleCancelInvitation: (id: string) => void;
  handleRevokeAccess: (id: string) => void;
  handleTerminate: (id: string) => void;
  handleEditTermination: (id: string) => void;
  handleGrantAccess: (id: string) => void;
  handleSendInvitation: (id: string) => void;
  sortMode: StakeholderOrder | null;
  sortField: StakeholderSortBy | null;
  onSelect: (stakeholderId: string) => void;
  onRemoveSelect: (stakeholderId: string) => void;
  isSelected: (stakeholderId: string) => boolean;
  onSelectAll: (stakeholderId: string[]) => void;
  onRemoveSelectAll: () => void;
  isSelectStarted: boolean;
  roleInCompany?: StakeholderRole;
  accountId: string;
};

const StakeholderTable: FC<StakeholderTableProps> = ({
  data,
  className,
  onSortClick,
  sortMode,
  sortField,
  handleEdit,
  handleView,
  handleDelete,
  handleCancelInvitation,
  handleGrantAccess,
  handleMakeContributor,
  handleRemoveContributorRole,
  handleResendInvitation,
  handleRevokeAccess,
  handleTerminate,
  handleEditTermination,
  handleSendInvitation,
  isSelected,
  onRemoveSelect,
  onSelect,
  searchValue,
  onRemoveSelectAll,
  onSelectAll,
  isSelectStarted,
  roleInCompany,
  accountId,
  isDemo,
}) => {
  return (
    <>
      <div className="w-full shrink-0 overflow-x-auto *:text-nowrap">
        <table
          className={twMerge(
            'h-fit w-full divide-y-[1px] divide-[#F2F2F2] border-b-[1px] border-[#F2F2F2] bg-brand-700',
            className,
          )}
        >
          <colgroup>
            <col className="w-12 shrink-0" span={1} />
          </colgroup>
          <thead>
            <tr className="h-11 bg-gray-50">
              <td className="relative">
                <div className="flex h-full w-full items-center justify-center px-4">
                  <Field className="flex cursor-pointer items-center">
                    <Checkbox
                      checked={isSelectStarted}
                      id="select-all"
                      onChange={(checked) =>
                        checked ? onSelectAll(data?.map(({ id }) => id) || []) : onRemoveSelectAll()
                      }
                      selectIcon={<MinusIcon />}
                    />
                    <Label
                      className="absolute left-0 top-0 h-full w-full cursor-pointer"
                      htmlFor="select-all"
                    />
                  </Field>
                </div>
              </td>

              <td className="pl-4">
                <div
                  className="flex items-center gap-1"
                  onClick={() => onSortClick(StakeholderSortBy.FULL_NAME)}
                >
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.NAME} />
                  </span>
                  <Button
                    className="relative flex size-4 justify-center rounded hover:bg-gray-200"
                    styleType="NONE"
                  >
                    {sortField === StakeholderSortBy.FULL_NAME ? (
                      sortMode == null ? (
                        <>
                          <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                          <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                        </>
                      ) : (
                        <>
                          {sortMode === StakeholderOrder.ASC ? (
                            <ChevronDownIcon className="w-2" />
                          ) : (
                            <ChevronDownIcon className="w-2 rotate-180" />
                          )}
                        </>
                      )
                    ) : (
                      <>
                        <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                        <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                      </>
                    )}
                  </Button>
                </div>
              </td>
              <td className="pl-4">
                <div
                  className="flex items-center gap-1"
                  onClick={() => onSortClick(StakeholderSortBy.TYPE)}
                >
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.TYPE} />
                  </span>
                  <Button
                    className="relative flex size-4 justify-center rounded hover:bg-gray-200"
                    styleType="NONE"
                  >
                    {sortField === StakeholderSortBy.TYPE ? (
                      sortMode == null ? (
                        <>
                          <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                          <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                        </>
                      ) : (
                        <>
                          {sortMode === StakeholderOrder.ASC ? (
                            <ChevronDownIcon className="w-2" />
                          ) : (
                            <ChevronDownIcon className="w-2 rotate-180" />
                          )}
                        </>
                      )
                    ) : (
                      <>
                        <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                        <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                      </>
                    )}
                  </Button>
                </div>
              </td>

              <td className="pl-4 text-label-md font-[450] text-[#172335]">
                <AppFormattedMessage id={StringKey.EMAIL_ADDRESS} />
              </td>
              <td className="pl-4">
                <div className="flex items-center gap-1">
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.LAST_ACTIVE} />
                  </span>
                </div>
              </td>
              <td className="pl-4">
                <div className="flex items-center gap-1">
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.VOTING_RIGHTS} />
                  </span>
                  <Button
                    className="relative flex size-4 justify-center rounded hover:bg-gray-200"
                    styleType="NONE"
                  >
                    <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                    <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                  </Button>
                </div>
              </td>
              <td className="pl-4">
                <div className="flex items-center gap-1">
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.STATUS} />
                  </span>
                </div>
              </td>
            </tr>
          </thead>
          <tbody className="divide-y-[1px] divide-[#F2F2F2] bg-gray-600">
            {data?.map((stakeholder, i) => (
              <StakeholderTableItem
                accountId={accountId}
                isDemo={isDemo}
                key={stakeholder.id}
                {...stakeholder}
                handleCancelInvitation={handleCancelInvitation}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                handleEditTermination={handleEditTermination}
                handleGrantAccess={handleGrantAccess}
                handleMakeContributor={handleMakeContributor}
                handleRemoveContributorRole={handleRemoveContributorRole}
                handleResendInvitation={handleResendInvitation}
                handleRevokeAccess={handleRevokeAccess}
                handleSendInvitation={handleSendInvitation}
                handleTerminate={handleTerminate}
                handleView={handleView}
                index={i}
                isSelected={isSelected}
                onRemoveSelect={onRemoveSelect}
                onSelect={onSelect}
                roleInCompany={roleInCompany}
              />
            ))}
          </tbody>
        </table>
        {searchValue && data?.length === 0 && (
          <div className="flex w-full items-center justify-center max-lg:hidden">
            <EmptySearchList className="mt-20" />
          </div>
        )}
      </div>
    </>
  );
};

StakeholderTable.displayName = 'StakeholderTable';

export default StakeholderTable;
