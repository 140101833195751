import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { TerminateStakeholderItemDto } from '../../types/stakeholderTypes';
import { useAppMutation } from '../useAppMutation';

export const useUpdateTermination = () => {
  const { mutate: updateTermination, isPending } = useAppMutation(
    [QueryKey.PATCH_TERMINATE_STAKEHOLDERS],
    {
      mutationFn: ({
        companyId,
        data,
      }: {
        companyId: string;
        data: TerminateStakeholderItemDto[];
      }) =>
        ApiService.patch(
          {
            endpoint: BackendRoute.COMPANIES,
            routePath: [companyId, 'stakeholders', 'terminate', 'update'],
          },
          { body: data },
        ),
    },
  );
  return { updateTermination, isPending };
};
