import React, { FC } from 'react';

import Loader from '../../components/Loader';
import { Company, Stakeholder } from '../../hooks';
import { toNumber } from '../../utils/toNumber';
import { AutomaticVesting } from './AutomaticVesting';
import { ConsolidatedPosition } from './ConsolidatedPosition';
import { EmptyMyHoldings } from './EmptyMyHoldings';
import { EquityGraph, PositionGraph } from './Graphs';
import { OwnershipSummary } from './OwnershipSummary';
import { SummaryItem } from './SummaryItem';

const MyHoldings: FC = () => {
  const { selectedCompanyId, isLoading, selectedCompany } = Company.useSelected();

  const companyId = selectedCompanyId;
  const stakeholderId = selectedCompany?.stakeholder?.id || '';

  const { ownershipSummary, isLoading: isLoadingOwnershipSummary } =
    Stakeholder.useOwnershipSummary({
      companyId,
      stakeholderId: stakeholderId,
    });

  const { isLoading: isLoadingInvestmentSummary, investmentSummary } =
    Stakeholder.useInvestmentSummary({ companyId, stakeholderId });

  if (isLoading || isLoadingOwnershipSummary || isLoadingInvestmentSummary) return <Loader />;

  if (!ownershipSummary || !investmentSummary || !ownershipSummary.numberOfShares)
    return <EmptyMyHoldings />;

  return (
    <div className="flex h-fit w-full flex-col gap-4 overflow-hidden rounded-lg p-1">
      <div className="flex w-full flex-wrap gap-4">
        <OwnershipSummary {...ownershipSummary} />
        <ConsolidatedPosition companyId={companyId} />
      </div>
      <div className="flex w-full flex-wrap gap-4">
        <PositionGraph companyId={companyId} stakeholderId={stakeholderId} />
        <EquityGraph companyId={companyId} stakeholderId={stakeholderId} />
      </div>
      <AutomaticVesting companyId={companyId} stakeholderId={stakeholderId} />
      <div className="flex w-full flex-col gap-3 rounded-lg bg-gray-50 p-1 pt-3">
        <span className="pl-4 text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
          Investment Summary
        </span>
        <div className="flex gap-1 max-lg:flex-wrap">
          {selectedCompany?.stakeholder?.companyTableAccess && (
            <div className="flex w-full flex-col divide-y divide-gray-100 rounded-md bg-white shadow-sm">
              <span className="p-4 text-xl font-semibold text-gray-700">Company</span>
              <SummaryItem
                title="Total Rounds"
                value={investmentSummary.companyTotalRounds.toLocaleString('en-US')}
              />
              <SummaryItem
                title="Current Valuation"
                value={`$${investmentSummary.currentValuationValue.toLocaleString('en-US')}`}
              />
              <SummaryItem
                title="Total Amount Raise"
                value={`$${investmentSummary.totalAmountRaise.toLocaleString('en-US')}`}
              />
              <SummaryItem
                title="Avg. Valuation Change"
                titleAdditionalNote="Per round"
                value={'none'}
              />
              <SummaryItem
                title="Latest Share Price"
                value={investmentSummary.latestSharePrice.toLocaleString('en-US')}
              />
            </div>
          )}
          <div className="flex w-full flex-col divide-y divide-gray-100 rounded-md bg-white shadow-sm">
            <span className="p-4 text-xl font-semibold text-gray-700">Your Participation</span>
            <SummaryItem
              title="Total Rounds"
              value={investmentSummary.stakeholderTotalRounds.toLocaleString('en-US')}
            />
            <SummaryItem
              title="Total Investment"
              value={`$${investmentSummary.stakeholderTotalInvestment.toLocaleString('en-US')}`}
            />
            <SummaryItem
              title="Estimated Value"
              value={`$${investmentSummary.stakeholderEstimatedValue.toLocaleString('en-US')}`}
            />
            <SummaryItem
              title="Overall Equity Change"
              titleAdditionalNote="Per round"
              value={`${investmentSummary.equityChange.toLocaleString('en-US')}%`}
            />
            <SummaryItem
              title="Average Investment"
              titleAdditionalNote="Per round"
              value={`${investmentSummary.stakeholderAverageInvestment.toLocaleString('en-US')}%`}
            />
            <SummaryItem
              title="Average Share Price "
              value={`$${toNumber(investmentSummary?.averageSharePrice).toLocaleString('en-US')}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

MyHoldings.displayName = 'MyHoldings';

export default MyHoldings;
