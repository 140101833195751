import { StringKey } from '../lang';
import { PageRoute } from '../types/pageTypes';

export const pageTitleTranslation: Record<PageRoute, StringKey> = {
  [PageRoute.HOME]: StringKey.MY_COMPANIES,
  [PageRoute.PROFILE]: StringKey.MY_PROFILE,
  [PageRoute.MY_COMPANIES]: StringKey.MY_COMPANIES,
  [PageRoute.COMPANY_SETUP]: StringKey.COMPANY_SETUP,
  [PageRoute.COMPANY_SETTINGS]: StringKey.COMPANY_SETTINGS,
  [PageRoute.DASHBOARD]: StringKey.DASHBOARD,
  [PageRoute.STAKEHOLDERS]: StringKey.STAKEHOLDERS,
  [PageRoute.EVENTS]: StringKey.EVENTS,
  [PageRoute.MY_HOLDINGS]: StringKey.MY_HOLDINGS,
  [PageRoute.FORGOT_PASSWORD]: StringKey.FORGOT_PASSWORD,
  [PageRoute.SHARE_CLASSES]: StringKey.SHARE_CLASSES,
  [PageRoute.POOL_PLANS_POOL]: StringKey.POOL_PLANS_POOL,
  [PageRoute.POOL_PLANS_PLAN]: StringKey.POOL_PLANS_PLAN,
  [PageRoute.POOL_PLANS]: StringKey.POOL_PLANS,
  [PageRoute.CAP_TABLE]: StringKey.CAP_TABLE,
  [PageRoute.SETTINGS]: StringKey.SETTINGS,
  [PageRoute.ACCEPT_INVITATION]: StringKey.ACCEPT_INVITATION,
  [PageRoute.LOGIN]: StringKey.LOGIN,
  [PageRoute.REGISTER]: StringKey.REGISTER,
  [PageRoute.REGISTER_VERIFY]: StringKey.REGISTER_VERIFY,
  [PageRoute.PASSWORD_RECOVERY]: StringKey.PASSWORD_RECOVERY,
  [PageRoute.BILLING]: StringKey.BILLING,
  [PageRoute.ERROR]: StringKey.ERROR,
};
