import { FC } from 'react';

import { useFormat, ValuationHooks } from '../../../../../hooks';
import {
  EventExercisedTypeBackend,
  eventFormType,
  EventTypeBackend,
} from '../../../../../types/events.types';
import { SharePlanType } from '../../../../../types/pool-plans.types';
import { toNumber } from '../../../../../utils/toNumber';
import { BasicTable } from './BasicTable';
import { Td } from './TableTd';
import { TableBodyProps } from './types';

export const Exercised: FC<TableBodyProps> = (props) => {
  const { format } = useFormat();
  const { valuation } = ValuationHooks.useCurrent({ companyId: props.companyId });

  if (props.type !== EventTypeBackend.EXERCISE) return <></>;

  const {
    type,
    exercise: {
      exercised,
      vestingTask: {
        grantItem: {
          stakeholder,
          numbersOfGrants,
          plan: {
            type: planType,
            pool: { shareClass },
          },
        },
      },
    },
    createdAt,
  } = props;

  if (planType === SharePlanType.STOCK_OPTIONS)
    return (
      <BasicTable eventType={EventExercisedTypeBackend.STOCK_OPTIONS}>
        <tr>
          <Td value={format(createdAt, 'dd/MM/yyyy')} />
          <Td value={eventFormType[type]} />
          <Td value={stakeholder.fullName} />
          <Td value={exercised} />
          <Td value={shareClass.name} />
          <Td value={numbersOfGrants} />
        </tr>
      </BasicTable>
    );

  if (planType === SharePlanType.WARRANTS)
    return (
      <BasicTable eventType={EventExercisedTypeBackend.STOCK_OPTIONS}>
        <tr>
          <Td value={format(createdAt, 'dd/MM/yyyy')} />
          <Td value={eventFormType[type]} />
          <Td value={stakeholder.fullName} />
          <Td value={exercised} />
          <Td value={shareClass.name} />
          <Td value={numbersOfGrants} />
        </tr>
      </BasicTable>
    );

  if (planType === SharePlanType.SAR)
    return (
      <BasicTable eventType={EventExercisedTypeBackend.SAR}>
        <tr>
          <Td value={format(createdAt, 'dd/MM/yyyy')} />
          <Td value={eventFormType[type]} />
          <Td value={stakeholder.fullName} />
          <Td value={exercised} />
          <Td value={shareClass.name} />
          <Td value={`$${(exercised * toNumber(valuation?.sharePrice)).toLocaleString('en-US')}`} />
          <Td value={numbersOfGrants} />
        </tr>
      </BasicTable>
    );

  return <></>;
};
