import { FC, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../../../assets/icons';
import {
  Column,
  ColumnVariant,
  DetailsSortVariant,
  detailsViewByColumns,
  ShareClassColumns,
  StakeholderColumns,
  ViewBy,
} from '../../../../types/snapshot.types';

type StakeholderTableBodyProps = {
  visibleColumns: Column[];
  viewBy: Record<ViewBy, boolean>;
  handleExpandRow?: React.Dispatch<React.SetStateAction<boolean>>;
  isExpandedRow: boolean;
  isExpanded?: boolean;
  className?: string;
  trClassName?: string;
  tdClassName?: string;
} & (
  | {
      type: DetailsSortVariant.BY_STAKEHOLDER;
      fields: Record<StakeholderColumns, string | number | string[] | number[] | null>;
    }
  | {
      type: DetailsSortVariant.BY_SHARE_CLASS;
      fields: Record<ShareClassColumns, string | number | string[] | number[] | null>;
    }
);
const allColumnsMap = Object.values(Column);

const allColumnFields = allColumnsMap.reduce(
  (acc, curr) => ({ ...acc, [curr]: null }),
  {},
) as Record<Column, null>;

const resultZero = '0.00';

export const TableRow: FC<StakeholderTableBodyProps> = ({
  fields,
  className,
  trClassName,
  visibleColumns = [],
  viewBy,
  handleExpandRow,
  isExpanded,
  isExpandedRow,
  type,
  tdClassName,
}) => {
  const selectedViewBy: ViewBy | 'all' =
    viewBy.stakeholders && viewBy.type ? 'all' : viewBy.type ? ViewBy.TYPE : ViewBy.STAKEHOLDERS;

  const viewByAll = selectedViewBy === 'all';

  const basicIsVisibleChecks = useMemo(
    () =>
      allColumnsMap.reduce(
        (acc, curr) => ({ ...acc, [curr]: visibleColumns.includes(curr) }),
        {},
      ) as Record<Column, boolean>,
    [visibleColumns],
  );

  const isVisibleChecks: Record<ColumnVariant, boolean> = useMemo(
    () => ({
      ...basicIsVisibleChecks,
      [Column.NAME]: visibleColumns.includes(Column.NAME) && viewByAll,
      [Column.SHARE_CLASS_NAME]:
        visibleColumns.includes(Column.SHARE_CLASS_NAME) && !viewByAll && viewBy.type,
      [Column.STAKEHOLDER_NAME]:
        visibleColumns.includes(Column.STAKEHOLDER_NAME) && !viewByAll && viewBy.stakeholders,
      [Column.TYPE]: visibleColumns.includes(Column.TYPE) && !viewByAll,
    }),
    [basicIsVisibleChecks, viewBy.stakeholders, viewBy.type, viewByAll, visibleColumns],
  );

  const fieldsMap = useMemo(
    () => Object.values(detailsViewByColumns[selectedViewBy][type]),
    [selectedViewBy, type],
  );

  const allFields = useMemo(() => ({ ...allColumnFields, ...fields }), [fields]);
  return (
    <>
      <tr className={twMerge(isExpanded && 'shadow-[inset_2px_0px_0px_0px_#2565C8]', trClassName)}>
        {viewByAll && (
          <td
            className={twMerge(
              'w-12 shadow-[inset_0px_-1px_0px_0px_#F2F4F7]',
              !isExpandedRow && 'cursor-pointer',
              isExpanded && 'bg-brand-50 shadow-[inset_2px_0px_0px_0px_#2565C8]',
              className,
            )}
            onClick={() => !isExpandedRow && handleExpandRow?.((prev) => !prev)}
          >
            <span className="centered flex w-12 shrink-0 text-sm font-[450] text-gray-700">
              {!isExpandedRow && (
                <ChevronDownIcon
                  className={twMerge('transition-all', isExpanded && 'rotate-180')}
                  iconColor={isExpanded ? '#2565C8' : '#344054'}
                />
              )}
            </span>
          </td>
        )}

        {fieldsMap.map(
          (field, i) =>
            isVisibleChecks[field] &&
            (Array.isArray(allFields[field]) ? (
              allFields[field].map((filed, i) => (
                <td
                  className="px-4 py-6 shadow-[inset_0px_-1px_0px_0px_#F2F4F7]"
                  key={`${field}_${i}_${type}`}
                >
                  <span
                    className={twMerge(
                      'text-sm font-[450] text-gray-700',
                      (!allFields[field] || allFields[field] === '-' || !filed) && 'text-gray-300',
                      tdClassName,
                    )}
                  >
                    {filed ? filed.toLocaleString('en-US') : '-'}
                  </span>
                </td>
              ))
            ) : (
              <td
                className="px-4 py-6 shadow-[inset_0px_-1px_0px_0px_#F2F4F7]"
                key={`${field}_${i}_${type}`}
              >
                <span
                  className={twMerge(
                    'text-sm font-[450] text-gray-700',
                    (!allFields[field] ||
                      allFields[field] === '-' ||
                      allFields[field] === resultZero) &&
                      'text-gray-300',
                    tdClassName,
                  )}
                >
                  {!allFields[field] || allFields[field] === resultZero
                    ? '-'
                    : allFields[field]?.toLocaleString('en-US')}
                </span>
              </td>
            )),
        )}
      </tr>
    </>
  );
};
