import React, { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { Company } from '../../hooks';
import { PageRoute, replaceIdWithPath } from '../../types/pageTypes';
import { StakeholderRole } from '../../types/stakeholderTypes';
import { ActivityBar } from '../ActivityBar';
import Header from '../Header';

interface HeaderLayoutProps {
  children: ReactNode;
  className?: string;
}

const HeaderLayout: FC<HeaderLayoutProps> = ({ children, className }) => {
  const { pathname } = useLocation();
  const { selectedCompany } = Company.useSelected({ redirectOnSelectedCompanyError: false });
  return (
    <div className="flex h-full w-full flex-col gap-3 overflow-y-auto bg-gray-50 lg:pb-4 lg:pr-6">
      <Header />
      <div className="h-full w-full overflow-y-auto max-lg:px-4" id="scrollbar-target">
        <div
          className={twMerge(
            'flex h-fit w-full justify-between gap-4 overflow-y-auto bg-white px-3 py-4 shadow-sm max-lg:mb-4 max-lg:mt-[1px] max-lg:rounded-md max-xs:rounded-b-[6px] max-xs:rounded-t-[6px] max-xs:shadow-sm lg:h-full lg:rounded-t-[24px] lg:shadow-none',
            className,
          )}
        >
          {children}
          {selectedCompany?.roleInCompany !== StakeholderRole.STAKEHOLDER &&
            replaceIdWithPath(pathname) !== PageRoute.COMPANY_SETUP &&
            replaceIdWithPath(pathname) !== PageRoute.BILLING && <ActivityBar />}
        </div>
      </div>
    </div>
  );
};

export default HeaderLayout;
