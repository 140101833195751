import { Company } from './companyTypes';
import { Valuation } from './events.types';
import { StakeholderType } from './stakeholderTypes';

export interface CommonStats {
  issuedPercentage: number;
  dilutedPercentage: number;
  votingRight: number;
  votingPercentage: number;
  issued: number;
  diluted: number;
}

export interface StakeholderShareClassData {
  id: string;
  name: string;
  shares: number;
}

export interface StakeholderStats extends CommonStats {
  id: string;
  name: string;
  type: StakeholderType;
  shareClasses: StakeholderShareClassData[];
  invested: number;
}

export interface StakeholderTypeStats extends CommonStats {
  type: StakeholderType;
  shareClasses: StakeholderShareClassData[];
}

export interface ShareClassStats extends CommonStats {
  id: string;
  name: string;
  investment: number;
}

export interface SharePlanStats {
  id: string;
  name: string;
  dilluted: number;
}

export interface PoolStats {
  id: string;
  name: string;
  dilluted: number;
}

export interface Stats {
  stakeholders: StakeholderStats[];
  stakeholdersTypes: StakeholderTypeStats[];
  shareClasses: ShareClassStats[];
  sharePlans: SharePlanStats[];
  pools: PoolStats[];
}

export enum DetailsSortVariant {
  BY_STAKEHOLDER = 'by-stakeholder',
  BY_SHARE_CLASS = 'by-share-class',
}

export const detailsSortVariantTitle: Record<DetailsSortVariant, string> = {
  [DetailsSortVariant.BY_STAKEHOLDER]: 'By Stakeholder',
  [DetailsSortVariant.BY_SHARE_CLASS]: 'By Share Class',
};

export const customizeSortVariantTitle: Record<DetailsSortVariant, string> = {
  [DetailsSortVariant.BY_STAKEHOLDER]: 'Stakeholder',
  [DetailsSortVariant.BY_SHARE_CLASS]: 'Share Class',
};

export const detailsSortVariantTitleMap = Object.entries(detailsSortVariantTitle);
export const customizeSortVariantTitleMap = Object.entries(customizeSortVariantTitle);

export enum Column {
  ISSUED = 'issued',
  DILUTED = 'diluted',
  VOTING = 'voting',
  NO_ISSUED = 'no-issued',
  DILUTED_SHARES = 'diluted-shares',
  COMMON_STOCK = 'common-stock',
  OTHER_SHARE_CLASSES = 'other-share-classes',
  INVESTMENT = 'investment',
  NAME = 'name',
  STAKEHOLDER_NAME = 'stakeholder-name',
  SHARE_CLASS_NAME = 'share-class-name',
  TYPE = 'type',
  EMPTY = 'empty',
  VOTING_RIGHTS = 'voting-rights',
  AVERAGE_SHARE_PRICE = 'average-share-price',
  NO_OF_STAKEHOLDERS = 'no-of-stakeholders',
}

export const columnTitle: Record<Column, string> = {
  [Column.ISSUED]: 'Issued %',
  [Column.DILUTED]: 'Diluted %',
  [Column.VOTING]: 'Voting %',
  [Column.NO_ISSUED]: 'No Issued',
  [Column.DILUTED_SHARES]: 'Diluted Shares',
  [Column.COMMON_STOCK]: 'Common Stock',
  [Column.OTHER_SHARE_CLASSES]: 'Other Share Classes',
  [Column.INVESTMENT]: 'Investment ($)',
  [Column.STAKEHOLDER_NAME]: 'Stakeholder name',
  [Column.SHARE_CLASS_NAME]: 'Share Class name',
  [Column.AVERAGE_SHARE_PRICE]: 'Average Share Price',
  [Column.VOTING_RIGHTS]: 'Voting Rights',
  [Column.NO_OF_STAKEHOLDERS]: 'No. of Stakeholders',
  [Column.TYPE]: 'Type',
  [Column.NAME]: '',
  [Column.EMPTY]: '',
};

export type StakeholderColumns =
  | Column.NAME
  | Column.STAKEHOLDER_NAME
  | Column.TYPE
  | Column.ISSUED
  | Column.DILUTED
  | Column.VOTING
  | Column.NO_ISSUED
  | Column.VOTING_RIGHTS
  | Column.DILUTED_SHARES
  | Column.COMMON_STOCK
  | Column.OTHER_SHARE_CLASSES
  | Column.INVESTMENT
  | Column.AVERAGE_SHARE_PRICE;

export type ShareClassColumns =
  | Column.SHARE_CLASS_NAME
  | Column.TYPE
  | Column.STAKEHOLDER_NAME
  | Column.NAME
  | Column.ISSUED
  | Column.DILUTED
  | Column.VOTING
  | Column.NO_ISSUED
  | Column.DILUTED_SHARES
  | Column.INVESTMENT
  | Column.VOTING_RIGHTS
  | Column.AVERAGE_SHARE_PRICE
  | Column.NO_OF_STAKEHOLDERS;

export enum ViewBy {
  STAKEHOLDERS = 'stakeholders',
  TYPE = 'type',
}

export type ColumnVariant = StakeholderColumns | ShareClassColumns;

export const detailsColumns: Record<DetailsSortVariant, ColumnVariant[]> = {
  [DetailsSortVariant.BY_STAKEHOLDER]: [
    Column.NAME,
    Column.ISSUED,
    Column.DILUTED,
    Column.VOTING,
    Column.NO_ISSUED,
    Column.DILUTED_SHARES,
    Column.VOTING_RIGHTS,
    Column.COMMON_STOCK,
    Column.OTHER_SHARE_CLASSES,
    Column.INVESTMENT,
    Column.AVERAGE_SHARE_PRICE,
  ],
  [DetailsSortVariant.BY_SHARE_CLASS]: [
    Column.NAME,
    Column.ISSUED,
    Column.DILUTED,
    Column.VOTING,
    Column.NO_ISSUED,
    Column.DILUTED_SHARES,
    Column.INVESTMENT,
    Column.VOTING_RIGHTS,
    Column.AVERAGE_SHARE_PRICE,
    Column.NO_OF_STAKEHOLDERS,
  ],
};

export const detailsViewByColumns: Record<
  ViewBy | 'all',
  Record<DetailsSortVariant, ColumnVariant[]>
> = {
  all: {
    [DetailsSortVariant.BY_STAKEHOLDER]: [
      Column.NAME,
      Column.ISSUED,
      Column.DILUTED,
      Column.VOTING,
      Column.NO_ISSUED,
      Column.DILUTED_SHARES,
      Column.VOTING_RIGHTS,
      Column.COMMON_STOCK,
      Column.OTHER_SHARE_CLASSES,
      Column.INVESTMENT,
      Column.AVERAGE_SHARE_PRICE,
    ],
    [DetailsSortVariant.BY_SHARE_CLASS]: [
      Column.NAME,
      Column.ISSUED,
      Column.DILUTED,
      Column.VOTING,
      Column.NO_ISSUED,
      Column.DILUTED_SHARES,
      Column.INVESTMENT,
      Column.VOTING_RIGHTS,
      Column.AVERAGE_SHARE_PRICE,
      Column.NO_OF_STAKEHOLDERS,
    ],
  },
  [ViewBy.STAKEHOLDERS]: {
    [DetailsSortVariant.BY_STAKEHOLDER]: [
      Column.STAKEHOLDER_NAME,
      Column.TYPE,
      Column.ISSUED,
      Column.DILUTED,
      Column.VOTING,
      Column.NO_ISSUED,
      Column.DILUTED_SHARES,
      Column.VOTING_RIGHTS,
      Column.COMMON_STOCK,
      Column.OTHER_SHARE_CLASSES,
      Column.INVESTMENT,
      Column.AVERAGE_SHARE_PRICE,
    ],
    [DetailsSortVariant.BY_SHARE_CLASS]: [
      Column.STAKEHOLDER_NAME,
      Column.TYPE,
      Column.ISSUED,
      Column.DILUTED,
      Column.VOTING,
      Column.NO_ISSUED,
      Column.DILUTED_SHARES,
      Column.INVESTMENT,
      Column.VOTING_RIGHTS,
      Column.AVERAGE_SHARE_PRICE,
      Column.NO_OF_STAKEHOLDERS,
    ],
  },
  [ViewBy.TYPE]: {
    [DetailsSortVariant.BY_STAKEHOLDER]: [
      Column.TYPE,
      Column.ISSUED,
      Column.DILUTED,
      Column.VOTING,
      Column.NO_ISSUED,
      Column.DILUTED_SHARES,
      Column.VOTING_RIGHTS,
      Column.COMMON_STOCK,
      Column.OTHER_SHARE_CLASSES,
      Column.INVESTMENT,
      Column.AVERAGE_SHARE_PRICE,
    ],
    [DetailsSortVariant.BY_SHARE_CLASS]: [
      Column.SHARE_CLASS_NAME,
      Column.ISSUED,
      Column.DILUTED,
      Column.VOTING,
      Column.NO_ISSUED,
      Column.DILUTED_SHARES,
      Column.INVESTMENT,
      Column.VOTING_RIGHTS,
      Column.AVERAGE_SHARE_PRICE,
      Column.NO_OF_STAKEHOLDERS,
    ],
  },
};

export type CustomViewExportSnapshot = {
  viewColumns?: Record<DetailsSortVariant, Column[]>;

  viewByColumns?: Record<DetailsSortVariant, Record<ViewBy, boolean>>;
};

export type Snapshot = {
  id: string;
  investorsCount: number;
  company: Company;
  valuation: Valuation;
  postMoneyValuation: number;
  issuedShares: number;
  dilutedShares: number;
  poolGrantedShares: number;
  poolDilutedShares: number;
  lifetimeInvestmentEquity: number;
  stats: Stats;
  createdAt: Date;
};
