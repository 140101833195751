import { differenceInDays } from 'date-fns';
import React, { FC, useEffect, useMemo } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { CalendarIcon, ChevronDownIcon, HelpIcon } from '../../../../../assets/icons';
import { HelpIconVariant } from '../../../../../assets/icons/HelpIcon';
import Button from '../../../../../components/Button';
import { FormInput } from '../../../../../components/Input';
import { Stakeholder as StakeholderHook, useFormat, VestingTaskHooks } from '../../../../../hooks';
import { Stakeholder, stakeholderExTypeTitle } from '../../../../../types/stakeholderTypes';
import { VestingTask } from '../../../../../types/vestingTasks.types';
import { toUpperCaseWords } from '../../../../../utils/toUppercaseUtil';
import { DatePicker } from '../../../../PoolPlans/PoolForm/DatePicker';
import { FormSchema } from '../validation';
import { TerminationTypeCombobox } from './TerminationTypeCombobox';

export type TableItemProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  stakeholder: Stakeholder;
  stakeholderIndex: number;
  selectedId: string;
  setSelectedId: React.Dispatch<React.SetStateAction<string>>;
};

export const TableItem: FC<TableItemProps> = ({
  control,
  setFormData,
  companyId,
  stakeholder,
  stakeholderIndex,
  selectedId,
  setSelectedId,
}) => {
  const { format } = useFormat();
  const { vestingTasks } = VestingTaskHooks.useVestingTasks({
    companyId,
    stakeholderId: stakeholder.id,
    exercisable: true,
  });

  const isSelected = selectedId === stakeholder.id;

  const unfinishedVestingTasks = useMemo(() => {
    return vestingTasks.filter((task) => !task.finished || task.exercisable);
  }, [vestingTasks]);

  useEffect(() => {
    setFormData(`terminateForm.stakeholderItems.${stakeholderIndex}.id`, stakeholder.id);
    if (vestingTasks.length > 0) {
      setFormData(
        `terminateForm.stakeholderItems.${stakeholderIndex}.grantItems`,
        unfinishedVestingTasks,
      );
    }
  }, [setFormData, stakeholder.id, stakeholderIndex, unfinishedVestingTasks, vestingTasks]);

  if (!stakeholder) return;

  return (
    <>
      <tr className="flex w-full bg-white transition-colors" key={stakeholder.id}>
        <td className="p-0">
          <div className="flex h-full w-full items-center justify-center">
            <Button
              className={twMerge(
                'h-full cursor-pointer rounded-none p-4 transition-colors',
                isSelected && 'bg-brand-25',
              )}
              onClick={() => {
                setSelectedId(isSelected ? '' : stakeholder.id);
              }}
              styleType="NONE"
            >
              <ChevronDownIcon
                className={twMerge(
                  '-rotate-90 transition-transform',
                  isSelected ? 'rotate-180' : '',
                )}
                iconColor={isSelected ? '#2565C8' : '#344054'}
              />
            </Button>
          </div>
        </td>
        <td className="w-[195px] text-nowrap p-4">
          <div className="flex w-full items-center gap-3">
            <div className="flex size-10 shrink-0 items-center justify-center rounded-full bg-gray-200 text-sm font-[450] text-gray-700">
              {stakeholder.fullName[0]}
            </div>
            <span className="truncate text-sm font-[450] text-gray-700">
              {toUpperCaseWords(stakeholder.fullName)}
            </span>
          </div>
        </td>
        <td className="flex w-[129px] items-center text-nowrap p-4">
          <span className="truncate text-sm font-[450] text-gray-700">
            {toUpperCaseWords(stakeholder.type)}
          </span>
        </td>
        <td className="flex w-[129px] items-center text-nowrap p-4">
          <span className="truncate text-sm font-[450] text-gray-700">
            {toUpperCaseWords(stakeholderExTypeTitle[stakeholder.type])}
          </span>
        </td>
        <td className="flex w-[184px] items-center text-nowrap p-4">
          <TerminationTypeCombobox control={control} index={stakeholderIndex} />
        </td>
        <td className="flex w-[184px] items-center text-nowrap p-4">
          <DatePicker
            onSelect={(date) =>
              setFormData(
                `terminateForm.stakeholderItems.${stakeholderIndex}.terminationDate`,
                date,
              )
            }
            wrapperClassName="w-[152px]"
          >
            <FormInput
              autoComplete="off"
              control={control}
              customValue={(value) => {
                return value && format(value, 'dd/MM/yyyy');
              }}
              icon={<CalendarIcon className="size-5" iconColor="#344054" />}
              inputClassName="h-10"
              name={`terminateForm.stakeholderItems.${stakeholderIndex}.terminationDate`}
              wrapperClassName="w-full"
            />
          </DatePicker>
        </td>
        <td className="flex w-[184px] items-center text-nowrap p-4">
          <DatePicker
            onSelect={(date) =>
              setFormData(
                `terminateForm.stakeholderItems.${stakeholderIndex}.terminationDate`,
                date,
              )
            }
            wrapperClassName="w-[152px]"
          >
            <FormInput
              autoComplete="off"
              control={control}
              customValue={(value) => {
                return value && format(value, 'dd/MM/yyyy');
              }}
              icon={<CalendarIcon className="size-5" iconColor="#344054" />}
              inputClassName="h-10"
              name={`terminateForm.stakeholderItems.${stakeholderIndex}.terminationDate`}
              wrapperClassName="w-full"
            />
          </DatePicker>
        </td>
        <td className="flex w-[190px] items-center text-nowrap p-4">
          <span className="truncate text-sm font-[450] text-gray-700">{stakeholder.email}</span>
        </td>
      </tr>

      {isSelected && (
        <div className="flex w-full items-center justify-center py-4">
          {unfinishedVestingTasks?.length > 0 ? (
            <div className="h-fit rounded-lg border border-gray-100 bg-white p-1">
              <table>
                <thead>
                  <tr className="flex bg-gray-50">
                    <td className="w-[106px] items-center px-4 py-3">
                      <span className="font-500 text-#172335 whitespace-nowrap text-label-md">
                        Grant
                      </span>
                    </td>
                    <td className="w-[150px] items-center px-4 py-3">
                      <span className="font-500 text-#172335 whitespace-nowrap text-label-md">
                        Status
                      </span>
                    </td>
                    <td className="w-[112px] items-center px-4 py-3">
                      <span className="font-500 text-#172335 whitespace-nowrap text-label-md">
                        Issue Date
                      </span>
                    </td>
                    <td className="w-[85px] items-center px-4 py-3">
                      <span className="font-500 text-#172335 whitespace-nowrap text-label-md">
                        Granted
                      </span>
                    </td>
                    <td className="w-[85px] items-center px-4 py-3">
                      <span className="font-500 text-#172335 whitespace-nowrap text-label-md">
                        Vested
                      </span>
                    </td>
                    <td className="w-[85px] items-center px-4 py-3">
                      <span className="font-500 text-#172335 whitespace-nowrap text-label-md">
                        Unvested
                      </span>
                    </td>
                    <td className="w-[85px] items-center px-4 py-3">
                      <span className="font-500 text-#172335 whitespace-nowrap text-label-md">
                        Exercised
                      </span>
                    </td>
                    <td className="w-[85px] items-center px-4 py-3">
                      <span className="font-500 text-#172335 whitespace-nowrap text-label-md">
                        Exercisable
                      </span>
                    </td>
                    <td className="flex w-[184px] items-center gap-2 px-4 py-3">
                      <span className="font-500 text-#172335 whitespace-nowrap text-label-md">
                        Final day to exercise
                      </span>
                      <HelpIcon variant={HelpIconVariant.EXERCISE} />
                    </td>
                    <td className="w-[121px] items-center px-4 py-3">
                      <span className="font-500 text-#172335 whitespace-nowrap text-label-md">
                        Days remaining
                      </span>
                    </td>
                  </tr>
                </thead>
                <tbody className="divide-y-[1px] divide-gray-100">
                  {unfinishedVestingTasks.map((task, index) => (
                    <GrantItem
                      companyId={companyId}
                      control={control}
                      index={index}
                      key={task.id}
                      setFormData={setFormData}
                      stakeholderId={stakeholder.id}
                      stakeholderIndex={stakeholderIndex}
                      task={task}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <span className="text-sm font-[450] text-gray-700">
              {stakeholder.fullName} doesn't have unfinished grants
            </span>
          )}
        </div>
      )}
    </>
  );
};

type GrantItemProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  stakeholderIndex: number;
  stakeholderId: string;
  task: VestingTask;
  index: number;
};

const GrantItem: FC<GrantItemProps> = ({
  control,
  setFormData,
  companyId,
  stakeholderIndex,
  stakeholderId,
  task,
  index,
}) => {
  const { format } = useFormat();
  const { terminateForm } = useWatch<FormSchema>({ control });
  const { calcTerminationFinalExerciseDate } = StakeholderHook.useTerminationFinalExerciseDate();
  const isExercisable = task.exercisable;

  useEffect(() => {
    const terminationDate = terminateForm?.stakeholderItems?.[stakeholderIndex]?.terminationDate;
    const terminationCause = terminateForm?.stakeholderItems?.[stakeholderIndex]?.terminationCause;

    if (terminationDate && terminationCause) {
      const terminateData = {
        id: stakeholderId,
        terminationDate,
        terminationCause,
      };

      calcTerminationFinalExerciseDate(
        {
          companyId,
          vestingTaskId: task.id,
          data: terminateData,
        },
        {
          onSuccess: (data) => {
            const currentFinalExerciseDate =
              terminateForm?.stakeholderItems?.[stakeholderIndex]?.grantItems?.[index]
                ?.finalExerciseDate;

            if (data && currentFinalExerciseDate !== data) {
              setFormData(
                `terminateForm.stakeholderItems.${stakeholderIndex}.grantItems.${index}.finalExerciseDate`,
                data,
              );
            }
          },
        },
      );
    }
  }, [
    calcTerminationFinalExerciseDate,
    companyId,
    index,
    setFormData,
    stakeholderId,
    stakeholderIndex,
    task.id,
    terminateForm?.stakeholderItems,
  ]);

  return (
    <tr className="flex w-full bg-white">
      <td className="flex w-[106px] items-center px-4 py-3">
        <span className="truncate whitespace-nowrap text-sm font-[450] uppercase text-brand-700 underline">
          {task?.grantItem?.id?.split('-')[0]}
        </span>
      </td>
      <td className="flex w-[150px] items-center px-4 py-3">
        <span className="whitespace-nowrap text-sm font-[450] text-gray-700">
          Awaiting approval
        </span>
      </td>
      <td className="flex w-[112px] items-center px-4 py-3">
        <span className="whitespace-nowrap text-sm font-[450] text-gray-700">
          {task.startTime ? format(task.startTime, 'dd/MM/yyyy') : '-'}
        </span>
      </td>
      <td className="flex w-[85px] items-center px-4 py-3">
        <span className="whitespace-nowrap text-sm font-[450] text-gray-700">
          {task.grantItem.numbersOfGrants.toLocaleString('en-US')}
        </span>
      </td>
      <td className="flex w-[85px] items-center px-4 py-3">
        <span className="whitespace-nowrap text-sm font-[450] text-gray-700">
          {task.vestedSharesCount.toLocaleString('en-US')}
        </span>
      </td>
      <td className="flex w-[85px] items-center px-4 py-3">
        <span className="whitespace-nowrap text-sm font-[450] text-gray-700">
          {(task.grantItem.numbersOfGrants - task.vestedSharesCount).toLocaleString('en-US')}
        </span>
      </td>
      <td className="flex w-[85px] items-center px-4 py-3">
        <span className="whitespace-nowrap text-sm font-[450] text-gray-700">
          {isExercisable ? task.exercisedCount.toLocaleString('en-US') : '-'}
        </span>
      </td>
      <td className="flex w-[85px] items-center px-4 py-3">
        <span className="whitespace-nowrap text-sm font-[450] text-gray-700">
          {isExercisable
            ? (task.vestedSharesCount - task.exercisedCount).toLocaleString('en-US')
            : '-'}
        </span>
      </td>
      <td className="flex w-[184px] items-center px-4 py-3">
        <DatePicker
          disabled={
            !terminateForm?.stakeholderItems?.[stakeholderIndex]?.grantItems?.[index]
              ?.finalExerciseDate
          }
          onSelect={(date) =>
            setFormData(
              `terminateForm.stakeholderItems.${stakeholderIndex}.grantItems.${index}.finalExerciseDate`,
              date,
            )
          }
          wrapperClassName="w-[152px]"
        >
          <FormInput
            autoComplete="off"
            control={control}
            customValue={(value) => {
              return value && format(value, 'dd/MM/yyyy');
            }}
            icon={<CalendarIcon className="size-5" iconColor="#344054" />}
            inputClassName="h-10"
            name={`terminateForm.stakeholderItems.${stakeholderIndex}.grantItems.${index}.finalExerciseDate`}
            wrapperClassName="w-full"
          />
        </DatePicker>
      </td>
      <td className="flex w-[121px] items-center px-4 py-3">
        <span className="whitespace-nowrap text-sm font-[450] text-gray-700">
          {terminateForm?.stakeholderItems?.[stakeholderIndex]?.grantItems?.[index]
            ?.finalExerciseDate
            ? differenceInDays(
                terminateForm?.stakeholderItems?.[stakeholderIndex]?.grantItems?.[index]
                  ?.finalExerciseDate,
                new Date(),
              )
            : '-'}
        </span>
      </td>
    </tr>
  );
};
