import { FC, useMemo } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';

import { CalendarIcon, CloseIcon } from '../../../../../../assets/icons';
import Button from '../../../../../../components/Button';
import { DatePicker } from '../../../../../../components/DatePicker';
import { FormInput } from '../../../../../../components/Input';
import { TableRow } from '../../../../../../components/Table';
import { useFormat } from '../../../../../../hooks';
import { SharePlanType } from '../../../../../../types/pool-plans.types';
import { toNumber } from '../../../../../../utils/toNumber';
import { GrantCombobox, StakeholderCombobox } from '../../FormComponents';
import { FormSchema } from '../../validation';
import { SarColumn, sarColumnMap } from './types';

export type SarTableRowProps = {
  id: string;
  rowIndex: number;
  control: Control<FormSchema>;
  companyId: string;
  setFormData: UseFormSetValue<FormSchema>;
  handleStakeholderChange: () => void;
  handleRemoveRow: () => void;
  totalItems: number;
  availableNoOfSar: number;
  availableSarPayout: number;
  availableIssuedShares: number;
};

export const SarTableRow: FC<SarTableRowProps> = ({
  id,
  companyId,
  control,
  setFormData,
  rowIndex,
  handleStakeholderChange,
  handleRemoveRow,
  totalItems,
  availableNoOfSar,
  availableIssuedShares,
  availableSarPayout,
}) => {
  const sarItem = useWatch({ control, name: `stepOne.sar.sarItems.${rowIndex}` });
  const { format } = useFormat();

  const checkSharesCount = useMemo(() => {
    const sarCount = Number(sarItem.sarCount);
    const sarPayout = Number(sarItem.sarPayout);
    const issuedShares = Number(sarItem.issuedShares);
    const sharePrice = Number(sarItem.sharePrice);
    const sarBasePrice = Number(sarItem.grant?.grantItem?.plan?.sarBasePrice);
    const priceDifference = sharePrice && sarBasePrice ? Number(sharePrice - sarBasePrice) : 1;
    const showError =
      sarCount &&
      issuedShares &&
      sarPayout &&
      priceDifference &&
      sarCount !== sarPayout / priceDifference + issuedShares;

    return showError || false;
  }, [sarItem]);

  return (
    <TableRow
      columns={sarColumnMap}
      fields={{
        id,
        [SarColumn.STAKEHOLDER]: (
          <StakeholderCombobox
            companyId={companyId}
            control={control}
            handleStakeholderChange={handleStakeholderChange}
            name={`stepOne.sar.sarItems.${rowIndex}.stakeholder`}
          />
        ),
        [SarColumn.GRANT]: (
          <GrantCombobox
            companyId={companyId}
            control={control}
            name={`stepOne.sar.sarItems.${rowIndex}.grant`}
            sharePlanType={SharePlanType.RSA}
            stakeholderId={sarItem?.stakeholder?.id || ''}
          />
        ),
        [SarColumn.EXERCISE_DATE]: (
          <DatePicker
            className="px-0"
            onSelect={(date) => setFormData(`stepOne.sar.sarItems.${rowIndex}.date`, date)}
          >
            <FormInput
              autoComplete="off"
              control={control}
              customValue={(value) => {
                return value && format(value, 'dd/MM/yyyy');
              }}
              icon={<CalendarIcon className="size-5" iconColor="#344054" />}
              inputClassName="h-10 w-[140px]"
              name={`stepOne.sar.sarItems.${rowIndex}.date`}
              wrapperClassName="w-full"
            />
          </DatePicker>
        ),
        [SarColumn.SAR_FOR_EXERCISING]: sarItem?.grant?.grantItem?.plan?.pool?.shareClass?.name,
        [SarColumn.BASE_PRICE]: `$${toNumber(sarItem?.grant?.grantItem?.plan?.sarBasePrice)}`,
        [SarColumn.SHARE_PRICE]: (
          <FormInput
            control={control}
            iconBeforeInput={<span>$</span>}
            iconBeforeInputClassName="mt-1 -mr-1"
            name={`stepOne.sar.sarItems.${rowIndex}.sharePrice`}
            shouldFormatNumber
            wrapperClassName="h-10 "
          />
        ),
        [SarColumn.NO_OF_SARS]: (
          <div className="mt-6 flex flex-col gap-2">
            <FormInput
              control={control}
              isShownErrorMessage={false}
              name={`stepOne.sar.sarItems.${rowIndex}.sarCount`}
              shouldFormatNumber
              wrapperClassName="h-10"
            />
            <span className="text-xs font-[450] text-gray-600">Available: {availableNoOfSar}</span>
          </div>
        ),
        [SarColumn.SAR_PAYOUT]: (
          <div className="mt-6 flex flex-col gap-2">
            <FormInput
              control={control}
              isShownErrorMessage={false}
              name={`stepOne.sar.sarItems.${rowIndex}.sarPayout`}
              shouldFormatNumber
              wrapperClassName="h-10"
            />
            <span className="text-xs font-[450] text-gray-600">
              Available: {availableSarPayout}
            </span>
          </div>
        ),
        [SarColumn.ISSUED_SHARES]: (
          <div className="mt-6 flex flex-col gap-2">
            <FormInput
              control={control}
              isShownErrorMessage={false}
              name={`stepOne.sar.sarItems.${rowIndex}.issuedShares`}
              shouldFormatNumber
              wrapperClassName="h-10"
            />
            <span className="text-xs font-[450] text-gray-600">
              Available: {Math.floor(availableIssuedShares)}
            </span>
          </div>
        ),
        [SarColumn.REMOVE_ROW]: totalItems > 1 && (
          <>
            <Button
              className="flex h-full w-full items-center justify-center"
              onClick={handleRemoveRow}
              styleType="NONE"
            >
              <CloseIcon />
            </Button>
            {checkSharesCount && (
              <span className="w-[400px] px-4 pb-4 text-xs font-[450] text-fireside-600">
                All No of SARs must be distributed
              </span>
            )}
          </>
        ),
      }}
      tdClassName="pl-4 h-[108px]"
    />
  );
};
