import { useQueryClient } from '@tanstack/react-query';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { NotificationType } from '../../types/notification.types';
import { useAppQuery } from '../useAppQuery';

export const useUnreadNotificationCount = ({
  companyId,
  type,
}: {
  companyId: string;
  type?: NotificationType;
}) => {
  const queryClient = useQueryClient();

  const { data: count, isLoading } = useAppQuery({
    queryKey: [QueryKey.GET_UNREAD_NOTIFICATION_COUNT, companyId],
    queryFn: () =>
      ApiService.get<number>(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'notifications', 'unread-count'],
        },
        { queryParams: { type } },
      ),
    enabled: !!companyId,
  });

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_UNREAD_NOTIFICATION_COUNT] });
  };

  return {
    unread: {
      count,
      type,
    },
    isLoading,
    invalidateQuery,
  };
};
