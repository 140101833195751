import { useQueryClient } from '@tanstack/react-query';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { CloseModalButton } from '../../../../components/CloseModalButton';
import { BackendRoute } from '../../../../config';
import { QueryKey } from '../../../../constants';
import { EventHooks, useAppMutation, useReactForm } from '../../../../hooks';
import { ApiService } from '../../../../services';
import {
  EventFormType,
  EventTypeBackend,
  eventTypeMapping,
  EventTypeTitle,
} from '../../../../types/events.types';
import { toBackendDateFormat } from '../../../../utils/toBackendDateFormat';
import { InitialStep, StepOne } from '../Steps';
import { FinalStep } from '../Steps/FinalStep';
import { OptionalStep } from '../Steps/OptionalStep';
import { StepTwo } from '../Steps/StepTwo';
import { ChildrenFormProps } from '../type';
import { FormSchema, formSchema } from '../validation';

export type AddEventFormProps = {
  handleClose: () => void;
  companyId: string;
  issuedShares: number;
  invalidateQuery: () => void;
};

const initialFormSteps: ((props: ChildrenFormProps) => ReactNode)[] = [
  (props) => <InitialStep {...props} />,
  (props) => <StepOne {...props} />,
  (props) => <StepTwo {...props} />,
  (props) => <FinalStep {...props} />,
];

export const AddEventForm: FC<AddEventFormProps> = ({
  companyId,
  issuedShares,
  handleClose,
  invalidateQuery,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    getFieldState,
    setError,
    clearErrors,
    formState: { errors },
  } = useReactForm({
    schema: formSchema,
    mode: 'onChange',
  });
  const queryClient = useQueryClient();
  const { initialStep } = useWatch<FormSchema>({ control });
  const { create } = EventHooks.useCreate();
  const [formSteps, setFormSteps] = useState(initialFormSteps);
  const { callback } = ApiService.uploadFile();

  const { mutate: uploadBulkGrant } = useAppMutation([QueryKey.GRANT_BULK_IMPORT, companyId], {
    defaultErrorHandling: false,
    mutationFn: async ({ file }: { file: File }) => {
      return callback(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'events', 'bulk-grant'],
        },
        ['file', file],
      );
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.GET_EVENTS] });
      toast.success('Grants successfully imported');
      invalidateQuery();
    },
    onError: async (error) => {
      try {
        if (error.response) {
          const response: any =
            typeof error.response === 'string' ? JSON.parse(error.response) : error.response;

          const errorMessage = response?.message;

          if (Array.isArray(errorMessage)) {
            errorMessage.forEach((err) => {
              if (err?.children?.length > 0) {
                const childError = err.children[0].children[0].children[0];
                const constraintKey = Object.keys(childError.constraints)[0];
                const constraintMessage = childError.constraints[constraintKey];
                toast.error(`Error in property "${childError.property}": ${constraintMessage}`);
              } else {
                toast.error(`Error in property "${err.property}": ${err.value}`);
              }
            });
          } else if (typeof errorMessage === 'object') {
            toast.error(JSON.stringify(errorMessage));
          } else {
            toast.error(response?.message);
          }
        } else {
          toast.error('Unexpected error occurred');
        }
      } catch (parseError) {
        toast.error('An error occurred, and the response could not be parsed.');
      }
    },
  });

  const submitHandler = useCallback(
    (data: FormSchema) => {
      const { initialStep, stepOne, optionalStep, stepTwo } = data;

      const type = eventTypeMapping[initialStep.type];

      const grant = initialStep.type === EventFormType.GRANT &&
        stepOne?.eventDetails?.grant?.grantItems && {
          items: stepOne.eventDetails.grant.grantItems.map(
            ({ grantDate, sharePlan, stakeholder, shares }) => ({
              date: toBackendDateFormat(grantDate),
              planId: sharePlan.id,
              stakeholderId: stakeholder.id,
              numbersOfGrants: shares,
            }),
          ),
        };

      const fundraisingRound = initialStep.type === EventFormType.FUNDRAISING_ROUND &&
        stepOne.eventDetails?.['fundraising-round']?.enabled &&
        optionalStep?.['fundraising-round']?.investmentItems && {
          valuationId: stepOne.eventDetails['fundraising-round'].event.id,
          sharePrice: stepOne.eventDetails['fundraising-round'].event.sharePrice,
          isOpen: stepOne.eventDetails['fundraising-round'].isOpen,
          items: optionalStep?.['fundraising-round'].investmentItems.map(
            ({ investment, investmentDate, shareClass, stakeholder }) => ({
              stakeholderId: stakeholder.id,
              shareClassId: shareClass.id,
              investedValue: investment,
              date: toBackendDateFormat(investmentDate),
            }),
          ),
        };

      const issuance = initialStep.type === EventFormType.SHARE_ISSUANCE &&
        stepOne.eventDetails?.['share-issuance']?.enabled &&
        optionalStep?.['share-issuance']?.issuanceItems && {
          valuationId: stepOne.eventDetails['share-issuance'].event.id,
          date: toBackendDateFormat(stepOne.eventDetails['share-issuance'].date),
          sharePrice: stepOne.eventDetails['share-issuance'].event.sharePrice,
          items: optionalStep?.['share-issuance']?.issuanceItems.map(
            ({ shares, shareClass, stakeholder }) => ({
              stakeholderId: stakeholder.id,
              shareClassId: shareClass.id,
              sharesCount: shares,
            }),
          ),
        };

      const buyback = initialStep.type === EventFormType.BUYBACK &&
        stepOne?.eventDetails?.buyback?.buyBackItems && {
          items: stepOne?.eventDetails.buyback?.buyBackItems.map(
            ({ date, shareClass, stakeholder, shares }) => ({
              date: toBackendDateFormat(date),
              stakeholderId: stakeholder.id,
              shareClassId: shareClass.id,
              sharesCount: shares,
            }),
          ),
        };

      const secondaries = initialStep.type === EventFormType.SECONDARIES &&
        stepOne?.eventDetails?.secondaries?.secondariesItems && {
          items: stepOne?.eventDetails?.secondaries?.secondariesItems.map(
            ({ date, shareClass, shares, shareValue, stakeholderFrom, stakeholderTo }) => ({
              date: toBackendDateFormat(date),
              fromStakeholderId: stakeholderFrom.id,
              toStakeholderId: stakeholderTo.id,
              shareClassId: shareClass.id,
              sharesCount: shares,
              sharesValue: shareValue,
            }),
          ),
        };

      const conversion = initialStep.type === EventFormType.CONVERSION &&
        stepOne?.eventDetails?.conversion?.classConversionItems && {
          items: stepOne?.eventDetails?.conversion?.classConversionItems.map(
            ({ date, shares, shareClassFrom, shareClassTo, stakeholder }) => ({
              date: toBackendDateFormat(date),
              stakeholderId: stakeholder.id,
              fromShareClassId: shareClassFrom.id,
              toShareClassId: shareClassTo.id,
              sharesCount: shares,
            }),
          ),
        };

      const valuation = initialStep.type === EventFormType.VALUATION &&
        stepOne.eventDetails.valuation?.enabled && {
          name: stepOne?.eventDetails.valuation?.name,
          date: toBackendDateFormat(stepOne?.eventDetails?.valuation?.date),
          sharePrice: stepOne?.eventDetails.valuation?.sharePrice,
        };

      if (
        initialStep.type === EventFormType.BULK_IMPORT_GRANT &&
        stepOne.eventDetails['bulk-import-grant']?.file
      ) {
        uploadBulkGrant({ file: stepOne.eventDetails['bulk-import-grant'].file });
        invalidateQuery();
        handleClose();
        return;
      } else if (
        initialStep.type === EventFormType.FUNDRAISING_ROUND &&
        stepOne?.eventDetails?.['fundraising-round']?.event?.id === 'new' &&
        stepOne?.eventDetails?.['fundraising-round']?.event?.name &&
        stepOne?.eventDetails?.['fundraising-round']?.event?.sharePrice
      ) {
        create(
          {
            companyId,
            data: {
              type: EventTypeBackend.VALUATION,
              valuation: {
                name: stepOne?.eventDetails?.['fundraising-round']?.event?.name,
                sharePrice: Number(stepOne?.eventDetails?.['fundraising-round']?.event?.sharePrice),
                date: toBackendDateFormat(stepOne?.eventDetails?.['fundraising-round']?.date),
              },
              filesLinks: [],
              additionalNotes: '',
            },
          },
          {
            onSuccess: (data) => {
              if (data.valuation?.id) {
                const fundraisingRoundWithValuation = fundraisingRound && {
                  ...fundraisingRound,
                  valuationId: data.valuation?.id,
                };

                create(
                  {
                    companyId,
                    data: {
                      type: type,
                      fundraisingRound: fundraisingRoundWithValuation || undefined,
                      filesLinks:
                        stepTwo?.files?.reduce<string[]>(
                          (prev, curr) => [...prev, curr.docLink],
                          [],
                        ) || [],
                      additionalNotes: stepTwo?.additionalNotes || '',
                    },
                  },
                  {
                    onSuccess: () => {
                      invalidateQuery();
                    },
                  },
                );
              }
              toast.success('New event created successfully');
              setTimeout(() => {
                invalidateQuery();
              }, 100);
              handleClose();
            },
          },
        );
        return;
      } else if (
        initialStep?.type === EventFormType.SHARE_ISSUANCE &&
        stepOne?.eventDetails?.['share-issuance']?.date &&
        stepOne?.eventDetails?.['share-issuance']?.event?.id === 'new' &&
        stepOne?.eventDetails?.['share-issuance']?.event?.name &&
        stepOne?.eventDetails?.['share-issuance']?.event?.sharePrice
      ) {
        create(
          {
            companyId,
            data: {
              type: EventTypeBackend.VALUATION,
              valuation: {
                name: stepOne?.eventDetails?.['share-issuance']?.event?.name,
                sharePrice: Number(stepOne?.eventDetails?.['share-issuance']?.event?.sharePrice),
                date: toBackendDateFormat(stepOne?.eventDetails?.['share-issuance']?.date),
              },
              filesLinks: [],
              additionalNotes: '',
            },
          },
          {
            onSuccess: (data) => {
              if (data.valuation?.id) {
                const issuanceWithValuation = issuance && {
                  ...issuance,
                  valuationId: data.valuation?.id,
                };

                create(
                  {
                    companyId,
                    data: {
                      type: type,
                      issuance: issuanceWithValuation || undefined,
                      filesLinks:
                        stepTwo?.files?.reduce<string[]>(
                          (prev, curr) => [...prev, curr.docLink],
                          [],
                        ) || [],
                      additionalNotes: stepTwo?.additionalNotes || '',
                    },
                  },
                  {
                    onSuccess: () => {
                      invalidateQuery();
                    },
                  },
                );
              }
              toast.success('New event created successfully');
              setTimeout(() => {
                invalidateQuery();
              }, 100);
              handleClose();
            },
          },
        );
        return;
      } else {
        create(
          {
            companyId,
            data: {
              type: type,
              grant: grant || undefined,
              fundraisingRound: fundraisingRound || undefined,
              issuance: issuance || undefined,
              buyback: buyback || undefined,
              secondaries: secondaries || undefined,
              conversion: conversion || undefined,
              valuation: valuation || undefined,
              filesLinks:
                stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
              additionalNotes: stepTwo?.additionalNotes || '',
            },
          },
          {
            onSuccess: () => {
              toast.success('New event created successfully');
              invalidateQuery();
              handleClose();
            },
          },
        );
      }
      return;
    },
    [uploadBulkGrant, invalidateQuery, handleClose, create, companyId],
  );

  const handleCloseModal = useCallback(() => {
    reset();
    handleClose();
    setCurrentStep(1);
  }, [reset, handleClose]);

  const handleNextStep = useCallback(async () => {
    const nextStep = currentStep + 1;
    if (nextStep === Object.keys(formSteps).length + 1) {
      handleSubmit(submitHandler)();
      return;
    }
    if (nextStep > Object.keys(formSteps).length) return;

    setCurrentStep(nextStep);
  }, [currentStep, handleSubmit, submitHandler, formSteps]);

  useEffect(() => {
    if (initialStep?.type) {
      if (
        initialStep.type === EventFormType.FUNDRAISING_ROUND ||
        initialStep.type === EventFormType.SHARE_ISSUANCE
      ) {
        setFormSteps([
          (props) => <InitialStep {...props} />,
          (props) => <StepOne {...props} />,
          (props) => <OptionalStep {...props} />,
          (props) => <StepTwo {...props} />,
          (props) => <FinalStep {...props} />,
        ]);
      } else if (initialStep.type === EventFormType.BULK_IMPORT_GRANT) {
        setFormSteps([(props) => <InitialStep {...props} />, (props) => <StepOne {...props} />]);
      } else {
        setFormSteps([
          (props) => <InitialStep {...props} />,
          (props) => <StepOne {...props} />,
          (props) => <StepTwo {...props} />,
          (props) => <FinalStep {...props} />,
        ]);
      }
    }
  }, [initialStep?.type]);

  const handlePrevStep = useCallback(() => {
    const prevStep = currentStep - 1;
    if (prevStep < 1) return;
    setCurrentStep(prevStep);
  }, [currentStep]);
  return (
    <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
      <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
        <span className="text-xl font-[550] text-gray-700">
          {initialStep?.type ? EventTypeTitle[initialStep.type] : 'Add Event'}
        </span>
        <CloseModalButton onClose={handleCloseModal} />
      </div>
      {currentStep !== 1 && (
        <div className="flex w-full items-center gap-[5px] bg-white px-6 pb-6">
          {formSteps.slice(1).map((_, i) => (
            <div
              className={twMerge(
                'h-1 w-full rounded-[25px]',
                i + 1 < currentStep ? 'bg-[#12B76A]' : 'bg-gray-100',
                i + 1 === currentStep &&
                  currentStep === formSteps.length &&
                  'bg-gradient-to-r from-forest-500 to-forest-300',
              )}
              key={`${i}_${companyId}`}
            />
          ))}
        </div>
      )}

      <div className="flex h-full flex-col gap-4 overflow-hidden">
        {formSteps[currentStep - 1]({
          companyId,
          issuedShares,
          formData: getValues,
          nextFormStep: handleNextStep,
          prevFormStep: handlePrevStep,
          setFormData: setValue,
          control,
          clearErrors,
          handleCloseModal,
          setError,
          filedState: getFieldState,
          errors,
        })}
      </div>
    </div>
  );
};
