import { z } from 'zod';

import { TerminationCause } from '../../../../types/stakeholderTypes';

export const terminateForm = z.object({
  stakeholderItems: z
    .array(
      z.object({
        id: z.string().trim().min(1),
        terminationCause: z.nativeEnum(TerminationCause),
        terminationDate: z.coerce.date(),
        grantItems: z
          .array(
            z.object({
              id: z.string().trim().min(1),
              finalExerciseDate: z.coerce.date().optional(),
            }),
          )
          .optional(),
      }),
    )
    .min(1, { message: 'Required' }),
  isAgree: z.boolean().refine((value) => value === true, {
    message: 'isAgree must be true',
  }),
  isSendEmails: z.boolean().optional(),
});

export const formSchema = z.object({
  terminateForm,
});

export type FormSchema = z.infer<typeof formSchema>;
