import { Field, Label } from '@headlessui/react';
import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Control,
  useFieldArray,
  UseFormResetField,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

import MinusIcon from '../../../../../../assets/icons/MinusIcon';
import { Checkbox } from '../../../../../../components/Checkbox';
import { Table } from '../../../../../../components/Table';
import { SharePlan } from '../../../../../../hooks';
import { GrantEventItem, Valuation } from '../../../../../../types/events.types';
import { toNumber } from '../../../../../../utils/toNumber';
import { FormSchema } from '../../validation';
import { HurdleTableRow } from './HurdleTableRow';
import { ApproveHurdleColumn, approveHurdleColumnMap, approveHurdleColumnTitle } from './types';

export type HurdleProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  resetField: UseFormResetField<FormSchema>;
  valuation: Valuation;
};

export const Hurdle: FC<HurdleProps> = ({ control, companyId, valuation }) => {
  const { stepOne } = useWatch<FormSchema>({ control });

  const { approveHurdleGrants } = SharePlan.useSharePlanGrantItems({
    companyId,
    sharePlanId: stepOne?.hurdle?.planId || '',
  });

  const [selectedStakeholderIds, setSelectedStakeholderIds] = useState<string[]>([]);

  const {
    fields: hurdleItemsFields,
    update: updateHurdleItem,
    replace: replaceHurdleItems,
  } = useFieldArray({
    control,
    name: 'stepOne.hurdle.hurdleItems',
  });

  const onDeselectAll = () => {
    setSelectedStakeholderIds([]);
    replaceHurdleItems([]);
  };

  const onSelectAll = (stakeholdersId: string[]) => {
    setSelectedStakeholderIds(stakeholdersId);
    replaceHurdleItems(approveHurdleGrants.map(handlePrepareHurdleItem));
  };

  const handleSelectStakeholder = (stakeholderId: string, hurdleItemsIndex: number) => {
    setSelectedStakeholderIds((prev) => [...prev, stakeholderId]);
    updateHurdleItem(hurdleItemsIndex, { ...hurdleItemsFields[hurdleItemsIndex], selected: true });
  };
  const handleDeselectStakeholder = (stakeholderId: string, hurdleItemsIndex: number) => {
    setSelectedStakeholderIds((prev) => prev.filter((id) => id !== stakeholderId));
    updateHurdleItem(hurdleItemsIndex, { ...hurdleItemsFields[hurdleItemsIndex], selected: false });
  };

  const handlePrepareHurdleItem = useCallback(
    ({
      stakeholder,
      numbersOfGrants,
      plan: {
        hardleValue,
        pool: { shareClass },
      },
      id: grantId,
    }: GrantEventItem) => ({
      stakeholder,
      grantId,
      selected: true,
      hurdleValue: toNumber(hardleValue),
      shareClassName: shareClass.name,
      issuedShares: numbersOfGrants,
      date: new Date(),
    }),
    [],
  );

  useEffect(() => {
    if (approveHurdleGrants.length === hurdleItemsFields.length) return;
    replaceHurdleItems(
      approveHurdleGrants.map((grantItem) => {
        setSelectedStakeholderIds((prev) => [...prev, grantItem.stakeholder.id]);
        return handlePrepareHurdleItem(grantItem);
      }),
    );
  }, [approveHurdleGrants, handlePrepareHurdleItem, hurdleItemsFields.length, replaceHurdleItems]);

  return (
    <>
      <div className="max-h-[400px] w-[1309px] overflow-y-auto rounded-lg border-[1px] border-gray-100">
        <Table
          columns={approveHurdleColumnMap}
          columnsTitle={{
            ...approveHurdleColumnTitle,
            [ApproveHurdleColumn.SELECT]: (
              <Field className="flex cursor-pointer items-center">
                <Checkbox
                  checked={selectedStakeholderIds.length > 0}
                  id="select-all-hurdle-plans"
                  onChange={(checked) =>
                    checked
                      ? onSelectAll(hurdleItemsFields?.map(({ stakeholder: { id } }) => id) || [])
                      : onDeselectAll()
                  }
                  selectIcon={<MinusIcon />}
                />
                <Label
                  className="absolute left-0 top-0 h-full w-full cursor-pointer"
                  htmlFor="select-all-hurdle-plans"
                />
              </Field>
            ),
          }}
          tHeadClassNames={{
            thClassName: 'pl-4',
            spanClassName: 'font-500 whitespace-nowrap text-#172335 text-label-md',
          }}
        >
          {hurdleItemsFields.map((approveItem, i) => {
            const {
              hurdleValue,
              grantId,
              issuedShares,
              shareClassName,
              stakeholder: { id, fullName },
            } = approveItem;

            return (
              <HurdleTableRow
                currentValuation={toNumber(valuation.issuedShares) * valuation.sharePrice}
                grantId={grantId}
                hurdleValue={hurdleValue}
                id={id}
                isSelected={selectedStakeholderIds.includes(id)}
                issuedShares={issuedShares}
                key={id}
                onDateChange={(date) => updateHurdleItem(i, { ...approveItem, date })}
                onDeselect={(stakeholderId) => handleDeselectStakeholder(stakeholderId, i)}
                onSelect={(stakeholderId) => handleSelectStakeholder(stakeholderId, i)}
                shareClassName={shareClassName}
                stakeholderName={fullName}
              />
            );
          })}
        </Table>
      </div>
    </>
  );
};
