import { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { NotificationHooks, useFormat } from '../../../hooks';
import {
  notificationActionTitle,
  NotificationType,
  requestNotificationBuilder,
} from '../../../types/notification.types';
import { SharePlanType } from '../../../types/pool-plans.types';
import { StakeholderType } from '../../../types/stakeholderTypes';
import { toNumber } from '../../../utils/toNumber';
import Loader from '../../Loader';
import { RequestNotification } from '../Notification';

export type RequestedTabProps = {
  companyId: string;
  onExerciseRequest: (id: string, type: SharePlanType | undefined) => void;
  unreadOnly: boolean;
  isAllRead: boolean;
};

export const RequestedTab: FC<RequestedTabProps> = ({
  companyId,
  onExerciseRequest,
  unreadOnly,
  isAllRead,
}) => {
  const { format } = useFormat();
  const { notifications, fetchNextPage, hasNextPage } = NotificationHooks.useNotifications({
    companyId,
    type: NotificationType.REQUEST,
    unreadOnly,
  });

  const { markAsRead } = NotificationHooks.useMarkRead({ companyId });
  const { markAsResolved } = NotificationHooks.useMarkResolved({
    companyId,
  });

  return (
    <InfiniteScroll
      className="flex w-full flex-col gap-2"
      dataLength={notifications.length || 0}
      hasMore={hasNextPage}
      loader={<Loader />}
      next={fetchNextPage}
      scrollableTarget="scroll-notifications-target"
    >
      {notifications.map(
        ({
          createdAt,
          is_read: isRead,
          id,
          action,
          company,
          stakeholderName = '',
          adminId,
          stakeholderType = StakeholderType.OTHER_INST,
          adminName = '',
          type,
          exerciseRequest: {
            id: exerciseRequestId,
            sharesCount,
            vestingTask: {
              grantItem: { plan, numbersOfGrants },
              totalSharesCount,
              nextTriggerTime,
            },
          } = { totalSharesCount: 0, vestingTask: { grantItem: { plan: {}, numbersOfGrants: 0 } } },
        }) => (
          <RequestNotification
            createdAt={new Date(createdAt)}
            isRead={isAllRead || isRead}
            key={id}
            message={requestNotificationBuilder({
              hurdleValue: toNumber(plan?.hardleValue),
              milestoneDate: format(nextTriggerTime, 'dd/MM/yyyy'),
              milestoneName: '',
              planAvailableCount: toNumber(totalSharesCount) - toNumber(numbersOfGrants),
              planGrunts: toNumber(numbersOfGrants),
              planName: plan?.name || '',
              planType: plan?.type || SharePlanType.BSPCE,
              requestedSharesCount: toNumber(sharesCount),
              adminName,
              stakeholderName,
              isAdminYou: adminId === company?.stakeholder?.id,
              stakeholderType,
            })}
            onExercise={() => {
              onExerciseRequest(exerciseRequestId || '', plan?.type);
            }}
            onMarkRead={() => markAsRead({ id, type })}
            onMarkResolved={() => markAsResolved(id)}
            type={(action && notificationActionTitle[action]) || ''}
          />
        ),
      )}
    </InfiniteScroll>
  );
};
