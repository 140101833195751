import { Transition } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import React, {
  FC,
  Fragment,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import {
  AddUserIcon,
  ChevronDownIcon,
  CloseIcon,
  EditIcon,
  ExportIcon,
  LockIcon,
  PlusIcon,
  SearchIcon,
  UploadIcon,
} from '../../assets/icons';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { Input } from '../../components/Input';
import Loader from '../../components/Loader/Loader';
import { Select, SelectButton, SelectOption, SelectOptions } from '../../components/Select';
import { Sheet, SheetContent, SheetTrigger } from '../../components/Sheet';
import { QueryKey } from '../../constants';
import {
  Stakeholder as StakeholderHook,
  useDebounce,
  useEventOutsideElement,
  useLocale,
  useModalState,
  useQueryParams,
  useScreenSize,
  useSelectedCompany,
} from '../../hooks';
import { StringKey } from '../../lang';
import { companySettingsTabStore } from '../../storage/companySettingsTabStore';
import { sideBarContext } from '../../storage/sideBarContext';
import { stakeholdersSortStore } from '../../storage/stakeholdersStortStorage';
import { stakeholderTypeTranslation } from '../../translations/stakeholderTranslation';
import {
  FilterVariant,
  filterVariant,
  filterVariantMap,
  Stakeholder,
  StakeholderOrder,
  StakeholderRole,
  StakeholderSortBy,
  StakeholderType,
} from '../../types/stakeholderTypes';
import { CompanySettingsCardTab } from '../CompanySettings/types';
import { StakeholdersCard, TerminatedCard } from './Cards';
import CancelInvitationModal from './modals/CancelInviteModal';
import DeleteStakeholderModal from './modals/DeleteStakeholderModal';
import GrantAccessModal from './modals/GrantAccessModal';
import MakeContributorModal from './modals/MakeContributorModal';
import ResendInvitationModal from './modals/ResendInviteModal';
import RevokeAccessModal from './modals/RevokeAccessModal';
import UpgradePlanModal from './modals/UpgradePlanModal';
import AddStakeholderModal from './StakeholderActions/AddStakeholderModal';
import {
  BulkImportStakeholdersModal,
  BulkModifyStakeholdersModal,
} from './StakeholderActions/BulkActions';
import BulkActionsModal from './StakeholderActions/BulkActionsModal';
import EditStakeholderModal from './StakeholderActions/EditStakeholderModal';
import {
  EditStakeholderTerminationModal,
  TerminateStakeholderModal,
} from './StakeholderActions/TerminateStakeholderModal';
import { StakeholderTableProps } from './StakeholderTable/StakeholderTable';
import { arrayOfStakeholderCardTab, StakeholderCard, stakeholderCardTranslation } from './types';

const findStakeholderTypeKeys = (search: string) =>
  Object.entries(stakeholderTypeTranslation)
    .filter(([_, value]) => (search ? value.toLowerCase().includes(search.toLowerCase()) : false))
    .map(([key]) => key as StakeholderType);

const numberOfFetchedItems = 7;

export type StakeholdersCardComponentProps = {
  currentPage: number;
  handlePageChange: (page: number) => void;
  totalPages: number;
  isDemo: boolean;
} & Omit<StakeholderTableProps, 'className'>;

const stakeholdersCardComponentVariant: Record<
  StakeholderCard,
  (props: StakeholdersCardComponentProps) => ReactNode
> = {
  [StakeholderCard.STAKEHOLDERS]: (props) => <StakeholdersCard {...props} />,
  [StakeholderCard.TERMINATED]: (props) => <TerminatedCard {...props} />,
};

export const transformDatetoISOString = (year: number, month: number, day: number) =>
  new Date(Date.UTC(year, month - 1, day)).toISOString();

export type SelectedStakeholder = {
  isActive: boolean;
  role: StakeholderRole;
  isAccessRevoked: boolean;
  isInvited: boolean;
  id: string;
};

const Stakeholders: FC = () => {
  const [selectedFilter, setSelectedFilter] = useState<FilterVariant>('all');
  const [sortState, setSortState] = useState<{
    field: StakeholderSortBy | null;
    sortMode: StakeholderOrder | null;
  }>(stakeholdersSortStore.get() || { field: null, sortMode: null });

  const [selectedStakeholderIds, setSelectedStakeholderIds] = useState<string[]>([]);
  const [selectedStakeholderId, setStakeholderId] = useState('');
  const [selectedCard, setCard] = useState<StakeholderCard>(StakeholderCard.STAKEHOLDERS);

  const { get } = useQueryParams();
  const navigate = useNavigate();

  const { selectedCompany } = useSelectedCompany();
  const companyId = selectedCompany?.id || '';

  const [search, setSearch] = useState<null | string>(null);
  const [isOpenSearch, setOpenSearch] = useState(false);
  const searchByType = useMemo(() => findStakeholderTypeKeys(search || ''), [search]);

  const [isFocusedInput, setInputFocus] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);

  const [currentPage, setCurrentPage] = useState(1);

  const { width } = useScreenSize();
  useEventOutsideElement(inputRef, 'click', () => !search && setOpenSearch(false));

  const [isOpenAddStakeholderModal, setOpenStakeholderModal] = useState(!!get('openAddModal'));
  const [isOpenEditStakeholderModal, setOpenEditStakeholderModal] = useState<{
    mode: 'EDIT' | 'VIEW';
    stakeholderId: string;
    isOpen: boolean;
  }>({
    mode: 'VIEW',
    stakeholderId: '',
    isOpen: false,
  });

  const { isOpen: isSideBarExpanded } = useContext(sideBarContext);

  const queryClient = useQueryClient();
  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [QueryKey.GET_STAKEHOLDERS],
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKey.GET_STAKEHOLDER],
    });
  }, [queryClient]);

  const { debounceCallback } = useDebounce({
    callback: () => {
      invalidateQuery();
    },
  });

  const { messagesLocale } = useLocale();
  const { deleteStakeholder } = StakeholderHook.useDelete();
  const { invite, cancelInvitation } = StakeholderHook.useInvitation();
  const { toggleAccess } = StakeholderHook.useToggleAccess();
  const { toggleStatus } = StakeholderHook.useToggleStatus();
  const { exportStakeholders } = StakeholderHook.useExport();

  const { refetch, stakeholders, isLoading, totalStakeholders, totalPages } =
    StakeholderHook.useStakeholders({
      companyId,
      filter: selectedFilter,
      orderBy: sortState.sortMode,
      currentPage,
      sortBy: sortState.field,
      search: search || '',
      searchType: searchByType,
      numberOfFetchedItems,
      isTerminated: selectedCard === StakeholderCard.TERMINATED,
    });

  const handleFilterSelect = (filter: FilterVariant) => {
    setSelectedFilter(filter);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = useCallback(
    (value: string) => {
      setSearch(value);
      debounceCallback(value);
    },
    [debounceCallback],
  );

  const handleSortField = useCallback(
    (newField: StakeholderSortBy) => {
      setSortState((prevState) => {
        if (prevState.field === newField) {
          const nextSortMode = !prevState.sortMode
            ? StakeholderOrder.ASC
            : prevState.sortMode === StakeholderOrder.ASC
              ? StakeholderOrder.DESC
              : null;

          stakeholdersSortStore.set({ ...prevState, sortMode: nextSortMode });
          return { ...prevState, sortMode: nextSortMode };
        } else {
          stakeholdersSortStore.set({
            field: newField,
            sortMode: StakeholderOrder.ASC,
          });
          return { field: newField, sortMode: StakeholderOrder.ASC };
        }
      });
      invalidateQuery();
    },
    [invalidateQuery],
  );

  useEffect(() => {
    if (!search && companyId) {
      refetch();
    }
  }, [companyId, refetch, search]);

  const {
    isOpen: isOpenDeleteModal,
    handleDismissModal: handleDismissDeleteModal,
    handleSuccessModal: handleSuccessDeleteModal,
    handleOpenModal: handleOpenDeleteModal,
  } = useModalState<string>({
    onSuccess: (successData) => {
      successData &&
        deleteStakeholder(
          { companyId, stakeholderId: successData },
          {
            onSuccess: () => {
              toast.success(messagesLocale[StringKey.STAKEHOLDER_DELETED_TOAST]);
              invalidateQuery();
              if (stakeholders?.length === 1) setCurrentPage((prev) => (prev > 1 ? prev - 1 : 1));
            },
          },
        );
    },
  });

  const {
    isOpen: isOpenCancelInvitationModal,
    handleDismissModal: handleDismissCancelInvitationModal,
    handleSuccessModal: handleSuccessCancelInvitationModal,
    handleOpenModal: handleOpenCancelInvitationModal,
  } = useModalState<string>({
    onSuccess: (stakeholderId) =>
      stakeholderId &&
      cancelInvitation(
        { companyId, stakeholderId },
        {
          onSuccess: () => {
            invalidateQuery();
            toast.success('Invitation successfully canceled');
          },
        },
      ),
  });

  const {
    isOpen: isOpenResendInvitationModal,
    handleDismissModal: handleDismissResendInvitationModal,
    handleSuccessModal: handleSuccessResendInvitationModal,
    handleOpenModal: handleOpenResendInvitationModal,
  } = useModalState<string>({
    onSuccess: (stakeholderId) => stakeholderId && invite({ companyId, stakeholderId }),
  });

  const {
    isOpen: isOpenRevokeAccessModal,
    handleDismissModal: handleDismissRevokeAccessModal,
    handleSuccessModal: handleSuccessRevokeAccessModal,
    handleOpenModal: handleOpenRevokeAccessModal,
  } = useModalState<string>({
    onSuccess: (stakeholderId) =>
      stakeholderId &&
      toggleAccess(
        { stakeholderId, companyId },
        {
          onSuccess: () => {
            invalidateQuery();
            toast.success('Access successfully changed');
          },
        },
      ),
  });

  const {
    isOpen: isOpenGrantAccessModal,
    handleDismissModal: handleDismissGrantAccessModal,
    handleSuccessModal: handleSuccessGrantAccessModal,
    handleOpenModal: handleOpenGrantAccessModal,
  } = useModalState<string>({
    onSuccess: (stakeholderId) =>
      stakeholderId &&
      toggleAccess(
        { stakeholderId, companyId },
        {
          onSuccess: () => {
            invalidateQuery();
            toast.success('Access successfully changed');
          },
        },
      ),
  });

  const {
    isOpen: isOpenMakeContributorModal,
    handleDismissModal: handleDismissMakeContributorModal,
    handleSuccessModal: handleSuccessMakeContributorModal,
    handleOpenModal: handleOpenMakeContributorModal,
  } = useModalState<string>({
    onSuccess: (stakeholderId) =>
      stakeholderId &&
      toggleStatus(
        { companyId, stakeholderId },
        {
          onSuccess: () => {
            invalidateQuery();
            toast.success('Role successfully changed');
          },
        },
      ),
  });

  const {
    isOpen: isOpenUpgradePlanModal,
    handleDismissModal: handleDismissUpgradePlanModal,
    handleSuccessModal: handleSuccessUpgradePlanModal,
    handleOpenModal: handleOpenUpgradePlanModal,
  } = useModalState({
    onSuccess: () => {
      companySettingsTabStore.set(CompanySettingsCardTab.COMPANY_PLAN_BILLING);
      navigate('/company-settings');
    },
  });

  const {
    isOpen: isOpenBulkImportModal,
    handleDismissModal: handleDismissBulkImportModal,
    handleOpenModal: handleOpenBulkImportModal,
  } = useModalState({});

  const {
    isOpen: isOpenBulkModifyModal,
    handleDismissModal: handleDismissBulkModifyModal,
    handleOpenModal: handleOpenBulkModifyModal,
  } = useModalState({});

  const {
    isOpen: isOpenBulkActionsModal,
    handleDismissModal: handleDismissBulkActionsModal,
    handleOpenModal: handleOpenBulkActionsModal,
    handleSuccessModal: handleSuccessBulkActionsModal,
  } = useModalState({
    onSuccess: () => setSelectedStakeholderIds([]),
  });

  const {
    handleCloseModal: handleCloseTerminateModal,
    handleOpenModal: handleOpenTerminateModal,
    isOpen: isOpenTerminateModal,
    modalData: stakeholdersToTerminate,
  } = useModalState<unknown, unknown, Stakeholder[]>();

  const {
    handleCloseModal: handleCloseEditTerminationModal,
    handleOpenModal: handleOpenEditTerminationModal,
    isOpen: isOpenEditTerminationModal,
    modalData: stakeholdersToEditTermination,
  } = useModalState<unknown, unknown, Stakeholder[]>();

  const { bulkActions } = StakeholderHook.useBulkActions();

  useEffect(() => {
    if (selectedStakeholderIds.length !== 0) return handleOpenBulkActionsModal();
    handleDismissBulkActionsModal();
  }, [handleDismissBulkActionsModal, handleOpenBulkActionsModal, selectedStakeholderIds.length]);

  if (!stakeholders || isLoading || !selectedCompany) return <Loader />;

  const isCanAddStakeholders = (selectedCompany?.stakeholdersLimit || 0) > totalStakeholders;
  const isMobile = width <= 1023;
  const isTablet = width <= 1233;
  const showMobileSearchBar = isTablet || (width <= 1300 && isSideBarExpanded);

  return (
    <>
      <UpgradePlanModal
        isOpen={isOpenUpgradePlanModal}
        onDismiss={handleDismissUpgradePlanModal}
        onSuccess={handleSuccessUpgradePlanModal}
        planName={selectedCompany.plan?.title || ''}
        stakeholderRole={selectedCompany.roleInCompany}
      />
      <DeleteStakeholderModal
        isOpen={isOpenDeleteModal}
        onDismiss={() => handleDismissDeleteModal()}
        onSuccess={() => handleSuccessDeleteModal(selectedStakeholderId)}
      />
      <CancelInvitationModal
        isOpen={isOpenCancelInvitationModal}
        onDismiss={() => handleDismissCancelInvitationModal()}
        onSuccess={() => handleSuccessCancelInvitationModal(selectedStakeholderId)}
      />
      <ResendInvitationModal
        isOpen={isOpenResendInvitationModal}
        onDismiss={() => handleDismissResendInvitationModal()}
        onSuccess={() => handleSuccessResendInvitationModal(selectedStakeholderId)}
      />

      <MakeContributorModal
        isOpen={isOpenMakeContributorModal}
        onDismiss={() => handleDismissMakeContributorModal()}
        onSuccess={() => handleSuccessMakeContributorModal(selectedStakeholderId)}
      />

      <RevokeAccessModal
        isOpen={isOpenRevokeAccessModal}
        onDismiss={() => handleDismissRevokeAccessModal()}
        onSuccess={() => handleSuccessRevokeAccessModal(selectedStakeholderId)}
      />
      <GrantAccessModal
        isOpen={isOpenGrantAccessModal}
        onDismiss={() => handleDismissGrantAccessModal()}
        onSuccess={() => handleSuccessGrantAccessModal(selectedStakeholderId)}
      />

      <BulkImportStakeholdersModal
        companyId={companyId || ''}
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenBulkImportModal}
        setOpen={handleDismissBulkImportModal}
      />

      <BulkModifyStakeholdersModal
        companyId={companyId || ''}
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenBulkModifyModal}
        setOpen={handleDismissBulkModifyModal}
      />

      <AddStakeholderModal
        companyId={companyId || ''}
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenAddStakeholderModal}
        setOpen={setOpenStakeholderModal}
      />

      <EditStakeholderModal
        companyId={companyId || ''}
        invalidateQuery={invalidateQuery}
        isDefaultEditing={isOpenEditStakeholderModal?.mode === 'EDIT'}
        isDemo={selectedCompany.isDemo}
        isOpenModal={isOpenEditStakeholderModal?.isOpen || false}
        setClose={() =>
          setOpenEditStakeholderModal({
            mode: 'VIEW',
            isOpen: false,
            stakeholderId: '',
          })
        }
        stakeholderId={isOpenEditStakeholderModal?.stakeholderId || ''}
      />
      <BulkActionsModal
        handleCancelInvitation={({ id: stakeholderIds, onSuccess }) =>
          bulkActions(
            {
              companyId,
              action: 'CANCEL_INVITATION',
              stakeholderIds,
            },
            { onSuccess },
          )
        }
        handleDelete={({ id: stakeholderIds }) =>
          bulkActions({ action: 'DELETE', stakeholderIds, companyId })
        }
        handleGrantAccess={({ id: stakeholderIds, onSuccess }) =>
          bulkActions(
            {
              companyId,
              action: 'TOGGLE_ACCESS',
              stakeholderIds,
            },
            { onSuccess },
          )
        }
        handleMakeContributor={({ id: stakeholderIds, onSuccess }) =>
          bulkActions(
            {
              companyId,
              action: 'TOGGLE_CONTRIBUTOR',
              stakeholderIds,
            },
            { onSuccess },
          )
        }
        handleOpenEditTerminationModal={handleOpenEditTerminationModal}
        handleOpenTerminateModal={handleOpenTerminateModal}
        handleRemoveContributorRole={({ id: stakeholderIds, onSuccess }) =>
          bulkActions(
            {
              action: 'TOGGLE_CONTRIBUTOR',
              stakeholderIds,
              companyId,
            },
            { onSuccess },
          )
        }
        handleResendInvitation={({ id: stakeholderIds, onSuccess }) =>
          bulkActions(
            { action: 'INVITE', stakeholderIds, companyId },
            {
              onSuccess,
            },
          )
        }
        handleRevokeAccess={({ id: stakeholderIds, onSuccess }) =>
          bulkActions(
            {
              action: 'TOGGLE_ACCESS',
              stakeholderIds,
              companyId,
            },
            { onSuccess },
          )
        }
        handleSendInvitation={({ id: stakeholderIds, onSuccess }) =>
          bulkActions(
            { action: 'INVITE', stakeholderIds, companyId },
            {
              onSuccess,
            },
          )
        }
        handleSuccess={handleSuccessBulkActionsModal}
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenBulkActionsModal}
        onRemoveSelectAll={() => setSelectedStakeholderIds([])}
        selectedStakeholderIds={selectedStakeholderIds}
        stakeholders={stakeholders}
      />
      <TerminateStakeholderModal
        companyId={companyId}
        handleClose={handleCloseTerminateModal}
        invalidateQuery={invalidateQuery}
        isOpen={isOpenTerminateModal}
        setSelectedStakeholderIds={setSelectedStakeholderIds}
        stakeholders={stakeholdersToTerminate}
      />
      <EditStakeholderTerminationModal
        companyId={companyId}
        handleClose={handleCloseEditTerminationModal}
        invalidateQuery={invalidateQuery}
        isOpen={isOpenEditTerminationModal}
        setSelectedStakeholderIds={setSelectedStakeholderIds}
        stakeholders={stakeholdersToEditTermination}
      />
      <div className="flex h-fit min-h-[200px] w-full flex-col gap-4 overflow-hidden rounded-md lg:p-4 lg:shadow-sm">
        <div className="flex h-[38px] w-full gap-4 border-b-[1px] border-gray-200">
          {arrayOfStakeholderCardTab.map(([cardKey, card]) => (
            <Button
              className={twMerge(
                'flex h-full w-fit rounded-none border-b-2 border-transparent text-sm font-[450] text-gray-400',
                selectedCard === cardKey && 'border-brand-700 font-[550] text-brand-700',
              )}
              key={card}
              onClick={() => {
                setCard(cardKey);
                setCurrentPage(1);
              }}
              styleType="NONE"
            >
              <AppFormattedMessage id={card} />
            </Button>
          ))}
        </div>
        <div className="flex h-fit min-h-full w-full flex-col rounded-md max-lg:gap-4 lg:rounded-md lg:shadow-sm">
          <div className="flex max-h-16 w-full items-center justify-between gap-4 lg:p-4">
            <span className="text-base font-[550] text-[#172335] max-lg:hidden">
              <AppFormattedMessage id={stakeholderCardTranslation[selectedCard][selectedFilter]} />
            </span>
            <Select onChange={handleFilterSelect} value={selectedFilter}>
              {({ open }) => (
                <>
                  <div className="relative rounded border-gray-100 px-[6px] py-2 lg:hidden">
                    <SelectButton className="flex justify-between border-gray-100 bg-gray-50 p-3 px-[6px]">
                      <span className="text-sm font-[450] text-[#344054]">
                        <AppFormattedMessage id={filterVariant[selectedFilter]} />
                      </span>
                      <ChevronDownIcon className="h-4 w-4 text-gray-400" />
                    </SelectButton>

                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      show={open}
                    >
                      <SelectOptions>
                        {filterVariantMap.map(([filterKey, filter]) => (
                          <SelectOption key={filterKey} value={filterKey}>
                            {({ selected }) => (
                              <span
                                className={twMerge(
                                  selected ? 'font-[450]' : 'font-normal',
                                  'block text-sm text-gray-700',
                                )}
                              >
                                <AppFormattedMessage id={filter} />
                              </span>
                            )}
                          </SelectOption>
                        ))}
                      </SelectOptions>
                    </Transition>
                  </div>
                </>
              )}
            </Select>
            <div className="relative flex h-full w-fit items-center gap-3">
              <Sheet modal={false} open={showMobileSearchBar && isOpenSearch}>
                <div className="flex items-center justify-end" ref={inputRef}>
                  <Input
                    onBlur={() => setInputFocus(false)}
                    onChange={handleSearch}
                    onFocus={() => {
                      setInputFocus(true);
                    }}
                    placeholder={search ? '' : <AppFormattedMessage id={StringKey.SEARCH} />}
                    value={search || ''}
                    wrapperClassName={twMerge(
                      'transition-all duration-500 w-[200px] overflow-hidden max-h-9',
                      isOpenSearch ? 'delay-500' : 'w-0',
                      showMobileSearchBar && 'w-0',
                    )}
                  />
                  {!showMobileSearchBar && isOpenSearch && typeof search === 'string' && (
                    <div
                      className="absolute mr-10 cursor-pointer"
                      onClick={() => {
                        setSearch('');
                        invalidateQuery();
                      }}
                    >
                      <CloseIcon />
                    </div>
                  )}
                  <SheetTrigger
                    className={twMerge(
                      'flex size-9 items-center justify-center border-[1px] border-gray-100 bg-gray-50 transition-all duration-300',
                      !showMobileSearchBar && isOpenSearch
                        ? 'h-9 rounded-l-none rounded-br-none border-x-0 border-t-0 border-b-black'
                        : 'delay-1000',
                      !showMobileSearchBar &&
                        isFocusedInput &&
                        'border-b-[2px] border-b-brand-700 transition-none',
                    )}
                    onClick={() => {
                      setOpenSearch((prev) => !prev);
                      setSearch(null);
                      invalidateQuery();
                    }}
                  >
                    <SearchIcon iconColor={isFocusedInput ? '#2565C8' : '#344054'} />
                  </SheetTrigger>
                </div>
                <SheetContent
                  className="w-full"
                  onClick={(e) => e.stopPropagation()}
                  onInteractOutside={() => {
                    if (!search) {
                      setOpenSearch(false);
                      setSearch(null);
                      invalidateQuery();
                    }
                  }}
                  side="TOP"
                >
                  <div className={twMerge('flex')}>
                    <Input
                      icon={search && <CloseIcon />}
                      onBlur={() => setInputFocus(false)}
                      onChange={handleSearch}
                      onFocus={(e) => {
                        setInputFocus(true);
                        e.stopPropagation();
                      }}
                      onIconClick={() => {
                        setTimeout(() => {
                          setSearch('');
                          invalidateQuery();
                        }, 0);
                      }}
                      placeholder={<AppFormattedMessage id={StringKey.SEARCH} />}
                      value={search || ''}
                      wrapperClassName={twMerge('transition-all duration-1000 w-full')}
                    />
                  </div>
                </SheetContent>
              </Sheet>
              <Select onChange={handleFilterSelect} value={selectedFilter}>
                {({ open }) => (
                  <>
                    <div className="relative rounded border-gray-100 px-[6px] py-2 max-lg:hidden">
                      <SelectButton className="flex justify-between border-gray-100 bg-gray-50 p-3 px-[6px]">
                        <span className="text-sm font-[450] text-[#344054]">
                          <AppFormattedMessage id={filterVariant[selectedFilter]} />
                        </span>
                        <ChevronDownIcon className="h-4 w-4 text-gray-400" />
                      </SelectButton>

                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        show={open}
                      >
                        <SelectOptions>
                          {filterVariantMap.map(([filterKey, filter]) => (
                            <SelectOption key={filterKey} value={filterKey}>
                              {({ selected }) => (
                                <span
                                  className={twMerge(
                                    selected ? 'font-[450]' : 'font-normal',
                                    'block text-sm text-gray-700',
                                  )}
                                >
                                  <AppFormattedMessage id={filter} />
                                </span>
                              )}
                            </SelectOption>
                          ))}
                        </SelectOptions>
                      </Transition>
                    </div>
                  </>
                )}
              </Select>

              {!selectedCompany.isDemo && (
                <>
                  {' '}
                  {selectedCard === StakeholderCard.STAKEHOLDERS && (
                    <Button
                      className="flex size-9 gap-1 border-[1px] border-gray-100 bg-gray-25 px-3 py-[6px] max-lg:hidden lg:w-fit"
                      onClick={handleOpenBulkModifyModal}
                    >
                      <EditIcon />
                      <span className="text-sm font-[450] text-gray-700 max-lg:hidden">
                        {isSideBarExpanded && isTablet ? (
                          ''
                        ) : (
                          <AppFormattedMessage id={StringKey.BULK_MODIFY} />
                        )}
                      </span>
                    </Button>
                  )}
                  <Button
                    className="flex size-9 gap-1 border-[1px] border-gray-100 bg-gray-25 px-3 py-[6px] lg:w-fit"
                    onClick={() => exportStakeholders({ companyId })}
                  >
                    <ExportIcon className="max-lg:rotate-180" />
                    <span className="text-sm font-[450] text-gray-700 max-lg:hidden">
                      {isSideBarExpanded && isTablet ? (
                        ''
                      ) : (
                        <AppFormattedMessage id={StringKey.EXPORT} />
                      )}
                    </span>
                  </Button>
                  {selectedCard === StakeholderCard.STAKEHOLDERS && (
                    <Button
                      className="flex size-9 gap-1 border-[1px] border-gray-100 bg-gray-25 px-3 py-[6px] max-lg:hidden lg:w-fit"
                      onClick={handleOpenBulkImportModal}
                    >
                      <UploadIcon />
                      <span className="text-sm font-[450] text-gray-700">
                        {isSideBarExpanded && isTablet ? (
                          ''
                        ) : (
                          <AppFormattedMessage id={StringKey.IMPORT} />
                        )}
                      </span>
                    </Button>
                  )}
                  {selectedCard === StakeholderCard.STAKEHOLDERS && (
                    <Button
                      className={twMerge(
                        'flex size-9 gap-1 px-4 py-[6px] lg:w-fit',
                        !isCanAddStakeholders && 'bg-gray-400',
                      )}
                      onClick={() =>
                        isCanAddStakeholders
                          ? setOpenStakeholderModal(true)
                          : handleOpenUpgradePlanModal()
                      }
                    >
                      {isCanAddStakeholders ? (
                        isMobile ? (
                          <PlusIcon />
                        ) : (
                          <AddUserIcon />
                        )
                      ) : (
                        <LockIcon iconColor="#ffffff" />
                      )}
                      <span className={twMerge('text-sm font-[450] text-white max-lg:hidden')}>
                        {isSideBarExpanded && isTablet ? (
                          ''
                        ) : (
                          <AppFormattedMessage id={StringKey.ADD_STAKEHOLDER} />
                        )}
                      </span>
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="h-[1px] w-full bg-[#F2F2F2] lg:hidden" />
          <div
            className="flex h-full w-full flex-col justify-between gap-4 pb-4"
            style={{ minHeight: !isMobile ? 'calc(100vh - 270px)' : undefined }}
          >
            {stakeholdersCardComponentVariant[selectedCard]({
              currentPage,
              isDemo: selectedCompany.isDemo,
              handlePageChange,
              totalPages: totalPages || 0,
              accountId: selectedCompany?.stakeholder?.id || '',
              roleInCompany: selectedCompany?.roleInCompany,
              isSelectStarted: selectedStakeholderIds.length !== 0,
              onRemoveSelectAll: () => setSelectedStakeholderIds([]),
              onSelectAll: (stakeholders) => {
                setSelectedStakeholderIds(stakeholders);
              },
              isSelected: (selectedId) => {
                return !!selectedStakeholderIds.find((id) => id === selectedId);
              },
              onSelect: (stakeholderId) => {
                setSelectedStakeholderIds((prev) => [...prev, stakeholderId]);
              },
              onRemoveSelect: (selectedId) =>
                setSelectedStakeholderIds((prev) => prev.filter((id) => id !== selectedId)),
              sortField: sortState.field,
              sortMode: sortState.sortMode,
              searchValue: search || '',
              handleCancelInvitation: (stakeholderId) => {
                setStakeholderId(stakeholderId);
                handleOpenCancelInvitationModal();
              },
              handleGrantAccess: (stakeholderId) => {
                setStakeholderId(stakeholderId);
                handleOpenGrantAccessModal();
              },
              handleMakeContributor: (stakeholderId) => {
                setStakeholderId(stakeholderId);
                handleOpenMakeContributorModal();
              },
              handleRemoveContributorRole: (stakeholderId) => {
                setStakeholderId(stakeholderId);
                toggleStatus(
                  { stakeholderId, companyId },
                  {
                    onSuccess: () => {
                      invalidateQuery();
                      toast.success('Role successfully changed');
                    },
                  },
                );
              },
              handleResendInvitation: (stakeholderId) => {
                setStakeholderId(stakeholderId);
                handleOpenResendInvitationModal();
              },
              handleSendInvitation: (stakeholderId) => {
                setStakeholderId(stakeholderId);
                invite(
                  { companyId, stakeholderId },
                  {
                    onSuccess: () => {
                      invalidateQuery();
                      toast.success('Successfully sent invitation');
                    },
                  },
                );
              },
              handleRevokeAccess: (stakeholderId) => {
                setStakeholderId(stakeholderId);
                handleOpenRevokeAccessModal();
              },
              handleTerminate: (stakeholderId) =>
                handleOpenTerminateModal(
                  stakeholders.filter((stakeholder) => stakeholder.id === stakeholderId),
                ),
              handleEditTermination: (stakeholderId) =>
                handleOpenEditTerminationModal(
                  stakeholders.filter((stakeholder) => stakeholder.id === stakeholderId),
                ),
              handleView: (stakeholderId) =>
                setOpenEditStakeholderModal({
                  mode: 'VIEW',
                  stakeholderId,
                  isOpen: true,
                }),
              handleDelete: (stakeholderId) => {
                setStakeholderId(stakeholderId);
                handleOpenDeleteModal();
              },
              handleEdit: (stakeholderId) =>
                setOpenEditStakeholderModal({
                  mode: 'EDIT',
                  stakeholderId,
                  isOpen: true,
                }),
              onSortClick: (newField) => handleSortField(newField),
              data: stakeholders,
            })}
          </div>
        </div>
      </div>
    </>
  );
};

Stakeholders.displayName = 'Stakeholders';

export default Stakeholders;
