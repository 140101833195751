import * as React from 'react';
import { twMerge } from 'tailwind-merge';

import { CalendarIcon } from '../../assets/icons';
import { useFormat } from '../../hooks';
import { Calendar } from '../Calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover';

export type DatePickerProps = {
  onSelect?: (state: Date) => void;
  className?: string;
  children?: React.ReactNode;
};

export const DatePicker: React.FC<DatePickerProps> = ({ onSelect, className, children }) => {
  const [date, setDate] = React.useState<Date | undefined>(new Date());
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const { format } = useFormat();
  const handleDatePicked = (state: Date | undefined) => {
    state && onSelect?.(state);
    setDate(state);
    setIsPopoverOpen(false);
  };

  return (
    <Popover onOpenChange={setIsPopoverOpen} open={isPopoverOpen}>
      <PopoverTrigger
        className={twMerge(
          'flex w-full cursor-pointer items-center justify-center gap-1 px-3 py-[6px] font-normal',
          !date && 'text-muted-foreground',
          className,
        )}
      >
        {children || (
          <>
            {date ? (
              <span className="text-sm font-[450] text-gray-700">{format(date, 'dd/MM/yyyy')}</span>
            ) : (
              <span className="text-sm font-[450] text-gray-700">Date</span>
            )}
            <CalendarIcon />
          </>
        )}
      </PopoverTrigger>
      <PopoverContent className="z-[100] w-auto p-0">
        <Calendar initialFocus mode="single" onSelect={handleDatePicked} selected={date} />
      </PopoverContent>
    </Popover>
  );
};
