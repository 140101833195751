import { Company } from './companyTypes';
import { ExerciseRequest } from './exerciseRequest.types';
import { SharePlanType, sharePlanTypeTitle } from './pool-plans.types';
import {
  Stakeholder,
  StakeholderType,
  stakeholderTypeTitle,
  TerminationCause,
  TerminationCauseTitle,
} from './stakeholderTypes';

export enum NotificationType {
  ACTIVITY = 'activity',
  REQUEST = 'request',
}

export const notificationTypeMap = Object.values(NotificationType);

export enum NotificationAction {
  STAKEHOLDER_ADDED = 'stakeholder-added',
  INVITATION_ACCEPTED = 'invitation-accepted',
  STAKEHOLDER_TERMINATED = 'stakeholder-terminated',
  ACCESS_REVOKED = 'access-revoked',
}

export const notificationActionTitle: Record<NotificationAction, string> = {
  [NotificationAction.STAKEHOLDER_ADDED]: 'Stakeholder Added',
  [NotificationAction.INVITATION_ACCEPTED]: 'Invitation Accepted',
  [NotificationAction.STAKEHOLDER_TERMINATED]: 'Stakeholder Terminated',
  [NotificationAction.ACCESS_REVOKED]: 'Access Revoked',
};

export type NotificationStakeholdersRead = {
  id: string;
  notification: Notification;
  stakeholder: Stakeholder;
  notificationId: string;
  stakeholderId: string;
};

export type AppNotification = {
  id: string;
  company: Company;
  type: NotificationType;
  action?: NotificationAction;

  stakeholderName?: string;
  stakeholderType?: StakeholderType;
  stakeholderId?: string;

  adminName?: string;
  adminType?: StakeholderType;
  adminId?: string;

  resolved: boolean;
  createdAt: Date;
  companyId: string;
  is_read: boolean;
  exerciseRequest?: ExerciseRequest;
  notificationStakeholdersRead: NotificationStakeholdersRead[];
};

type NotificationBaseOptions = {
  isAdminYou: boolean;
  adminName: string;
  stakeholderName: string;
  stakeholderType: StakeholderType;
};

type ActiveNotificationBaseOptions = {
  action?: NotificationAction;
  terminationType: TerminationCause;
} & NotificationBaseOptions;

type RequestNotificationBaseOptions = {
  planName: string;
  hurdleValue: number;
  planGrunts: number;
  planType: SharePlanType;
  planAvailableCount: number;
  requestedSharesCount: number;
  milestoneName: string;
  milestoneDate: string;
} & NotificationBaseOptions;

const getAdminPart = ({ isAdminYou, adminName }: NotificationBaseOptions) =>
  isAdminYou ? `${adminName} (You)` : adminName;

const createStakeholderAddedNotification = (options: ActiveNotificationBaseOptions) =>
  `${options.stakeholderName} was added as a '${options.stakeholderType && stakeholderTypeTitle[options.stakeholderType]}' by ${getAdminPart(options)}`;

const createInvitationAcceptedNotification = (options: ActiveNotificationBaseOptions) =>
  `${options.stakeholderName} has accepted the stakeholder invitation sent by ${getAdminPart(options)}`;

const createStakeholderTerminatedNotification = (options: ActiveNotificationBaseOptions) =>
  `${options.stakeholderName} was Terminated ('${options.terminationType && TerminationCauseTitle[options.terminationType]}') as ${options.stakeholderType && stakeholderTypeTitle[options.stakeholderType]} by ${getAdminPart(options)}`;

const createAccessRevokedNotification = (options: ActiveNotificationBaseOptions) =>
  `${options.stakeholderName} has had access revoked by ${getAdminPart(options)}`;

const createExerciseRequestedNotification = (options: RequestNotificationBaseOptions) =>
  `${options.stakeholderName} has requested to exercise ${options.requestedSharesCount} ${options.planType && sharePlanTypeTitle[options.planType]}s out of ${options.planAvailableCount}`;

const createHurdleApprovalNotification = (options: RequestNotificationBaseOptions) =>
  `Hurdle Plan ${options.planName} has exceeded the $${options.hurdleValue?.toLocaleString('en-US')} hurdle value. ${options.planGrunts} grants are pending approval.`;

const createTargetBasedVestingApprovalNotification = (options: RequestNotificationBaseOptions) =>
  `Milestone ${options.milestoneName} under the Target-Based Plan ${options.planName} (${options.planType && sharePlanTypeTitle[options.planType]}) has a set target date of ${options.milestoneDate}. Would you like to Approve Target?`;

const NotificationFactory = {
  createActionNotification: (options: ActiveNotificationBaseOptions) => {
    switch (options.action) {
      case NotificationAction.STAKEHOLDER_ADDED:
        return createStakeholderAddedNotification(options);
      case NotificationAction.INVITATION_ACCEPTED:
        return createInvitationAcceptedNotification(options);
      case NotificationAction.STAKEHOLDER_TERMINATED:
        return createStakeholderTerminatedNotification(options);
      default:
        return createAccessRevokedNotification(options);
    }
  },

  createRequestNotification: (options: RequestNotificationBaseOptions) => {
    switch (options.planType) {
      case SharePlanType.COMMON_STOCK:
        return createExerciseRequestedNotification(options);
      case SharePlanType.GROWTH_HURDLE:
        return createHurdleApprovalNotification(options);
      default:
        return createTargetBasedVestingApprovalNotification(options);
    }
  },
};

export const activityNotificationBuilder = NotificationFactory.createActionNotification;
export const requestNotificationBuilder = NotificationFactory.createRequestNotification;
