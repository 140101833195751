import { addMonths, lastDayOfMonth } from 'date-fns';
import React, { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import {
  CloseIcon,
  InfoCircle,
  TargetBasedStandardIcon,
  TimeBasedDynamicIcon,
  TimeBasedStandardIcon,
} from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormDatePicker } from '../../../../components/DatePicker';
import { DropDown } from '../../../../components/Dropdown';
import { FormInput } from '../../../../components/Input';
import { FormRadio, FormRadioGroup } from '../../../../components/RadioGroup';
import { Switch } from '../../../../components/Switch';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import {
  SharePlanType,
  SharePlanVestingType,
  VestingOnOption,
} from '../../../../types/pool-plans.types';
import { DatePicker } from '../../PoolForm/DatePicker';
import { ChildrenFormProps } from '../type';
import { FormSchema, stepTwo as stepTwoFormSchema } from '../Validation';
import { VestingOnCombobox } from '../VestingOnCombobox';

const vestingMechanismTitleVariant: Record<
  SharePlanVestingType,
  { title: string; description: string }
> = {
  [SharePlanVestingType.TARGET_BASED]: { description: 'Standard', title: 'Target Based' },
  [SharePlanVestingType.TIME_BASED_DYNAMIC]: { description: 'Dynamic', title: 'Time Based' },
  [SharePlanVestingType.TIME_BASED_STANDARD]: { description: 'Standard', title: 'Time Based' },
};

const vestingMechanismVariant: {
  type: SharePlanVestingType;
  title: string;
  description: string;
}[] = Object.values(SharePlanVestingType).map((mechanism) => ({
  ...vestingMechanismTitleVariant[mechanism],
  type: mechanism,
}));

export type StepTwoProps = ChildrenFormProps;

const vestingsIconVariant: Record<SharePlanVestingType, (isSelected: boolean) => ReactNode> = {
  [SharePlanVestingType.TARGET_BASED]: (isSelected) => (
    <TargetBasedStandardIcon iconColor={isSelected ? '#2565c8' : '#344054'} />
  ),
  [SharePlanVestingType.TIME_BASED_DYNAMIC]: (isSelected) => (
    <TimeBasedDynamicIcon iconColor={isSelected ? '#2565c8' : '#344054'} />
  ),
  [SharePlanVestingType.TIME_BASED_STANDARD]: (isSelected) => (
    <TimeBasedStandardIcon iconColor={isSelected ? '#2565c8' : '#344054'} />
  ),
};
const defaultDate = new Date(+0);

export const StepTwo: FC<StepTwoProps> = ({
  handleCloseModal,
  control,
  setFormData,
  nextFormStep,
  prevFormStep,
  lockMode,
  clearErrors,
}) => {
  const { stepTwo, stepOne } = useWatch<FormSchema>({ control });
  const { success, error } = stepTwoFormSchema.safeParse(stepTwo);
  const { format } = useFormat();
  const isValid = success;
  const {
    fields: dynamicVestingsFields,
    append: dynamicVestingsAppend,
    remove: dynamicVestingsRemove,
  } = useFieldArray({
    control,
    name: 'stepTwo.vesting.timeBasedDynamic.dynamicVestings',
  });
  const {
    fields: targetVestingsFields,
    append: targetVestingsAppend,
    remove: targetVestingsRemove,
  } = useFieldArray({
    control,
    name: 'stepTwo.vesting.targetBasedStandard.targetVestings',
  });

  useEffect(() => {
    !stepTwo?.vesting?.enabled && setFormData('stepTwo.vesting', undefined);
    const selectedMechanism = stepTwo?.vesting?.vestingMechanism;
    clearErrors('stepTwo.vesting');
    if (selectedMechanism === SharePlanVestingType.TARGET_BASED) {
      setFormData('stepTwo.vesting.timeBasedDynamic', undefined);
      setFormData('stepTwo.vesting.timeBasedStandard', undefined);
    }
    if (selectedMechanism === SharePlanVestingType.TIME_BASED_DYNAMIC) {
      setFormData('stepTwo.vesting.targetBasedStandard', undefined);
      setFormData('stepTwo.vesting.timeBasedStandard', undefined);
    }
    if (selectedMechanism === SharePlanVestingType.TIME_BASED_STANDARD) {
      setFormData('stepTwo.vesting.timeBasedDynamic', undefined);
      setFormData('stepTwo.vesting.targetBasedStandard', undefined);
    }
  }, [clearErrors, setFormData, stepTwo?.vesting?.enabled, stepTwo?.vesting?.vestingMechanism]);

  const handleRemoveTargetVestings = useCallback(
    (index: number) => {
      targetVestingsRemove(index);
    },
    [targetVestingsRemove],
  );
  const handleAppendTargetVestings = useCallback(() => {
    targetVestingsAppend({
      name: '',
      targetDate: defaultDate,
      weight: 0,
    });
  }, [targetVestingsAppend]);

  const handleRemoveDynamicVestings = useCallback(
    (index: number) => {
      dynamicVestingsRemove(index);
    },
    [dynamicVestingsRemove],
  );
  const handleAppendDynamicVestings = useCallback(() => {
    dynamicVestingsAppend({
      vestingDuration: 0,
      vestingFrequency: 0,
      planAllocation: 0,
    });
  }, [dynamicVestingsAppend]);

  const handleSelectVestingMechanism = useCallback(
    (mechanism: SharePlanVestingType) => {
      setFormData('stepTwo.vesting', undefined);
      setFormData('stepTwo.vesting.enabled', true);
      setFormData('stepTwo.vesting.vestingMechanism', mechanism);
      if (mechanism === SharePlanVestingType.TIME_BASED_DYNAMIC) {
        setFormData('stepTwo.vesting.timeBasedDynamic.enabled', true);
        handleAppendDynamicVestings();
      }

      if (mechanism === SharePlanVestingType.TARGET_BASED) {
        setFormData('stepTwo.vesting.targetBasedStandard.enabled', true);
        handleAppendTargetVestings();
      }
    },
    [handleAppendDynamicVestings, handleAppendTargetVestings, setFormData],
  );

  const isInvalidTargetVestings = useMemo(
    () =>
      targetVestingsFields.reduce((sum, vesting) => sum + Number(vesting.weight ?? 0), 0) !== 100 &&
      targetVestingsFields.length > 1 &&
      !isValid,
    [isValid, targetVestingsFields],
  );

  const isInvalidDynamicVestings = useMemo(() => {
    if (stepTwo?.vesting?.timeBasedDynamic?.dynamicVestings) {
      return (
        stepTwo.vesting.timeBasedDynamic.dynamicVestings.reduce(
          (sum, vesting) => sum + Number(vesting.planAllocation ?? 0),
          0,
        ) !== 100 && dynamicVestingsFields.length > 1
      );
    }
    return false;
  }, [dynamicVestingsFields, stepTwo?.vesting?.timeBasedDynamic?.dynamicVestings]);

  const isInvalidStandardDurationVestings =
    stepTwo?.vesting?.timeBasedStandard?.vestingFrequency &&
    stepTwo?.vesting?.timeBasedStandard?.vestingDuration &&
    stepTwo?.vesting?.timeBasedStandard?.vestingDuration %
      stepTwo?.vesting?.timeBasedStandard?.vestingFrequency !==
      0;

  const isInvalidDynamicDurationVestings =
    stepTwo?.vesting?.timeBasedDynamic?.dynamicVestings?.every(
      ({ vestingDuration, vestingFrequency }) => {
        return (vestingDuration || 0) % (vestingFrequency || 0) !== 0;
      },
    ) &&
    dynamicVestingsFields.length > 1 &&
    !isValid;

  const handleVestingOnChange = useCallback(
    (state: VestingOnOption) => {
      if (stepTwo?.vesting?.vestingMechanism === SharePlanVestingType.TIME_BASED_DYNAMIC)
        return setFormData('stepTwo.vesting.timeBasedDynamic.vestingOn', state);
      if (stepTwo?.vesting?.vestingMechanism === SharePlanVestingType.TIME_BASED_STANDARD)
        return setFormData('stepTwo.vesting.timeBasedStandard.vestingOn', state);
    },
    [setFormData, stepTwo?.vesting?.vestingMechanism],
  );
  const selectedAccelerationDate =
    stepTwo?.vesting?.timeBasedDynamic?.accelerationDate ||
    stepTwo?.vesting?.timeBasedStandard?.accelerationDate ||
    new Date();

  const calculateMinExpiryDate = useCallback(() => {
    if (stepTwo?.vesting?.vestingMechanism === SharePlanVestingType.TIME_BASED_STANDARD) {
      const vestingOnOption = stepTwo?.vesting?.timeBasedStandard?.vestingOn;
      const startTime =
        vestingOnOption === VestingOnOption.LAST_DAY
          ? lastDayOfMonth(addMonths(new Date().setDate(1), 1))
          : addMonths(new Date().setDate(1), 1);
      const duration = stepTwo?.vesting?.timeBasedStandard?.vestingDuration;
      if (startTime && duration) {
        return addMonths(startTime, Number(duration));
      }
    }
    if (stepTwo?.vesting?.vestingMechanism === SharePlanVestingType.TIME_BASED_DYNAMIC) {
      const vestingOnOption = stepTwo?.vesting?.timeBasedDynamic?.vestingOn;
      const periods = stepTwo?.vesting?.timeBasedDynamic?.dynamicVestings;
      const startTime =
        vestingOnOption === VestingOnOption.LAST_DAY
          ? lastDayOfMonth(addMonths(new Date().setDate(1), 1))
          : addMonths(new Date().setDate(1), 1);
      if (periods && startTime) {
        return addMonths(
          startTime,
          periods.reduce((acc, el) => acc + Number(el?.vestingDuration || 0), 0),
        );
      }
    }
  }, [
    stepTwo?.vesting?.timeBasedDynamic?.dynamicVestings,
    stepTwo?.vesting?.timeBasedDynamic?.vestingOn,
    stepTwo?.vesting?.timeBasedStandard?.vestingDuration,
    stepTwo?.vesting?.timeBasedStandard?.vestingOn,
    stepTwo?.vesting?.vestingMechanism,
  ]);

  const resetExpiryDate = useCallback(() => {
    if (stepOne?.type !== SharePlanType.WARRANTS) return;
    setFormData('stepTwo.expiryDate', undefined);
    setFormData('stepTwo.expiryDate.enabled', true);
  }, [setFormData, stepOne?.type]);

  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div className="rounded-md bg-gray-50">
          <div className="flex items-center gap-2 px-4 py-3">
            <Switch
              checked={stepTwo?.vesting?.enabled}
              onChange={(state) => {
                if (state) return setFormData('stepTwo.vesting.enabled', state);
                setFormData('stepTwo.vesting', undefined);
              }}
            />
            <span className="text-sm font-[450] text-gray-700">Vesting</span>
          </div>
          {stepTwo?.vesting?.enabled && (
            <div className="flex flex-col items-center gap-4 divide-y-[1px] divide-gray-100 rounded-md bg-white px-4 py-3 shadow-sm">
              <FormRadioGroup
                className={'flex gap-4'}
                control={control}
                name="stepTwo.vesting.vestingMechanism"
                onSelect={handleSelectVestingMechanism}
                wrapperClassName="w-full"
              >
                {vestingMechanismVariant.map(({ description, title, type }) => (
                  <FormRadio
                    className="w-full rounded-md bg-gray-100 p-4 text-label-md text-gray-700 data-[checked]:bg-brand-50 data-[checked]:text-brand-700"
                    control={control}
                    name="stepTwo.vesting.vestingMechanism"
                    value={type}
                  >
                    <div className="flex flex-col gap-3">
                      {vestingsIconVariant[type](stepTwo?.vesting?.vestingMechanism === type)}

                      <div className="flex flex-col">
                        <span className="text-sm font-[450]">{title}</span>
                        <span className="text-label-sm font-[450]">{description}</span>
                      </div>
                    </div>
                  </FormRadio>
                ))}
              </FormRadioGroup>
              {stepTwo.vesting?.vestingMechanism === SharePlanVestingType.TIME_BASED_STANDARD && (
                <div className="flex w-full flex-col gap-3 px-4 py-3">
                  <div className="flex w-full items-center justify-between">
                    <span className="text-sm font-[450] text-gray-700">Vesting on</span>
                    <VestingOnCombobox
                      defaultValue={stepTwo.vesting.timeBasedStandard?.vestingOn}
                      onSelect={handleVestingOnChange}
                    />
                  </div>
                  <div className="flex w-full items-center justify-between">
                    <span className="text-sm font-[450] text-gray-700">Duration</span>
                    <FormInput
                      control={control}
                      errorMessage={!!isInvalidStandardDurationVestings && '1'}
                      isShownErrorMessage={false}
                      name="stepTwo.vesting.timeBasedStandard.vestingDuration"
                      numberOnly
                      placeholder="Months"
                      wrapperClassName="w-[200px]"
                    />
                  </div>
                  <div className="flex w-full items-center justify-between">
                    <span className="text-sm font-[450] text-gray-700">Vesting Frequency</span>
                    <FormInput
                      control={control}
                      isShownErrorMessage={false}
                      name="stepTwo.vesting.timeBasedStandard.vestingFrequency"
                      numberOnly
                      placeholder="Months"
                      wrapperClassName="w-[200px]"
                    />
                  </div>
                  <div className="flex w-full items-center justify-between">
                    <span className="text-sm font-[450] text-gray-700">Cliff</span>
                    <FormInput
                      control={control}
                      isShownErrorMessage={false}
                      name="stepTwo.vesting.timeBasedStandard.cliffPeriod"
                      numberOnly
                      placeholder="Months"
                      wrapperClassName="w-[200px]"
                    />
                  </div>
                  <div className="flex w-full items-center justify-between">
                    <span className="text-sm font-[450] text-gray-700">Acceleration Date</span>
                    <FormDatePicker
                      calendar={{ fromDate: new Date() }}
                      inputValue={
                        stepTwo?.vesting?.timeBasedStandard?.accelerationDate
                          ? format(
                              stepTwo.vesting?.timeBasedStandard?.accelerationDate || '',
                              'dd/MM/yyyy',
                            )
                          : ''
                      }
                      onSelect={(date) => {
                        resetExpiryDate();
                        setFormData('stepTwo.vesting.timeBasedStandard.accelerationDate', date);
                      }}
                      placeholder="Date"
                      value={stepTwo?.vesting?.timeBasedStandard?.accelerationDate}
                      wrapperClassName="w-[200px]"
                    />
                  </div>
                  {isInvalidStandardDurationVestings && (
                    <span className="flex gap-1 text-xs font-[450] text-fireside-600">
                      <InfoCircle />
                      The duration should be a multiple of your frequency
                    </span>
                  )}
                </div>
              )}
              {stepTwo.vesting?.vestingMechanism === SharePlanVestingType.TIME_BASED_DYNAMIC && (
                <div className="flex w-full flex-col items-center gap-3 divide-y-[1px] divide-gray-100 pt-3">
                  <div className="flex w-full items-center justify-between px-4">
                    <span className="text-sm font-[450] text-gray-700">Vesting on</span>
                    <VestingOnCombobox
                      defaultValue={stepTwo.vesting.timeBasedDynamic?.vestingOn}
                      onSelect={handleVestingOnChange}
                    />
                  </div>
                  <div className="flex w-full flex-col gap-6 px-4 py-3">
                    <div className="flex w-full items-center justify-between pr-4">
                      <span
                        className={twMerge(
                          'text-sm font-[450]',
                          isInvalidDynamicDurationVestings ? 'text-fireside-600' : 'text-gray-700',
                        )}
                      >
                        Duration
                      </span>
                      <span className="text-sm font-[450] text-gray-700">Vesting Freq.</span>
                      <span
                        className={twMerge(
                          'text-sm font-[450]',
                          isInvalidDynamicVestings ? 'text-fireside-600' : 'text-gray-700',
                        )}
                      >
                        Plan Allocation
                      </span>
                    </div>
                    <div className="flex w-full flex-col items-start gap-3">
                      {dynamicVestingsFields.map((field, index) => (
                        <div
                          className="flex w-full items-start justify-between gap-3"
                          key={field.id}
                        >
                          <FormInput
                            control={control}
                            errorMessage={isInvalidDynamicDurationVestings && '1'}
                            isShownErrorMessage={false}
                            name={`stepTwo.vesting.timeBasedDynamic.dynamicVestings.${index}.vestingDuration`}
                            numberOnly
                            placeholder="Months"
                            wrapperClassName="w-[200px]"
                          />
                          <FormInput
                            control={control}
                            isShownErrorMessage={false}
                            name={`stepTwo.vesting.timeBasedDynamic.dynamicVestings.${index}.vestingFrequency`}
                            numberOnly
                            placeholder="Months"
                            wrapperClassName="w-[200px]"
                          />

                          <FormInput
                            control={control}
                            errorMessage={
                              isInvalidDynamicVestings &&
                              'Plan allocation percentage does not reach 100%'
                            }
                            isShownErrorMessage={false}
                            name={`stepTwo.vesting.timeBasedDynamic.dynamicVestings.${index}.planAllocation`}
                            numberOnly
                            placeholder="%"
                            wrapperClassName="w-[200px]"
                          />
                          <Button
                            className="w-fit items-center justify-center hover:bg-gray-50"
                            onClick={() => handleRemoveDynamicVestings(index)}
                            styleType="NONE"
                          >
                            <CloseIcon className="size-3" iconColor="#344054" />
                          </Button>
                        </div>
                      ))}
                      {error?.errors &&
                        (stepTwo?.vesting?.timeBasedDynamic?.dynamicVestings?.[0]
                          ?.vestingDuration || 0) > 0 && (
                          <span className="flex gap-1 text-xs font-[450] text-fireside-600">
                            <InfoCircle />
                            {error?.errors[0].message}
                          </span>
                        )}
                    </div>
                    <Button
                      className="w-fit text-sm font-[450] text-brand-700 underline disabled:border-transparent disabled:bg-transparent"
                      disabled={dynamicVestingsFields.length >= 10}
                      onClick={handleAppendDynamicVestings}
                      styleType="NONE"
                    >
                      + Add Period
                    </Button>
                    <div className="flex w-full items-center justify-between border-t border-gray-100 pt-5">
                      <span className="text-sm font-[450] text-gray-700">Acceleration Date</span>
                      <FormDatePicker
                        calendar={{ fromDate: new Date() }}
                        inputValue={
                          stepTwo?.vesting?.timeBasedDynamic?.accelerationDate
                            ? format(
                                stepTwo.vesting.timeBasedDynamic.accelerationDate,
                                'dd/MM/yyyy',
                              )
                            : ''
                        }
                        onSelect={(date) => {
                          resetExpiryDate();
                          setFormData('stepTwo.vesting.timeBasedDynamic.accelerationDate', date);
                        }}
                        placeholder="Date"
                        value={stepTwo?.vesting?.timeBasedDynamic?.accelerationDate}
                        wrapperClassName="w-[200px]"
                      />
                    </div>
                  </div>
                </div>
              )}
              {stepTwo.vesting?.vestingMechanism === SharePlanVestingType.TARGET_BASED && (
                <div className="flex w-full flex-col gap-6 px-4 py-3">
                  <div className="flex w-full items-center justify-between pr-8">
                    <span className="text-sm font-[450] text-gray-700">Milestone Name</span>
                    <span
                      className={twMerge(
                        'text-sm font-[450]',
                        isInvalidTargetVestings ? 'text-fireside-600' : 'text-gray-700',
                      )}
                    >
                      Weight
                    </span>
                    <span className="text-sm font-[450] text-gray-700">Target Date</span>
                  </div>
                  <div className="flex w-full flex-col gap-4">
                    {targetVestingsFields.map((field, index) => (
                      <div className="flex w-full items-start justify-between gap-3" key={field.id}>
                        <FormInput
                          control={control}
                          isShownErrorMessage={false}
                          name={`stepTwo.vesting.targetBasedStandard.targetVestings.${index}.name`}
                          placeholder={<AppFormattedMessage id={StringKey.NAME} />}
                          wrapperClassName="w-full"
                        />
                        <FormInput
                          control={control}
                          errorMessage={
                            isInvalidTargetVestings && 'Weight allocation does not reach 100%'
                          }
                          isShownErrorMessage={false}
                          name={`stepTwo.vesting.targetBasedStandard.targetVestings.${index}.weight`}
                          numberOnly
                          placeholder="%"
                          wrapperClassName="w-[71px] shrink-0"
                        />
                        <DatePicker
                          onSelect={(date) =>
                            setFormData(
                              `stepTwo.vesting.targetBasedStandard.targetVestings.${index}.targetDate`,
                              date,
                            )
                          }
                          wrapperClassName="w-full"
                        >
                          <FormInput
                            control={control}
                            customValue={(value) => {
                              return value && new Date(value).getTime() !== defaultDate.getTime()
                                ? format(value, 'dd/MM/yyyy')
                                : '';
                            }}
                            name={`stepTwo.vesting.targetBasedStandard.targetVestings.${index}.targetDate`}
                            numberOnly
                            placeholder="Date"
                            wrapperClassName="w-full"
                          />
                        </DatePicker>
                        <Button
                          className="w-fit items-center justify-center hover:bg-gray-50 disabled:border-transparent disabled:bg-transparent"
                          disabled={targetVestingsFields.length >= 10}
                          onClick={() => handleRemoveTargetVestings(index)}
                          styleType="NONE"
                        >
                          <CloseIcon className="size-3" iconColor="#344054" />
                        </Button>
                      </div>
                    ))}
                    <Button
                      className="w-fit text-sm font-[450] text-brand-700 underline"
                      onClick={handleAppendTargetVestings}
                      styleType="NONE"
                    >
                      + Add Milestone
                    </Button>
                  </div>
                  {error?.errors &&
                    (stepTwo?.vesting?.targetBasedStandard?.targetVestings?.[0]?.weight || 0) > 0 &&
                    !error?.errors[0].path?.includes('targetDate') && (
                      <span className="flex gap-1 text-xs font-[450] text-fireside-600">
                        <InfoCircle />
                        {error?.errors[0].message}
                      </span>
                    )}
                </div>
              )}
            </div>
          )}
        </div>
        {stepOne?.type === SharePlanType.WARRANTS && (
          <div className="rounded-md bg-gray-50">
            <div className="flex items-center gap-2 px-4 py-3">
              <Switch
                checked={stepTwo?.expiryDate?.enabled}
                onChange={(state) => {
                  if (state) return setFormData('stepTwo.expiryDate.enabled', true);
                  setFormData('stepTwo.expiryDate', undefined);
                }}
              />
              <span className="text-sm font-[450] text-gray-700">Expiry Date</span>
            </div>
            {stepTwo?.expiryDate?.enabled && (
              <div className="flex items-center justify-between gap-3 rounded-md bg-white px-4 py-3 shadow-sm">
                <span className="text-nowrap text-sm font-[450] text-gray-700">Expiry Date</span>
                <FormDatePicker
                  calendar={{ fromDate: calculateMinExpiryDate() || selectedAccelerationDate }}
                  inputValue={
                    stepTwo?.expiryDate.date ? format(stepTwo?.expiryDate.date, 'dd/MM/yyyy') : ''
                  }
                  onSelect={(date) => {
                    setFormData('stepTwo.expiryDate.date', date);
                  }}
                  placeholder="Date"
                  value={stepTwo?.expiryDate?.date}
                  wrapperClassName="w-[192px]"
                />
              </div>
            )}
          </div>
        )}
        <div className="w-full divide-y-[1px] divide-gray-100 rounded-lg bg-gray-50">
          {stepOne?.type === SharePlanType.WARRANTS && (
            <DropDown
              answer="The vesting date is when an individual earns the right to receive full benefits or ownership of shares or assets, usually after meeting certain conditions. The expiry date is the deadline by which the individual must exercise their rights or options before they become invalid or worthless."
              className="rounded-lg bg-gray-50"
              question="What is the difference between Vesting & Expiry date?"
            />
          )}
          {stepTwo?.vesting?.vestingMechanism === SharePlanVestingType.TIME_BASED_STANDARD && (
            <>
              <DropDown
                answer="The “Vesting on” value refers to the specific date or milestone when an individual gains the right to receive full benefits or ownership of shares or assets, typically in the context of employee stock options or retirement plans. It marks the point at which the vesting conditions are satisfied, and the individual can fully access their entitlements."
                className="rounded-lg bg-gray-50"
                question="What is “Vesting on” value?"
              />
              <DropDown
                answer="To set the duration, specify the desired length of time in the appropriate field or parameter, usually in units such as seconds, minutes, or hours. Ensure that the format and value adhere to the guidelines or requirements of the specific application or context you're working in."
                className="rounded-lg bg-gray-50"
                question="How do I set the duration?"
              />
              <DropDown
                answer="The conversion ratio of the Grant (established at Plan level) indicates the conversion of the grant into a share class. In other words, Alice gets 1000 Grants that can have a conversion ratio on a Stock Options Plan of 2x. In such a way that when she exercises, she will get 2000 shares from the selected share class. This means that a Grant does not always have a 1-1 value with the selected share class underlying the pool"
                className="rounded-lg bg-gray-50"
                question="What is conversion ratio?"
              />
              <DropDown
                answer="Frequency in vesting refers to how often vesting events occur, determining the intervals at which an individual earns the right to receive a portion of their shares or benefits. Common vesting frequencies include monthly, quarterly, or annually."
                className="rounded-lg bg-gray-50"
                question="What is frequency in vesting?"
              />
              <DropDown
                answer="The cliff value in vesting refers to the initial period during which no vesting occurs, after which a substantial portion of shares or benefits vests all at once. It typically serves as a probationary period to ensure commitment before granting significant ownership or benefits."
                className="rounded-lg bg-gray-50"
                question="What is cliff value in vesting?"
              />
            </>
          )}

          {stepTwo?.vesting?.vestingMechanism === SharePlanVestingType.TARGET_BASED && (
            <>
              <DropDown
                answer="As a finance professional, setting the milestone value involves determining the key performance indicators (KPIs) and specific goals that align with the overall strategic objectives of the project or organization."
                className="rounded-lg bg-gray-50"
                question="How can I set the Milestone value?"
              />
              <DropDown
                answer="Milestone weightage refers to the relative importance or significance of each milestone within the overall project or performance evaluation framework. It is a way to prioritize certain milestones over others based on their impact on the final outcome"
                className="rounded-lg bg-gray-50"
                question="What is milestone weightage?"
              />
            </>
          )}
        </div>
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          {!lockMode && (
            <Button
              className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
              onClick={prevFormStep}
              styleType="NONE"
              type="button"
            >
              <AppFormattedMessage id={StringKey.BACK} />
            </Button>
          )}
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            disabled={!isValid}
            onClick={nextFormStep}
            type="button"
          >
            {lockMode ? (
              <AppFormattedMessage id={StringKey.UPDATE} />
            ) : stepTwo?.vesting?.enabled || stepTwo?.expiryDate?.enabled ? (
              <AppFormattedMessage id={StringKey.NEXT} />
            ) : (
              <AppFormattedMessage id={StringKey.SKIP} />
            )}
          </Button>
        </div>
      </div>
    </>
  );
};
