import React, { FC, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import { Sheet, SheetContent } from '../../../../components/Sheet';
import { Stakeholder as StakeholderHook, useReactForm } from '../../../../hooks';
import { TerminateForm } from './TerminateForm';
import { TerminateStakeholderModalProps } from './TerminateStakeholderModal';
import { FormSchema, formSchema } from './validation';

export const EditStakeholderTerminationModal: FC<TerminateStakeholderModalProps> = ({
  isOpen,
  companyId,
  handleClose,
  invalidateQuery,
  stakeholders,
  setSelectedStakeholderIds,
}) => {
  const { control, reset, handleSubmit, setValue, getFieldState, resetField } = useReactForm({
    schema: formSchema,
  });

  const { updateTermination } = StakeholderHook.useUpdateTermination();

  useEffect(() => {
    if (!stakeholders) return;

    reset({
      terminateForm: {
        stakeholderItems: stakeholders.map(({ id, terminationCause, terminationDate }) => ({
          id,
          terminationCause,
          terminationDate: terminationDate ? new Date(terminationDate) : undefined,
        })),
      },
    });
  }, [reset, stakeholders]);

  const handleCloseModal = useCallback(() => {
    reset();
    handleClose();
    setSelectedStakeholderIds([]);
  }, [reset, handleClose, setSelectedStakeholderIds]);

  const submitHandler = useCallback(
    (data: FormSchema) => {
      const { terminateForm } = data;
      const terminateData = terminateForm.stakeholderItems.map(
        ({ id, terminationCause, terminationDate }) => ({
          id,
          terminationDate,
          terminationCause,
          lastVestingDate: terminationDate,
        }),
      );
      updateTermination(
        {
          companyId,
          data: terminateData,
        },
        {
          onSuccess: () => {
            toast.success(
              `Termination policy updated for ${terminateForm.stakeholderItems.length} stakeholder${terminateForm.stakeholderItems.length > 1 ? 's' : ''}`,
            );
            invalidateQuery();
            handleCloseModal();
          },
        },
      );
    },
    [companyId, handleCloseModal, invalidateQuery, updateTermination],
  );

  const handleTerminate = useCallback(() => {
    handleSubmit(submitHandler)();
  }, [handleSubmit, submitHandler]);

  return (
    <Sheet open={isOpen}>
      <SheetContent
        className="h-fit max-h-[800px] w-[90%] max-w-[1326px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="CENTER"
      >
        <div className="flex h-fit w-full flex-col gap-10 rounded-2xl bg-white p-8">
          <div className="flex h-fit w-full items-end justify-between">
            <div className="flex flex-col gap-2">
              <span className="text-xl font-semibold text-gray-700">Edit Termination</span>
              <span className="text-sm font-[450] text-gray-700">
                Please fill in the New Type, Termination Type, and Termination Date for each
                stakeholder.
              </span>
            </div>
            <span className="cursor-pointer text-sm font-[500] text-brand-700 underline">
              Learn more about stakeholder termination
            </span>
          </div>
          <div className="flex h-full max-h-[600px] flex-col overflow-y-auto">
            <TerminateForm
              companyId={companyId}
              control={control}
              editMode
              filedState={getFieldState}
              handleCloseModal={handleCloseModal}
              handleTerminate={handleTerminate}
              resetField={resetField}
              setFormData={setValue}
              stakeholders={stakeholders}
            />
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
