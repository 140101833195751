import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Company } from '../../types/companyTypes';
import { useAppQuery } from '../useAppQuery';

export const useSelectedCompanyId = () => {
  const { data: { id: companyId = '' } = {}, isLoading } = useAppQuery({
    queryKey: [QueryKey.GET_SELECTED_COMPANY_ID, QueryKey.GET_SELECTED_COMPANY],
    queryFn: () =>
      ApiService.get<Company>({ endpoint: BackendRoute.COMPANIES, routePath: ['selected'] }),
  });

  return {
    companyId,
    isLoading,
  };
};
