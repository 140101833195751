import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { HttpException } from '../../exceptions';
import { ApiService } from '../../services';
import {
  Stakeholder,
  StakeholderFilter,
  StakeholderOrder,
  StakeholderSortBy,
  StakeholderType,
} from '../../types/stakeholderTypes';
import { useAppQuery } from '../useAppQuery';

type GetCompaniesStakeholderResponse = {
  stakeholders: Stakeholder[];
  totalPages: number;
  totalStakeholders: number;
};
export type FilterVariant = 'all' | StakeholderFilter;

export const useStakeholders = ({
  companyId,
  numberOfFetchedItems = 7,
  filter,
  orderBy,
  currentPage,
  search,
  searchType,
  sortBy,
  isTerminated,
}: {
  companyId: string;
  numberOfFetchedItems?: number;
  currentPage: number;
  search: string;
  searchType: StakeholderType[];
  filter: FilterVariant;
  orderBy: StakeholderOrder | null;
  sortBy: StakeholderSortBy | null;
  isTerminated: boolean;
}) => {
  const queryClient = useQueryClient();
  const skip = (currentPage - 1 <= 0 ? 0 : currentPage - 1) * numberOfFetchedItems;
  const {
    data: stakeholders,
    isLoading,
    refetch,
  } = useAppQuery<GetCompaniesStakeholderResponse, HttpException>({
    queryKey: [
      QueryKey.GET_STAKEHOLDERS,
      companyId,
      {
        filter,
        page: currentPage,
        sortBy,
        orderBy,
        skip,
        isTerminated,
      },
    ],
    queryFn: () =>
      ApiService.get(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'stakeholders'] },
        {
          queryParams: {
            take: numberOfFetchedItems,
            skip,
            search,
            searchType: searchType.length > 0 ? searchType.join(',') : '',
            filter: filter === 'all' ? '' : filter,
            ...(orderBy && sortBy ? { sortBy, sortOrder: orderBy } : {}),
            isTerminated,
          },
        },
      ),
    enabled: !!companyId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_STAKEHOLDERS] });
  }, [queryClient]);

  return {
    invalidateQuery,
    ...stakeholders,
    totalStakeholders: stakeholders?.totalStakeholders || 0,
    isLoading,
    refetch,
  };
};
