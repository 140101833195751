import { useQueryClient } from '@tanstack/react-query';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { ExerciseRequestDto } from '../../types/exerciseRequest.types';
import { useAppMutation } from '../useAppMutation';

export const useExerciseRequest = ({ companyId }: { companyId: string }) => {
  const { invalidateQueries } = useQueryClient();
  const { mutate: exerciseRequest } = useAppMutation(
    [QueryKey.NOTIFICATION_EXERCISE_REQUEST, companyId],
    {
      mutationFn: ({
        exerciseRequestId,
        ...dto
      }: { exerciseRequestId: string } & ExerciseRequestDto) =>
        ApiService.post(
          {
            endpoint: BackendRoute.COMPANIES,
            routePath: [companyId, 'exercise-request', exerciseRequestId],
          },
          { body: dto },
        ),
      onSuccess: () => {
        invalidateQueries({ queryKey: [QueryKey.NOTIFICATION_EXERCISE_REQUEST] });
        invalidateQueries({ queryKey: [QueryKey.GET_NOTIFICATIONS] });
      },
    },
  );

  return {
    exerciseRequest,
  };
};
