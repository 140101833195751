import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { GrantEventItem } from '../../types/events.types';
import { useAppQuery } from '../useAppQuery';
export const useSharePlanGrantItems = ({
  companyId,
  sharePlanId,
}: {
  companyId: string;
  sharePlanId: string;
}) => {
  const queryClient = useQueryClient();
  const { data: approveHurdleGrants = [], isLoading } = useAppQuery<GrantEventItem[]>({
    queryKey: [QueryKey.GET_SHARE_PLAN_GRANT_ITEMS, companyId, sharePlanId],
    queryFn: () =>
      ApiService.get({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'share-plans', sharePlanId, 'grant-items'],
      }),
    enabled: !!companyId && !!sharePlanId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SHARE_PLAN_GRANT_ITEMS] });
  }, [queryClient]);

  return {
    invalidateQuery,
    approveHurdleGrants,
    isLoading,
  };
};
