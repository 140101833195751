import { subDays } from 'date-fns';
import React, { FC } from 'react';
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { useFormat } from '../../../../hooks';
import { GraphVariant } from '../../variables';

export const EmptyGraph: FC<{ variant: GraphVariant }> = ({ variant }) => {
  const { format } = useFormat();
  const today = new Date();
  const yesterday = subDays(today, 1);

  const data = [
    { date: '16/11/2023', totalShares: 0, percentage: 0 },
    { date: '16/05/2024', totalShares: 4, percentage: 20 },
    { date: format(yesterday, 'dd/MM/yyyy'), totalShares: 3, percentage: 15 },
    { date: 'Fully diluted', totalShares: 8, percentage: 40 },
  ];

  const yAxisDomain = variant === GraphVariant.OWNERSHIP_PERCENT ? [0, 100] : [0, 20];

  return (
    <ResponsiveContainer height="100%" width="100%">
      <AreaChart
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 8,
          bottom: 8,
        }}
      >
        <XAxis
          dataKey="date"
          stroke="#475467"
          tick={{ fontSize: 10, fontWeight: 500 }}
          tickLine={false}
          tickMargin={16}
        />
        <YAxis
          domain={yAxisDomain}
          stroke="#475467"
          tick={{ fontSize: 10, fontWeight: 500 }}
          tickFormatter={(value) =>
            variant === GraphVariant.OWNERSHIP_PERCENT ? `${value}%` : value
          }
          tickLine={false}
          tickMargin={16}
        />
        <Area
          dataKey={variant === GraphVariant.OWNERSHIP_PERCENT ? 'percentage' : 'totalShares'}
          fill="#F9FAFB"
          stroke="#EAECF0"
          strokeDasharray="5 5"
          type="monotone"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
