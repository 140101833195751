import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { StakeholderOwnershipSummary } from '../../types/stakeholderTypes';
import { toNumber } from '../../utils/toNumber';

type SummaryItemProps = {
  title: string;
  value: string;
  className?: string;
};

const SummaryItem: FC<SummaryItemProps> = ({ title, value, className }) => (
  <div
    className={twMerge(
      'flex flex-grow basis-[40%] flex-col gap-2 rounded-lg bg-gray-50 px-2 py-3',
      className,
    )}
  >
    <span className="text-nowrap text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-500">
      {title}
    </span>
    <span className="text-base font-semibold text-gray-600">{value}</span>
  </div>
);

export type OwnershipSummaryProps = StakeholderOwnershipSummary;

export const OwnershipSummary: FC<OwnershipSummaryProps> = ({
  companyOwned,
  investment,
  sharePrice,
  numberOfShares,
  roi,
  valueOfShares,
  votingPercentage,
}) => (
  <div className="flex w-[30%] min-w-[350px] flex-grow flex-col gap-3 rounded-lg bg-gray-50 p-1 pt-3">
    <span className="pl-4 text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
      Ownership Summary
    </span>
    <div className="flex w-full flex-wrap gap-3 rounded-md bg-white p-3 shadow-sm">
      <SummaryItem title="Number of Shares" value={numberOfShares.toLocaleString('en-US')} />
      <SummaryItem title="% of company owned" value={`${companyOwned.toLocaleString('en-US')}%`} />
      <SummaryItem
        title="Voting (%)"
        value={`${(votingPercentage * 100).toLocaleString('en-US')}%`}
      />
      <SummaryItem title="Share Price" value={`$${sharePrice.toLocaleString('en-US')}`} />
      <SummaryItem title="Value of Shares" value={`$${valueOfShares.toLocaleString('en-US')}`} />
      <SummaryItem title="Investment ($)" value={`$${investment.toLocaleString('en-US')}`} />
      <SummaryItem className="basis-[50%]" title="ROI on INvestment" value={`${toNumber(roi)} x`} />
    </div>
  </div>
);
