import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  CheckCircleIcon,
  ChevronLeftIcon,
  CloseIcon,
  EventIssueShares,
  ExcerciseIcon,
  PieIcon,
  SarIcon,
} from '../../../assets/icons';
import Button from '../../../components/Button';
import { ExerciseType } from '../../../types/pool-plans.types';

export type ExerciseModalProps = {
  onClose: () => void;
  onOpenExerciseStockModal: (type: ExerciseType) => void;
};

type ExerciseModalItemPRops = {
  title: string;
  description: string | ReactNode;
  children: ReactNode;
  onClick: () => void;
  className?: string;
};

export const ExerciseModalItem: FC<ExerciseModalItemPRops> = ({
  description,
  title,
  children,
  onClick,
  className,
}) => (
  <div
    className={twMerge(
      className,
      'flex w-[419px] cursor-pointer items-center justify-between py-2',
    )}
    onClick={onClick}
  >
    <div className="flex items-center gap-4">
      <div className="rounded-lg border-[1px] border-[#F2F2F2] bg-white p-[9px]">{children}</div>
      <div className="flex flex-col gap-1">
        <span className="text-sm font-[550] text-[#172335]">{title}</span>
        <span className="text-label-sm text-gray-500">{description}</span>
      </div>
    </div>
    <Button className="size-4 rounded hover:bg-gray-50" styleType="NONE">
      <ChevronLeftIcon className="size-4 rotate-180" />
    </Button>
  </div>
);

export const ExerciseModal: FC<ExerciseModalProps> = ({ onClose, onOpenExerciseStockModal }) => {
  return (
    <div className="flex max-w-[483px] flex-col gap-4 rounded-xl bg-white px-4 pb-4 shadow-2xl">
      <div className="flex w-full justify-between pb-[10px] pt-[14px]">
        <span className="text-xl font-semibold text-gray-700">Exercise</span>
        <Button
          className="flex size-7 items-center justify-center rounded-full"
          onClick={onClose}
          style={{ border: '0.78px solid #F2F2F2', boxShadow: ' 0px 0px 18.67px 0px #00000014' }}
          styleType="NONE"
        >
          <CloseIcon />
        </Button>
      </div>
      <div className="flex w-full flex-col divide-y divide-gray-100 rounded-xl bg-gray-50 p-4">
        <ExerciseModalItem
          description="Execute your stock options with ease"
          onClick={() => {
            onClose();
            onOpenExerciseStockModal(ExerciseType.STOCK_OPTIONS);
          }}
          title="Exercise Stock Options"
        >
          <EventIssueShares />
        </ExerciseModalItem>
        <ExerciseModalItem
          description="Execute your RSA with ease"
          onClick={() => {
            onClose();
            onOpenExerciseStockModal(ExerciseType.RSA);
          }}
          title="Exercise RSA"
        >
          <EventIssueShares className="rotate-180" />
        </ExerciseModalItem>
        <ExerciseModalItem
          description="Execute your stock options with ease"
          onClick={() => {
            onClose();
            onOpenExerciseStockModal(ExerciseType.BSPCE);
          }}
          title="Exercise BSPCE"
        >
          <EventIssueShares className="-scale-x-100" />
        </ExerciseModalItem>
        <ExerciseModalItem
          description="Manage or execute your warrants with ease"
          onClick={() => {
            onClose();
            onOpenExerciseStockModal(ExerciseType.WARRANTS);
          }}
          title="Exercise / Expire Warrants"
        >
          <ExcerciseIcon />
        </ExerciseModalItem>
        <ExerciseModalItem
          description="Execute your SAR’s with ease"
          onClick={() => {
            onClose();
            onOpenExerciseStockModal(ExerciseType.SAR);
          }}
          title="Exercise SARs"
        >
          <SarIcon />
        </ExerciseModalItem>
        <ExerciseModalItem
          description="Payout your phantom shares with ease"
          onClick={() => {
            onClose();
            onOpenExerciseStockModal(ExerciseType.PHANTOM);
          }}
          title="Payout Phantom Shares"
        >
          <PieIcon />
        </ExerciseModalItem>
        <ExerciseModalItem
          description="Approve your hurdle shares with ease"
          onClick={() => {
            onClose();
            onOpenExerciseStockModal(ExerciseType.HURDLE);
          }}
          title="Approve Hurdle Shares"
        >
          <CheckCircleIcon />
        </ExerciseModalItem>
      </div>
    </div>
  );
};
