import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';
import React, { FC } from 'react';
import { Control } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { ChevronDownIcon } from '../../../../assets/icons';
import Button from '../../../../components/Button';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../../components/Combobox';
import Loader from '../../../../components/Loader';
import { useAllSharePlans } from '../../../../hooks/sharePlan/useAllSharePlans';
import { SharePlanUnionType } from '../../../../types/pool-plans.types';
import { FormSchema } from '../validation';

export type ShareClassItemsListProps = {
  data: SharePlanUnionType[];
  control: Control<FormSchema>;
  index: number;
  hasNextPage: boolean;
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<
    InfiniteQueryObserverResult<
      InfiniteData<
        {
          sharePlans: SharePlanUnionType[];
          totalPages: number;
        },
        unknown
      >,
      Error
    >
  >;
};

const ShareClassItemsList: FC<ShareClassItemsListProps> = ({
  data,
  control,
  index,
  hasNextPage,
  fetchNextPage,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex w-full flex-col gap-2">
      <InfiniteScroll
        className="flex w-full flex-col gap-2"
        dataLength={10}
        hasMore={hasNextPage}
        loader={<Loader />}
        next={fetchNextPage}
        scrollableTarget="scrollbar-target"
      >
        {data.length > 0 ? (
          <>
            {data.map(({ name, id, pool }, i) => (
              <FormComboboxOption
                className="w-full"
                control={control}
                key={`${name}_${i}`}
                name={`stepOne.eventDetails.grant.grantItems.${index}.sharePlan`}
                value={{ id, name, pool }}
              >
                <span className="truncate text-sm text-gray-700">{name}</span>
              </FormComboboxOption>
            ))}
          </>
        ) : (
          <span className="py-[6px] text-sm font-normal text-gray-700">No Plan found</span>
        )}
      </InfiniteScroll>

      <Button
        className="w-full justify-start rounded bg-brand-25 px-2 py-[6px] text-start text-sm font-[450] text-brand-700"
        onClick={() => navigate('/pool-plans?openAddPlanModal=true')}
        styleType="NONE"
      >
        + Add a new Plan
      </Button>
    </div>
  );
};

export type SharePlanComboboxProps = {
  control: Control<FormSchema>;
  companyId: string;
  index: number;
  onSelect?: (id: string) => void;
};

export const SharePlanCombobox: FC<SharePlanComboboxProps> = ({
  control,
  companyId,
  index,
  onSelect,
}) => {
  const { sharePlans, fetchNextPage, hasNextPage } = useAllSharePlans({
    companyId,
  });

  return (
    <>
      <FormCombobox
        className="relative w-[224px]"
        control={control}
        name={`stepOne.eventDetails.grant.grantItems.${index}.sharePlan`}
        onSelect={({ id }) => onSelect?.(id)}
      >
        <div className="relative">
          <FormComboboxInput
            className={'bg-gray-900'}
            control={control}
            customValue={(value) => value?.name}
            icon={<ChevronDownIcon className="mt-3" />}
            name={`stepOne.eventDetails.grant.grantItems.${index}.sharePlan`}
            placeholder="Value"
            readOnly
          />
          <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
        </div>
        <ComboboxOptions className="w-full overflow-x-hidden">
          <ShareClassItemsList
            control={control}
            data={sharePlans || []}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            index={index}
          />
        </ComboboxOptions>
      </FormCombobox>
    </>
  );
};
