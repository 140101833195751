import React, { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../assets/icons';
import Loader from '../../components/Loader';
import { Company, SnapshotHooks, useScreenSize, ValuationHooks } from '../../hooks';
import { PageRoute } from '../../types/pageTypes';
import { CapTableGraphWrapper } from '../CapTable/Graphs';
import { OverAllStatus } from '../CapTable/OverAllStatus';
import { GraphVariant } from '../CapTable/variables';
import { EmptyGraph, EventGraph } from '../Events/Desktop/GraphsDesk';
import { GraphVariant as EventGraphVariant } from '../Events/variables';
import { PoolGraph } from './PoolGraph';
import { PoolGraphVariant } from './variables';

const Dashboard: FC = () => {
  const [selectedGraphVariant, setGraphVariant] = useState(GraphVariant.OWNERSHIP);
  const { selectedCompanyId } = Company.useSelected();
  const { valuation } = ValuationHooks.useCurrent({ companyId: selectedCompanyId });
  const { snapshots, isLoading } = SnapshotHooks.useSnapshots({ companyId: selectedCompanyId });
  const { width } = useScreenSize();
  const navigate = useNavigate();

  const valuationId = valuation?.id || '';

  const snapshot = useMemo(
    () => snapshots.find(({ valuation: { id } }) => id === valuationId),
    [snapshots, valuationId],
  );

  const toggleGraphVariant = () => {
    setGraphVariant((prevVariant) =>
      prevVariant === GraphVariant.OWNERSHIP ? GraphVariant.VOTING_RIGHTS : GraphVariant.OWNERSHIP,
    );
  };

  const top5Stakeholders = useMemo(() => {
    if (!snapshot?.stats?.stakeholders) {
      return [];
    }
    const totalIssuedPercentage = snapshot.stats.stakeholders.reduce(
      (total, stakeholder) => total + stakeholder.issuedPercentage,
      0,
    );

    const totalDilutedPercentage = snapshot.stats.stakeholders.reduce(
      (total, stakeholder) => total + stakeholder.dilutedPercentage,
      0,
    );

    return snapshot.stats.stakeholders
      .sort((a, b) => b.issuedPercentage - a.issuedPercentage)
      .slice(0, 5)
      .map(({ name, issuedPercentage, dilutedPercentage }) => ({
        name,
        issuedPercentage: ((issuedPercentage * 100) / (totalIssuedPercentage || 1)).toFixed(2),
        dilutedPercentage: ((dilutedPercentage * 100) / (totalDilutedPercentage || 1)).toFixed(2),
      }));
  }, [snapshot?.stats?.stakeholders]);

  const isDesktop = width >= 1344;
  const isTablet = width >= 1024 && width < 1344;

  if (isLoading) return <Loader />;

  return (
    <div className="flex h-fit w-full flex-col gap-4 overflow-hidden p-4">
      <div className="flex h-max w-full items-stretch gap-4 max-lg:flex-col">
        <OverAllStatus
          shouldNavigate
          snapshot={snapshot}
          wrapperClassName={isTablet ? 'w-[40%]' : 'w-[100%]'}
        />
        <CapTableGraphWrapper
          isDesktop={isDesktop}
          isTablet={isTablet}
          selectedGraphVariant={selectedGraphVariant}
          snapshot={snapshot}
          toggleGraphVariant={toggleGraphVariant}
          wrapperClassName={isTablet ? 'w-[60%]' : 'w-[100%]'}
        />
      </div>
      <div className="flex h-max w-full items-stretch gap-4 max-lg:flex-col">
        <div
          className={twMerge(
            'flex min-h-full flex-col items-center rounded-lg bg-gray-50 p-1',
            isDesktop || isTablet ? 'w-[38%]' : 'w-[100%]',
          )}
        >
          <div className="flex w-full items-start justify-between gap-4 px-4 py-3">
            <span className="w-full text-left text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
              top 5 stakeholders
            </span>
            <ChevronDownIcon
              className="-rotate-90 transform cursor-pointer"
              onClick={() => navigate(PageRoute.STAKEHOLDERS)}
            />
          </div>
          <div className="h-full w-full rounded-md bg-white px-3 py-2 shadow-sm">
            <div className="grid w-full grid-cols-[repeat(1,minmax(125px,2fr))_repeat(2,minmax(54px,1fr))] gap-3 p-3 pt-0">
              <span className="whitespace-nowrap text-label-md font-[500] text-gray-700">Name</span>
              <span className="whitespace-nowrap text-label-md font-[500] text-gray-700">
                Issued %
              </span>
              <span className="whitespace-nowrap text-label-md font-[500] text-gray-700">
                Diluted %
              </span>
            </div>
            {top5Stakeholders.map((stakeholder, index) => (
              <div
                className="grid w-full grid-cols-[repeat(1,minmax(125px,2fr))_repeat(2,minmax(54px,1fr))] gap-3 p-3"
                key={`${stakeholder.name}_${index}`}
              >
                <span className="truncate text-xs font-[450] text-gray-700">
                  {stakeholder.name}
                </span>
                <span className="text-xs font-[450] text-gray-700">
                  {stakeholder.issuedPercentage}
                </span>
                <span className="text-xs font-[450] text-gray-700">
                  {stakeholder.dilutedPercentage}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div
          className={twMerge(
            'flex min-h-full flex-col items-center rounded-lg bg-gray-50 p-1',
            isDesktop || isTablet ? 'w-[62%]' : 'w-[100%]',
          )}
        >
          <div className="flex w-full items-start justify-between gap-4 px-4 py-3">
            <span className="w-full text-left text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
              pool allocation
            </span>
            <ChevronDownIcon
              className="-rotate-90 transform cursor-pointer"
              onClick={() => {
                navigate(PageRoute.POOL_PLANS);
              }}
            />
          </div>
          <div
            className={twMerge(
              'relative flex h-[280px] w-full gap-4 rounded-md bg-white shadow-sm',
              isDesktop ? 'p-6' : isTablet ? 'p-5' : 'h-full flex-col gap-6 p-2',
            )}
          >
            <PoolGraph
              isDesktop={isDesktop}
              isTablet={isTablet}
              snapshot={snapshot}
              variant={PoolGraphVariant.EQUITY}
            />
            <PoolGraph
              isDesktop={isDesktop}
              isTablet={isTablet}
              snapshot={snapshot}
              variant={PoolGraphVariant.GRANTED}
            />
          </div>
        </div>
      </div>
      <div className="h-max w-full max-lg:hidden">
        <div className="flex min-h-full flex-col items-center rounded-lg bg-gray-50 p-1">
          <div className="flex w-full items-start justify-between gap-4 px-4 py-3">
            <span className="w-full text-left text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
              valuation history
            </span>
            <ChevronDownIcon
              className="-rotate-90 transform cursor-pointer"
              onClick={() => {
                navigate(PageRoute.EVENTS);
              }}
            />
          </div>
        </div>
        <div className="relative flex h-full w-full rounded-md bg-white p-6 pl-2 shadow-sm">
          <span className="absolute left-[90px] whitespace-nowrap text-label-sm font-medium text-gray-500">
            Currency: USD (millions)
          </span>
          <div className="h-[344px] w-full">
            {snapshots.length > 1 ? (
              <EventGraph snapshots={snapshots} variant={EventGraphVariant.VALUATION} />
            ) : (
              <EmptyGraph variant={EventGraphVariant.VALUATION} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
