import React, { FC, useCallback, useEffect } from 'react';
import { Control, useFieldArray, UseFormSetValue, useWatch } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { CalendarIcon, InfoCircle } from '../../../../assets/icons';
import Button from '../../../../components/Button';
import { FormInput } from '../../../../components/Input';
import { Stakeholder, useFormat } from '../../../../hooks';
import { EventFormType } from '../../../../types/events.types';
import { DatePicker } from '../../../PoolPlans/PoolForm/DatePicker';
import { FormDropDown, ShareClassCombobox, StakeholderCombobox } from '../Components';
import { FormSchema } from '../validation';

export type ClassConversionStepOneProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
};

export const ClassConversionStepOne: FC<ClassConversionStepOneProps> = ({
  control,
  setFormData,
  companyId,
}) => {
  const { initialStep } = useWatch<FormSchema>({ control });

  const defaultDate = new Date(+0);

  const {
    fields: classConversionItemsFields,
    append: classConversionItemsAppend,
    remove: classConversionItemsRemove,
  } = useFieldArray({
    control,
    name: 'stepOne.eventDetails.conversion.classConversionItems',
  });

  const handleRemoveClassConversionItems = useCallback(
    (index: number) => {
      classConversionItemsRemove(index);
    },
    [classConversionItemsRemove],
  );

  const handleAppendClassConversionItems = useCallback(() => {
    classConversionItemsAppend({
      date: defaultDate,
      balance: 0,
      stakeholder: { id: '', fullName: '' },
      shareClassFrom: { id: '', name: '', conversionRatio: 0 },
      shareClassTo: { id: '', name: '' },
      shares: 0,
    });
  }, [classConversionItemsAppend]);

  useEffect(() => {
    if (initialStep?.type === EventFormType.CONVERSION && classConversionItemsFields.length === 0) {
      handleAppendClassConversionItems();
    }
  }, [initialStep?.type]);

  return (
    <>
      {classConversionItemsFields.map((field, index) => (
        <ClassConversionItem
          companyId={companyId}
          control={control}
          handleRemoveClassConversionItems={handleRemoveClassConversionItems}
          index={index}
          key={field.id}
          setFormData={setFormData}
          showRemoveButton={classConversionItemsFields.length > 1}
        />
      ))}
      <Button
        className="w-fit px-4 py-2 text-sm font-[450] text-brand-700 underline disabled:border-transparent disabled:bg-transparent"
        onClick={handleAppendClassConversionItems}
        styleType="NONE"
      >
        + Add Another Class Conversion
      </Button>
    </>
  );
};

const ClassConversionItem: FC<
  ClassConversionStepOneProps & {
    index: number;
    handleRemoveClassConversionItems: (index: number) => void;
    showRemoveButton: boolean;
  }
> = ({
  control,
  setFormData,
  companyId,
  index,
  handleRemoveClassConversionItems,
  showRemoveButton,
}) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const { format } = useFormat();

  const defaultDate = new Date(+0);

  const stakeholderId =
    stepOne?.eventDetails?.conversion?.classConversionItems?.[index]?.stakeholder?.id || '';
  const shareClassId =
    stepOne?.eventDetails?.conversion?.classConversionItems?.[index]?.shareClassFrom?.id || '';
  const initialShares =
    stepOne?.eventDetails?.conversion?.classConversionItems?.[index]?.initialShares || undefined;
  const initialStakeholderId =
    stepOne?.eventDetails?.conversion?.classConversionItems?.[index]?.initialStakeholderId;
  const initialShareClassFromId =
    stepOne?.eventDetails?.conversion?.classConversionItems?.[index]?.initialShareClassFromId;
  const initialShareClassToId =
    stepOne?.eventDetails?.conversion?.classConversionItems?.[index]?.initialShareClassToId;
  const shouldAddInitialShares =
    initialShares &&
    initialStakeholderId === stakeholderId &&
    initialShareClassFromId === shareClassId;
  const shouldSubtractInitialShares =
    initialShares &&
    initialStakeholderId === stakeholderId &&
    initialShareClassToId === shareClassId;

  const { balance } = Stakeholder.useBalance({ companyId, stakeholderId, shareClassId });

  useEffect(() => {
    if (balance) {
      setFormData(
        `stepOne.eventDetails.conversion.classConversionItems.${index}.balance`,
        shouldAddInitialShares
          ? Number(balance) + initialShares
          : shouldSubtractInitialShares
            ? Number(balance) - initialShares
            : Number(balance),
      );
    }
  }, [
    balance,
    index,
    initialShares,
    setFormData,
    shouldAddInitialShares,
    shouldSubtractInitialShares,
  ]);

  const getFullTitle = useCallback(
    (index: number) => {
      const truncateName = (name: string) => {
        return name.length > 25 ? `${name.slice(0, 25)}...` : name;
      };

      const stakeholderName =
        stepOne?.eventDetails?.['conversion']?.classConversionItems?.[index]?.stakeholder?.fullName;

      if (stakeholderName) {
        return `Conversion - ${truncateName(stakeholderName)}`;
      }

      return 'Conversion';
    },
    [stepOne?.eventDetails],
  );

  const calculateConvertedShares = useCallback(
    (index: number) => {
      const shares = stepOne?.eventDetails?.['conversion']?.classConversionItems?.[index]?.shares;
      const conversionRatio =
        stepOne?.eventDetails?.['conversion']?.classConversionItems?.[index]?.shareClassFrom
          ?.conversionRatio;

      if (shares && conversionRatio) {
        return Number(shares * conversionRatio).toLocaleString('en-US');
      }

      return '00';
    },
    [stepOne?.eventDetails],
  );

  const calculateBalance = useCallback(
    (index: number) => {
      if (balance) {
        const currentItem = stepOne?.eventDetails?.conversion?.classConversionItems?.[index];

        if (!currentItem) {
          return Number(balance);
        }

        const filteredItems =
          stepOne?.eventDetails?.conversion?.classConversionItems &&
          stepOne?.eventDetails?.conversion?.classConversionItems.filter((_, i) => i !== index);

        if (!filteredItems || filteredItems.length === 0) {
          return Number(balance);
        }

        const matchingItems = filteredItems.filter(
          (item) =>
            item.stakeholder?.id === currentItem.stakeholder?.id &&
            item.shareClassFrom?.id === currentItem.shareClassFrom?.id,
        );

        if (matchingItems.length === 0) {
          return Number(balance);
        }

        const totalShares = matchingItems.reduce((sum, item) => sum + (item.shares || 0), 0);

        return Number(balance - totalShares);
      }
      return 0;
    },
    [balance, stepOne?.eventDetails?.conversion?.classConversionItems],
  );

  const calculateAvailableShares = useCallback(
    (index: number, shouldConvert: boolean) => {
      const conversionRatio =
        stepOne?.eventDetails?.conversion?.classConversionItems?.[index]?.shareClassFrom
          ?.conversionRatio || 1;
      const shares = stepOne?.eventDetails?.conversion?.classConversionItems?.[index]?.shares || 0;
      const balance = shouldAddInitialShares
        ? calculateBalance(index) + (initialShares || 0)
        : shouldSubtractInitialShares
          ? calculateBalance(index) - (initialShares || 0)
          : calculateBalance(index);
      const convertedBalance = shouldConvert ? balance * conversionRatio : balance;

      return convertedBalance - shares;
    },
    [
      calculateBalance,
      initialShares,
      shouldAddInitialShares,
      shouldSubtractInitialShares,
      stepOne?.eventDetails?.conversion?.classConversionItems,
    ],
  );

  const isShareClassDuplicate =
    stepOne?.eventDetails?.conversion?.classConversionItems?.[index]?.shareClassFrom?.id &&
    stepOne?.eventDetails?.conversion?.classConversionItems?.[index]?.shareClassTo?.id &&
    stepOne?.eventDetails?.conversion?.classConversionItems?.[index]?.shareClassFrom?.id ===
      stepOne?.eventDetails?.conversion?.classConversionItems?.[index]?.shareClassTo?.id;

  return (
    <FormDropDown
      handleRemove={() => handleRemoveClassConversionItems(index)}
      showRemoveButton={showRemoveButton}
      title={getFullTitle(index)}
    >
      <div className="flex w-full flex-col gap-3">
        <div className="flex w-full items-center justify-between">
          <span className="text-sm font-[450] text-gray-700">Event date</span>
          <DatePicker
            onSelect={(date) =>
              setFormData(
                `stepOne.eventDetails.conversion.classConversionItems.${index}.date`,
                date,
              )
            }
            wrapperClassName="w-[224px]"
          >
            <FormInput
              autoComplete="off"
              control={control}
              customValue={(value) => {
                return value && new Date(value).getTime() !== defaultDate.getTime()
                  ? format(value, 'dd/MM/yyyy')
                  : '';
              }}
              icon={<CalendarIcon className="mr-2 size-6" iconColor="#98A2B3" />}
              name={`stepOne.eventDetails.conversion.classConversionItems.${index}.date`}
              numberOnly
              placeholder="Date"
              wrapperClassName="w-full"
            />
          </DatePicker>
        </div>
        <div className="flex w-full items-center justify-between">
          <span className="text-sm font-[450] text-gray-700">Stakeholder</span>
          <StakeholderCombobox
            companyId={companyId}
            control={control}
            name={`stepOne.eventDetails.conversion.classConversionItems.${index}.stakeholder`}
          />
        </div>
        <div className="flex w-full items-center justify-between">
          <span className="text-sm font-[450] text-gray-700">From Share Class</span>
          <ShareClassCombobox
            commonStockIsAllowed={false}
            companyId={companyId}
            control={control}
            fieldName={`stepOne.eventDetails.conversion.classConversionItems.${index}.shareClassFrom`}
          />
        </div>
        <div className="flex w-full items-center justify-between">
          <span className="text-sm font-[450] text-gray-700">To Share Class</span>
          <ShareClassCombobox
            companyId={companyId}
            control={control}
            fieldName={`stepOne.eventDetails.conversion.classConversionItems.${index}.shareClassTo`}
            shareClassFromId={
              stepOne?.eventDetails?.conversion?.classConversionItems?.[index]?.shareClassFrom?.id
            }
          />
        </div>
        <div className="flex w-full items-center justify-between">
          <span className="text-sm font-[450] text-gray-700">No of Shares</span>
          <div className="flex flex-col items-start gap-2">
            <FormInput
              control={control}
              name={`stepOne.eventDetails.conversion.classConversionItems.${index}.shares`}
              numberOnly
              placeholder="Value"
              shouldFormatNumber
              wrapperClassName="w-[224px]"
            />
            {balance && (
              <span className="text-xs text-gray-600">
                Available:{' '}
                {Math.max(calculateAvailableShares(index, false), 0).toLocaleString('en-US')}
              </span>
            )}
          </div>
        </div>
        {isShareClassDuplicate && (
          <div className="flex items-center gap-1">
            <InfoCircle />
            <span className="rounded text-xs font-[450] text-fireside-600">
              Please choose different share classes
            </span>
          </div>
        )}
        <div className="h-[1px] w-full bg-gray-100" />
        <div className="flex w-full items-center justify-between py-3">
          <span className="text-sm font-[450] text-gray-700">Converted Shares</span>
          <div className="flex flex-col items-end gap-2">
            <span
              className={twMerge(
                'rounded px-3 py-1 text-sm font-[550]',
                calculateConvertedShares(index) === '00'
                  ? 'bg-gray-100 text-gray-300'
                  : 'bg-brand-25 text-brand-700',
              )}
            >
              {calculateConvertedShares(index)}
            </span>
            {balance && (
              <span className="text-xs text-gray-600">
                Available:{' '}
                {Math.max(calculateAvailableShares(index, true), 0).toLocaleString('en-US')}
              </span>
            )}
          </div>
        </div>
        <div className="h-[1px] w-full bg-gray-100" />
        <div className="flex w-full items-center justify-between py-3">
          <span className="text-sm font-[450] text-gray-700">Conversion ratio</span>
          <span
            className={twMerge(
              'rounded px-3 py-1 text-sm font-[550]',
              stepOne?.eventDetails?.['conversion']?.classConversionItems?.[index]?.shareClassFrom
                ?.conversionRatio === 0
                ? 'bg-gray-100 text-gray-300'
                : 'bg-brand-25 text-brand-700',
            )}
          >
            {stepOne?.eventDetails?.['conversion']?.classConversionItems?.[index]?.shareClassFrom
              ?.conversionRatio || 0}
            x
          </span>
        </div>
      </div>
    </FormDropDown>
  );
};
