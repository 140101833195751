import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { ModalWrapper } from '../../../components/ModalWrapper';
import { QueryKey } from '../../../constants';
import { Company, EventHooks, useModalState } from '../../../hooks';
import { EventTypeBackend, EventUnion } from '../../../types/events.types';
import { toBackendDateFormat } from '../../../utils/toBackendDateFormat';
import { ActivityOverview } from '../ActivityOverview';
import { AddEventForm } from '../EventForm/Add';
import { EditEventModal } from '../EventForm/Edit';
import { EditEventStepVariant } from '../EventForm/type';
import { GraphsDesk } from './GraphsDesk';
import { History } from './History';

type EventsDesktopProps = {
  companyId: string;
};

export type SelectedEvent = {
  id: string;
  transactionId?: string;
  step: EditEventStepVariant;
  mode: 'EDIT' | 'VIEW';
};

export const EventsDesktop: FC<EventsDesktopProps> = ({ companyId }) => {
  const [selectedEvent, setSelectedEvent] = useState<SelectedEvent | null>({
    id: '',
    step: 1,
    mode: 'EDIT',
  });

  const { deleteEvent } = EventHooks.useDelete();
  const { update } = EventHooks.useUpdate();
  const { issuedShares } = Company.useIssuedShares({
    companyId,
  });

  const queryClient = useQueryClient();

  const {
    isOpen: isOpenAddEventModal,
    handleOpenModal: handleOpenAddEventModal,
    handleCloseModal: handleCloseAddEventModal,
    toggler: addEventModalToggler,
  } = useModalState();

  const {
    isOpen: isOpenEditEventModal,
    handleOpenModal: handleOpenEditEventModal,
    handleCloseModal: handleCloseEditEventModal,
  } = useModalState();

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_EVENTS] });
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_EVENT_TRANSACTIONS] });
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_ISSUED_SHARES] });
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SNAPSHOTS] });
  }, [queryClient]);

  const handleDeleteTransaction = useCallback(
    (event: EventUnion, transactionId: string) => {
      const { id, type } = event;
      const buyBackItems =
        type === EventTypeBackend.BUYBACK &&
        event.items.filter((item) => item.id !== transactionId);
      const conversionItems =
        type === EventTypeBackend.CONVERSION &&
        event.items.filter((item) => item.id !== transactionId);
      const fundraisingItems =
        type === EventTypeBackend.FUNDRAISING_ROUND &&
        event.items.filter((item) => item.id !== transactionId);
      const grantItems =
        type === EventTypeBackend.GRANT && event.items.filter((item) => item.id !== transactionId);
      const secondariesItems =
        type === EventTypeBackend.SECONDARIES &&
        event.items.filter((item) => item.id !== transactionId);
      const issuanceItems =
        type === EventTypeBackend.SHARE_ISSUANCE &&
        event.items.filter((item) => item.id !== transactionId);

      const buyback = type === EventTypeBackend.BUYBACK &&
        buyBackItems && {
          id,
          items: buyBackItems.map(({ date, shareClass, stakeholder, sharesCount, id }) => ({
            date: toBackendDateFormat(date),
            stakeholderId: stakeholder.id,
            shareClassId: shareClass.id,
            sharesCount,
            id,
          })),
        };

      const conversion = type === EventTypeBackend.CONVERSION &&
        conversionItems && {
          id,
          items: conversionItems.map(
            ({ date, fromShareClass, sharesCount, toShareClass, stakeholder, id }) => ({
              date: toBackendDateFormat(date),
              stakeholderId: stakeholder.id,
              fromShareClassId: fromShareClass.id,
              toShareClassId: toShareClass.id,
              sharesCount,
              id,
            }),
          ),
        };

      const fundraisingRound = type === EventTypeBackend.FUNDRAISING_ROUND &&
        fundraisingItems && {
          id,
          valuationId: event.valuation.id,
          sharePrice: event.sharePrice,
          isOpen: event.isOpen,
          items: fundraisingItems.map(({ date, investedValue, shareClass, stakeholder, id }) => ({
            id,
            stakeholderId: stakeholder.id,
            shareClassId: shareClass.id,
            investedValue,
            date: toBackendDateFormat(date),
          })),
        };

      const grant = event.type === EventTypeBackend.GRANT &&
        grantItems && {
          id: event.id,
          items: grantItems.map(({ id, date, numbersOfGrants, plan, stakeholder }) => ({
            id,
            date: toBackendDateFormat(date),
            planId: plan.id,
            stakeholderId: stakeholder.id,
            numbersOfGrants,
          })),
        };

      const secondaries = type === EventTypeBackend.SECONDARIES &&
        secondariesItems && {
          id,
          items: secondariesItems.map(
            ({
              date,
              shareClass,
              fromStakeholder,
              sharesCount,
              sharesValue,
              toStakeholder,
              id,
            }) => ({
              date: toBackendDateFormat(date),
              fromStakeholderId: fromStakeholder.id,
              toStakeholderId: toStakeholder.id,
              shareClassId: shareClass.id,
              sharesCount,
              sharesValue,
              id,
            }),
          ),
        };

      const issuance = type === EventTypeBackend.SHARE_ISSUANCE &&
        issuanceItems && {
          id,
          valuationId: event.valuation.id,
          date: toBackendDateFormat(event.date),
          sharePrice: event.sharePrice,
          items: issuanceItems.map(({ sharesCount, shareClass, stakeholder, id }) => ({
            stakeholderId: stakeholder.id,
            shareClassId: shareClass.id,
            sharesCount,
            id,
          })),
        };
      update(
        {
          companyId,
          eventId: event.eventId,
          data: {
            type: event.type,
            grant: grant || undefined,
            fundraisingRound: fundraisingRound || undefined,
            issuance: issuance || undefined,
            buyback: buyback || undefined,
            secondaries: secondaries || undefined,
            conversion: conversion || undefined,
            valuation: undefined,
            filesLinks: event.filesLinks,
            additionalNotes: event.additionalNotes,
          },
        },
        {
          onSuccess: () => {
            toast.success('Transaction successfully deleted');
            invalidateQuery();
          },
        },
      );
    },
    [companyId, invalidateQuery, update],
  );

  return (
    <>
      <ModalWrapper
        className="w-full max-w-[485px]"
        control={{ open: isOpenAddEventModal, onOpenChange: addEventModalToggler }}
        side="RIGHT"
      >
        <AddEventForm
          companyId={companyId}
          handleClose={handleCloseAddEventModal}
          invalidateQuery={invalidateQuery}
          issuedShares={issuedShares || 0}
        />
      </ModalWrapper>

      <EditEventModal
        companyId={companyId}
        eventId={selectedEvent?.id || ''}
        handleClose={handleCloseEditEventModal}
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenEditEventModal}
        issuedShares={issuedShares || 0}
        onEditClick={({ id, step }) => setSelectedEvent({ id, mode: 'EDIT', step })}
        previewMode={selectedEvent?.mode === 'VIEW' || undefined}
        step={selectedEvent?.step || 1}
        transactionId={selectedEvent?.transactionId}
      />
      <div className="flex h-fit w-full flex-col gap-4 overflow-hidden p-[1px]">
        <ActivityOverview companyId={companyId} onAddEvent={handleOpenAddEventModal} />
        <GraphsDesk companyId={companyId} />
        <History
          companyId={companyId}
          onAddEvent={handleOpenAddEventModal}
          onDelete={(id) => {
            deleteEvent(
              { companyId, eventId: id },
              {
                onSuccess: () => {
                  invalidateQuery();
                  toast.success('Event successfully deleted');
                },
                onError: (error) => {
                  if (error.body?.message === 'Internal server error') {
                    toast.error('Unable to delete event related to another event');
                  } else {
                    toast.error(error.body?.message);
                  }
                },
              },
            );
          }}
          onEdit={(id) => {
            handleOpenEditEventModal();
            setSelectedEvent({
              id,
              mode: 'EDIT',
              step: 4,
            });
          }}
          onTransactionDelete={(event, transactionId) => {
            handleDeleteTransaction(event, transactionId);
          }}
          onTransactionEdit={(id, transactionId) => {
            handleOpenEditEventModal();
            setSelectedEvent({
              id,
              mode: 'EDIT',
              step: 4,
              transactionId,
            });
          }}
          onTransactionView={(id, transactionId) => {
            handleOpenEditEventModal();
            setSelectedEvent({
              id,
              mode: 'VIEW',
              step: 4,
              transactionId,
            });
          }}
          onView={(id) => {
            handleOpenEditEventModal();
            setSelectedEvent({
              id,
              mode: 'VIEW',
              step: 4,
            });
          }}
        />
      </div>
    </>
  );
};
