import { FC } from 'react';

import { EmptySearchList } from '../../../../components/EmptySearchList';
import Loader from '../../../../components/Loader';
import { SnapshotHooks } from '../../../../hooks';
import { Column, ViewBy } from '../../../../types/snapshot.types';
import { TableProps } from '../Details';
import { EmptyDetails } from '../EmptyDetails';
import { snapshotViewBy } from '../SnapshotViewBy';
import { BasicTable } from './TableBasic';

export const Table: FC<TableProps> = ({
  viewBy,
  visibleColumns,
  companyId,
  valuationId,
  type,
  snapshotByDate,
  isSelectedSnapshotByDate,
  search,
  isEmptySearchResult,
  setEmptySearchResult,
}) => {
  const { snapshots, isLoading } = SnapshotHooks.useSnapshots({ companyId });
  if (isLoading) return <Loader />;

  const snapshot = isSelectedSnapshotByDate
    ? snapshotByDate
    : snapshots.find(({ valuation: { id } }) => id === valuationId);

  const isSnapshotStatsExist = snapshot?.stats && snapshot?.stats?.stakeholders?.length > 0;

  if (!snapshot || !isSnapshotStatsExist) return <EmptyDetails />;

  if (isEmptySearchResult) return <EmptySearchList className="m-auto my-20" />;

  const selectedViewBy: ViewBy | 'all' =
    viewBy.stakeholders && viewBy.type ? 'all' : viewBy.type ? ViewBy.TYPE : ViewBy.STAKEHOLDERS;

  const dynamicShareClassFields = snapshot.stats.shareClasses
    .filter(({ name }) => name !== 'Common Stock')
    .map(({ name }) => name);

  return (
    <>
      <BasicTable
        additionalColumns={{ [Column.OTHER_SHARE_CLASSES]: dynamicShareClassFields }}
        sortBy={type}
        viewBy={viewBy}
        visibleColumns={visibleColumns}
      >
        {snapshotViewBy[selectedViewBy]({
          snapshot,
          viewBy,
          visibleColumns,
          type,
          search,
          setEmptySearchResult,
          dynamicShareClassColumns: dynamicShareClassFields,
        })}
      </BasicTable>
    </>
  );
};
