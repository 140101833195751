import { FC, useEffect, useState } from 'react';

import { useFakePagination, useLocale } from '../../../../hooks';
import { stakeholderTypeTranslation } from '../../../../translations/stakeholderTranslation';
import { Column, DetailsSortVariant } from '../../../../types/snapshot.types';
import { filterByAllFields } from '../../../../utils/filterByAllFields';
import { toRound } from '../../../../utils/getRoundedNumber';
import { toNumber } from '../../../../utils/toNumber';
import { AmountOfFetchedItems } from '../../variables';
import { PaginationItem } from '../TableVariants/Items/PaginationItem';
import { ShareClassTableItem } from '../TableVariants/Items/ShareClassTableItem';
import { StakeholderTableItem } from '../TableVariants/Items/StakeholderTableItem';
import { SnapshotViewByProps } from './type';

export const SnapshotViewByStakeholder: FC<SnapshotViewByProps> = ({
  snapshot: { stats },
  viewBy,
  visibleColumns,
  type,
  search,
  setEmptySearchResult,
  dynamicShareClassColumns,
}) => {
  const { messagesLocale } = useLocale();
  const [currentPage, setCurrentPage] = useState(1);

  const [itemsToFetch, setItemsToFetch] = useState(AmountOfFetchedItems.THREE);

  const stakeholdersFilteredData = filterByAllFields(stats.stakeholders, search).filter(
    (stakeholder) =>
      stakeholder.shareClasses.reduce((sharesTotal, { shares }) => sharesTotal + shares, 0) > 0,
  );

  const { data, totalPages } = useFakePagination({
    currentPage,
    data: stakeholdersFilteredData,
    take: Number(itemsToFetch),
  });
  useEffect(() => {
    if (stakeholdersFilteredData.length === 0) {
      setEmptySearchResult(true);
    }
  }, [setEmptySearchResult, stakeholdersFilteredData.length]);

  switch (type) {
    case DetailsSortVariant.BY_STAKEHOLDER:
      const issuedTotal =
        stakeholdersFilteredData.reduce((acc, curr) => (acc += curr.issuedPercentage || 0), 0) *
        100;

      const dilutedTotal =
        stakeholdersFilteredData.reduce((acc, curr) => (acc += curr.dilutedPercentage || 0), 0) *
        100;

      const votingTotal =
        stakeholdersFilteredData.reduce((acc, curr) => (acc += curr.votingPercentage || 0), 0) *
        100;

      const votingRightTotal = stakeholdersFilteredData.reduce(
        (acc, curr) => (acc += curr.votingRight || 0),
        0,
      );

      const noIssuedTotal = stakeholdersFilteredData.reduce(
        (acc, curr) => (acc += curr.issued || 0),
        0,
      );
      const dilutedSharesTotal = stakeholdersFilteredData.reduce(
        (acc, curr) => (acc += curr.diluted || 0),
        0,
      );

      const totalShares = stakeholdersFilteredData.reduce(
        (stakeholdersAcc, { shareClasses }) =>
          stakeholdersAcc +
          filterByAllFields(shareClasses, search).reduce(
            (shareClassAcc, { shares }) => shareClassAcc + toNumber(shares),
            0,
          ),
        0,
      );

      const totalShareClasses = stakeholdersFilteredData.reduce(
        (stakeholdersAcc, { shareClasses }) =>
          stakeholdersAcc + filterByAllFields(shareClasses, search).length,
        0,
      );

      const averageSharePrice = toNumber(totalShares / totalShareClasses);

      const shareClassesTotal = stats.shareClasses
        .filter(({ name }) => name !== 'Common Stock')
        .map(({ name }) =>
          stats.stakeholders.reduce(
            (acc, curr) =>
              (acc += filterByAllFields(curr.shareClasses, search)
                .filter(({ name: stakeholderShareClassName }) => name === stakeholderShareClassName)
                .reduce((acc, curr) => (acc += curr.shares), 0)),
            0,
          ),
        );

      const commonStockShareClassTotal = stakeholdersFilteredData.reduce(
        (acc, curr) =>
          (acc += filterByAllFields(curr.shareClasses, search)
            .filter(({ name }) => name === 'Common Stock')
            .reduce((acc, curr) => (acc += curr.shares), 0)),
        0,
      );

      const investmentTotal = 0;

      return (
        <>
          {data.map(
            ({
              diluted,
              dilutedPercentage,
              issued,
              issuedPercentage,
              type,
              votingPercentage,
              name,
              id,
              votingRight,
              shareClasses: stakeholderShareClasses,
            }) => {
              const shareClasses = filterByAllFields(stakeholderShareClasses, search);

              const otherShareClasses = dynamicShareClassColumns.map(
                (columnsName) =>
                  stakeholderShareClasses.find(({ name }) => name === columnsName)?.shares || 0,
              );

              return (
                <StakeholderTableItem
                  fields={{
                    [Column.NAME]: null,
                    [Column.STAKEHOLDER_NAME]: name,
                    [Column.TYPE]: messagesLocale[stakeholderTypeTranslation[type]],
                    [Column.ISSUED]: toRound(issuedPercentage * 100) || 0,
                    [Column.DILUTED]: toRound(dilutedPercentage * 100),
                    [Column.VOTING]: toRound(votingPercentage * 100),
                    [Column.NO_ISSUED]: issued,
                    [Column.DILUTED_SHARES]: diluted,
                    [Column.COMMON_STOCK]:
                      shareClasses.find(({ name }) => name === 'Common Stock')?.shares || 0,
                    [Column.OTHER_SHARE_CLASSES]: otherShareClasses,
                    [Column.INVESTMENT]: '-',
                    [Column.AVERAGE_SHARE_PRICE]: toNumber(
                      shareClasses.reduce((acc, { shares }) => (acc += shares), 0) /
                        shareClasses.length,
                    ),
                    [Column.VOTING_RIGHTS]: toNumber(votingRight).toLocaleString('en-US'),
                  }}
                  key={id}
                  search={search}
                  viewBy={viewBy}
                  visibleColumns={visibleColumns}
                />
              );
            },
          )}

          <tr>
            <td colSpan={visibleColumns.length}>
              <PaginationItem
                currentPage={currentPage}
                handleChangeCurrentPage={setCurrentPage}
                handleFetchAmountChange={setItemsToFetch}
                itemsToFetch={itemsToFetch}
                totalPages={totalPages}
              />
            </td>
          </tr>

          <StakeholderTableItem
            className="font-[550] text-gray-800"
            fields={{
              [Column.NAME]: 'Total',
              [Column.STAKEHOLDER_NAME]: 'Total',
              [Column.TYPE]: null,
              [Column.ISSUED]: issuedTotal.toLocaleString('en-US'),
              [Column.DILUTED]: dilutedTotal.toLocaleString('en-US'),
              [Column.VOTING]: votingTotal.toLocaleString('en-US'),
              [Column.NO_ISSUED]: noIssuedTotal.toLocaleString('en-US'),
              [Column.DILUTED_SHARES]: dilutedSharesTotal.toLocaleString('en-US'),
              [Column.COMMON_STOCK]: commonStockShareClassTotal.toLocaleString('en-US'),
              [Column.OTHER_SHARE_CLASSES]: shareClassesTotal,
              [Column.INVESTMENT]: investmentTotal.toLocaleString('en-US'),
              [Column.AVERAGE_SHARE_PRICE]: averageSharePrice,
              [Column.VOTING_RIGHTS]: votingRightTotal.toLocaleString('en-US'),
            }}
            search={search}
            viewBy={viewBy}
            visibleColumns={visibleColumns}
          />
        </>
      );

    case DetailsSortVariant.BY_SHARE_CLASS:
      return stakeholdersFilteredData.map(
        ({
          diluted,
          dilutedPercentage,
          issued,
          issuedPercentage,
          type,
          name,
          votingPercentage,
          votingRight,
          id,
        }) => (
          <ShareClassTableItem
            fields={{
              [Column.SHARE_CLASS_NAME]: null,
              [Column.STAKEHOLDER_NAME]: name,
              [Column.TYPE]: messagesLocale[stakeholderTypeTranslation[type]],
              [Column.NAME]: name,
              [Column.ISSUED]: issuedPercentage * 100 || 0,
              [Column.DILUTED]: dilutedPercentage * 100 || 0,
              [Column.VOTING]: votingPercentage * 100 || 0,
              [Column.NO_ISSUED]: issued.toLocaleString('en-US'),
              [Column.DILUTED_SHARES]: diluted.toLocaleString('en-US'),
              [Column.INVESTMENT]: '-',
              [Column.AVERAGE_SHARE_PRICE]: 'none',
              [Column.VOTING_RIGHTS]: toNumber(votingRight).toLocaleString('en-US'),
              [Column.NO_OF_STAKEHOLDERS]: 'none',
            }}
            key={id}
            viewBy={viewBy}
            visibleColumns={visibleColumns}
          />
        ),
      );

    default:
      return <></>;
  }
};
