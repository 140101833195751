import { ReactNode } from 'react';

export enum SarColumn {
  STAKEHOLDER = 'stakeholder',
  GRANT = 'grant',
  EXERCISE_DATE = 'exercise-date',
  SAR_FOR_EXERCISING = 'sar-for-exercising',
  BASE_PRICE = 'base-price',
  SHARE_PRICE = 'share-price',
  NO_OF_SARS = 'no-of-sars',
  SAR_PAYOUT = 'sar-payout',
  ISSUED_SHARES = 'issued-shares',
  REMOVE_ROW = 'remove-row',
}
export const sarColumnMap = Object.values(SarColumn);

export const sarColumnTitle: Record<SarColumn, string | ReactNode> = {
  [SarColumn.STAKEHOLDER]: 'Stakeholder',
  [SarColumn.GRANT]: 'Grant',
  [SarColumn.EXERCISE_DATE]: 'Exercise Date',
  [SarColumn.SAR_FOR_EXERCISING]: 'SAR for exercising',
  [SarColumn.BASE_PRICE]: 'Base price',
  [SarColumn.SHARE_PRICE]: 'Share price',
  [SarColumn.NO_OF_SARS]: 'No of SARs',
  [SarColumn.SAR_PAYOUT]: 'SAR Payout',
  [SarColumn.ISSUED_SHARES]: 'Issued Shares',
  [SarColumn.REMOVE_ROW]: '',
};
