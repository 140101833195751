import { format } from 'date-fns';
import React, { FC, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon, InfoCircle } from '../../../../../assets/icons';
import { AppFormattedMessage } from '../../../../../components/AppFormattedMessage';
import Button from '../../../../../components/Button';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../../../components/HoverCard';
import Loader from '../../../../../components/Loader';
import { useSelectedCompany, useSharePlans } from '../../../../../hooks';
import { StringKey } from '../../../../../lang';
import { Pool, PoolPlansTab } from '../../../../../types/pool-plans.types';
import { StickyPlanItem } from './StickyPlanItem';

type StickyPoolItemProps = {
  pool: Pool;
  isSelected: boolean;
  setSelectedPool: (state: string) => void;
  selectedPlan: string;
  setSelectedPlan: (state: string) => void;
  selectedTab: PoolPlansTab;
};

export const StickyPoolItem: FC<StickyPoolItemProps> = ({
  pool,
  isSelected,
  setSelectedPool,
  selectedPlan,
  setSelectedPlan,
  selectedTab,
}) => {
  const { selectedCompany } = useSelectedCompany();
  const companyId = selectedCompany?.id || '';

  const handlePoolSelected = () => {
    setSelectedPool(pool.id);
    setSelectedPlan('');
  };

  const handlePoolUnselected = () => {
    setSelectedPool('');
    setSelectedPlan('');
  };

  const { sharePlans, fetchNextPage, hasNextPage, isEmpty, numberOfFetchedItems } = useSharePlans({
    companyId,
    poolId: pool?.id || '',
    selectedTab,
  });

  const isTerminatedDatePassed = useMemo(() => {
    return pool?.terminationDate
      ? new Date(pool.terminationDate).getTime() < new Date().getTime()
      : false;
  }, [pool?.terminationDate]);
  return (
    <>
      <tr
        className={twMerge(
          'flex h-[72px] w-full border-l-[2px] border-l-white bg-white transition-colors',
          isSelected && 'border-l-brand-700 bg-brand-25',
        )}
      >
        <td className="relative h-[72px] border-b-[1px] border-b-gray-100">
          <div className="flex h-full w-full items-center justify-center">
            {!isEmpty ? (
              <Button
                className={twMerge(
                  'h-[72px] cursor-pointer rounded-none p-4 transition-colors',
                  isSelected && 'bg-brand-50',
                )}
                onClick={isSelected ? handlePoolUnselected : handlePoolSelected}
                styleType="NONE"
              >
                <ChevronDownIcon
                  className={twMerge('transition-transform', isSelected ? 'rotate-180' : '')}
                  iconColor={isSelected ? '#2565C8' : '#344054'}
                />
              </Button>
            ) : (
              <div className="w-12 p-4 transition-colors" />
            )}
          </div>
        </td>
        <td
          className={twMerge(
            'flex h-[72px] w-full items-center gap-4 text-nowrap border-b-[1px] border-b-gray-100 px-6 py-4',
            pool?.isTerminated && isTerminatedDatePassed && 'justify-between',
          )}
        >
          <div className="flex flex-col">
            <span className="max-w-[320px] truncate text-sm font-[450] text-gray-900">
              {pool.name}
            </span>
            <span className="max-w-[320px] truncate text-xs font-[450] text-gray-400">
              {pool.shareClass?.name}
            </span>
          </div>
          {pool.isTerminated && (
            <>
              {isTerminatedDatePassed ? (
                <span className="rounded-2xl bg-fireside-50 px-2 py-[2px] text-label-sm font-[450] text-fireside-600">
                  <AppFormattedMessage id={StringKey.TERMINATED} />
                </span>
              ) : (
                <HoverCard>
                  <HoverCardTrigger>
                    <InfoCircle className="size-4 cursor-pointer" />
                  </HoverCardTrigger>
                  <HoverCardContent
                    className="z-20 w-max rounded-lg bg-[#101828] px-3 py-2"
                    isShownTriangle
                    side="top"
                    triangleClassName="bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 ml-0"
                  >
                    <span className="text-label-md font-[550] text-white">
                      Termination set:{' '}
                      {pool?.terminationDate && format(pool?.terminationDate, 'dd/MM/yyyy')}
                    </span>
                  </HoverCardContent>
                </HoverCard>
              )}
            </>
          )}
        </td>
      </tr>
      {!isEmpty && sharePlans && isSelected && (
        <InfiniteScroll
          dataLength={numberOfFetchedItems}
          hasMore={hasNextPage}
          loader={<Loader />}
          next={fetchNextPage}
          scrollableTarget="scrollbar-target"
        >
          {sharePlans.map((plan) => (
            <StickyPlanItem
              isSelected={selectedPlan === plan.id}
              key={plan.id}
              plan={plan}
              setSelectedPlan={setSelectedPlan}
            />
          ))}
        </InfiniteScroll>
      )}
    </>
  );
};
