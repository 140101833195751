import { FC } from 'react';

import { useFormat } from '../../../hooks';
import { Badge } from '../Badge';
import { getDateIntervalFromToday } from '../formatDate';

type NotificationProps = {
  type: string;
  createdAt: Date;
  message: string;
  requestMessage: string;
  requestType: string;
  requestDate: Date;
};

export const ResolvedNotification: FC<NotificationProps> = ({
  createdAt,
  message,
  type,
  requestDate,
  requestMessage,
  requestType,
}) => {
  const { format } = useFormat();
  return (
    <div className="flex flex-col gap-4 rounded-lg border border-gray-200 bg-white px-4 py-3">
      <div className="flex w-full justify-between text-label-md font-medium text-gray-500">
        <span>{type}</span>
        <span>{getDateIntervalFromToday(createdAt)}</span>
      </div>
      <div className="flex gap-3">
        <div className="size-10 shrink-0 rounded-full bg-gray-200" />
        <div className="flex flex-col gap-1">
          <span className="text-sm font-[450] text-black">{message}</span>
          <div className="flex gap-1">
            <div className="h-10 w-1 border-b border-l border-gray-200" />
            <div className="mt-[27px] flex flex-col gap-2">
              <Badge title={requestType} />
              <span className="text-sm font-[450] text-black">{requestMessage}</span>
              <span className="text-label-md font-medium text-gray-500">
                {format(requestDate, 'dd/MM/yyyy')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
