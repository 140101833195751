import { FC, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ArrowIcon } from '../../../assets/icons';
import Button from '../../Button';
import { getDateIntervalFromToday } from '../formatDate';

type NotificationProps = {
  type: string;
  createdAt: Date;
  message: string;
  isRead: boolean;
  onMarkAsRead: () => void;
  onInviteUser: () => void;
};

export const ActivityNotification: FC<NotificationProps> = ({
  createdAt,
  isRead,
  message,
  onInviteUser,
  onMarkAsRead,
  type,
}) => {
  const [read, setRead] = useState(false);
  return (
    <div
      className={twMerge(
        'flex flex-col gap-4 rounded-lg border border-brand-50 bg-brand-25 px-4 py-3',
        (isRead || read) && 'border-gray-200 bg-white',
      )}
    >
      <div className="flex w-full justify-between text-label-md font-medium text-gray-500">
        <span>{type}</span>
        <span>{getDateIntervalFromToday(createdAt)}</span>
      </div>
      <div className="flex gap-3">
        <div className="size-10 shrink-0 rounded-full bg-brand-700" />
        <div className="flex flex-col gap-4">
          <span className="text-sm font-[450] text-black">{message}</span>
          <div className="flex items-center gap-4 max-lg:justify-center">
            {!(isRead || read) && (
              <>
                <Button
                  className="w-fit text-sm font-medium text-brand-700"
                  onClick={() => {
                    onMarkAsRead();
                    setRead(true);
                  }}
                  styleType="NONE"
                >
                  Mark as read
                </Button>
                <div className="size-[5px] rounded-full bg-gray-300" />
              </>
            )}
            <Button
              className="h-[22px] w-fit gap-2 text-nowrap rounded-full border border-gray-200 bg-white px-2 !text-label-md font-medium leading-[18px] text-gray-700"
              onClick={onInviteUser}
              styleType="NONE"
            >
              Invite User
              <ArrowIcon className="rotate-180" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
