import React, { FC, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Pool, PoolPlansTab } from '../../../types/pool-plans.types';
import { PlanStakeholder } from '../../../types/stakeholderTypes';
import { ScrollablePoolTable } from './PoolPlansScrollableTable/ScrollablePoolTable';
import { ScrollableStakeholderTable } from './PoolPlansScrollableTable/ScrollableStakeholderTable';
import { StickyPoolTable } from './PoolPlansStickyTable/StickyPoolsTable';
import { StickyStakeholderTable } from './PoolPlansStickyTable/StickyStakeholderTable';

type PoolPlansCommonTableHeaderProps = {
  isSortedByPools: boolean;
  pools: Pool[];
  stakeholders: PlanStakeholder[];
  selectedPool: string;
  selectedPlan: string;
  selectedTab: PoolPlansTab;
  setSelectedPool: (id: string) => void;
  setSelectedPlan: (id: string) => void;
  onEditClick: (id: string) => void;
  onViewClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
  onEditPlanClick: (id: string) => void;
  onViewPlanClick: (id: string) => void;
  onDeletePlanClick: (id: string) => void;
  onEditTerminationDateClick: (id: string) => void;
  onEditPlanTerminationDateClick: (id: string) => void;
};

export const PoolPlansCommonTable: FC<PoolPlansCommonTableHeaderProps> = ({
  isSortedByPools,
  selectedPool,
  selectedPlan,
  selectedTab,
  setSelectedPool,
  setSelectedPlan,
  pools,
  stakeholders,
  onEditClick,
  onViewClick,
  onEditPlanClick,
  onViewPlanClick,
  onDeleteClick,
  onDeletePlanClick,
  onEditTerminationDateClick,
  onEditPlanTerminationDateClick,
}) => {
  const [hasRightShadow, setHasRightShadow] = useState(false);
  const scrollableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableRef.current) {
        const { scrollLeft } = scrollableRef.current;
        const newHasRightShadow = scrollLeft > 0;
        setHasRightShadow(newHasRightShadow);
      }
    };

    const scrollable = scrollableRef.current;
    if (scrollable) {
      scrollable.addEventListener('scroll', handleScroll);
      handleScroll();

      return () => scrollable.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <div className="flex w-full">
      {isSortedByPools ? (
        <>
          <div
            className={twMerge(
              'z-10 h-full flex-shrink-0',
              hasRightShadow ? 'shadow-rightShadow' : '',
            )}
          >
            <StickyPoolTable
              pools={pools}
              selectedPlan={selectedPlan}
              selectedPool={selectedPool}
              selectedTab={selectedTab}
              setSelectedPlan={setSelectedPlan}
              setSelectedPool={setSelectedPool}
            />
          </div>
          <div className="h-full flex-grow overflow-x-auto" ref={scrollableRef}>
            <ScrollablePoolTable
              onDeleteClick={onDeleteClick}
              onDeletePlanClick={onDeletePlanClick}
              onEditClick={onEditClick}
              onEditPlanClick={onEditPlanClick}
              onEditPlanTerminationDateClick={onEditPlanTerminationDateClick}
              onEditTerminationDateClick={onEditTerminationDateClick}
              onViewClick={onViewClick}
              onViewPlanClick={onViewPlanClick}
              pools={pools}
              selectedPlan={selectedPlan}
              selectedPool={selectedPool}
              selectedTab={selectedTab}
            />
          </div>
        </>
      ) : (
        <>
          <div
            className={twMerge('flex-shrink-0', hasRightShadow ? 'z-10 shadow-rightShadow' : '')}
          >
            <StickyStakeholderTable
              poolsByStakeholders={stakeholders}
              selectedPlan={selectedPlan}
              selectedPool={selectedPool}
              setSelectedPlan={setSelectedPlan}
              setSelectedPool={setSelectedPool}
            />
          </div>
          <div className="flex-grow overflow-x-auto" ref={scrollableRef}>
            <ScrollableStakeholderTable
              onDeleteClick={onDeleteClick}
              onDeletePlanClick={onDeletePlanClick}
              onEditClick={onEditClick}
              onEditPlanClick={onEditPlanClick}
              onEditTerminationDateClick={onEditTerminationDateClick}
              onViewClick={onViewClick}
              onViewPlanClick={onViewPlanClick}
              poolsByStakeholders={stakeholders}
              selectedPlan={selectedPlan}
              selectedPool={selectedPool}
            />
          </div>
        </>
      )}
    </div>
  );
};
