import React, { FC, useMemo, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { twMerge } from 'tailwind-merge';

import { Snapshot } from '../../types/snapshot.types';
import { EmptyPositionGraph } from '../MyHoldings/Graphs/EmptyPositionGraph';
import { PoolGraphVariant } from './variables';

export const colors = {
  [PoolGraphVariant.EQUITY]: {
    strokeColors: ['#D2a9FA', '#A6F4C5'],
    fillColors: ['#E9D8FA', '#D1FADF'],
    activeColors: ['#6717b6', '#039855'],
  },
  [PoolGraphVariant.GRANTED]: {
    strokeColors: ['#FEDF89', '#B4D1FE'],
    fillColors: ['#FEF0C7', '#D1E3FF'],
    activeColors: ['#DC6803', '#2565C8'],
  },
};

type PoolGraphProps = {
  isDesktop: boolean;
  isTablet: boolean;
  variant: PoolGraphVariant;
  snapshot?: Snapshot;
};

export const PoolGraph: FC<PoolGraphProps> = ({ snapshot, isDesktop, isTablet, variant }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const equityGraphData = useMemo(() => {
    if (!snapshot) {
      return [];
    }
    const totalShares = snapshot.poolDilutedShares + snapshot.issuedShares;
    return [
      {
        name: 'Equity',
        percentage: parseFloat(((snapshot.issuedShares * 100) / (totalShares || 1)).toFixed(2)),
      },
      {
        name: 'Pools',
        percentage: parseFloat(
          ((snapshot.poolDilutedShares * 100) / (totalShares || 1)).toFixed(2),
        ),
      },
    ];
  }, [snapshot]);

  const grantedGraphData = useMemo(() => {
    if (!snapshot) {
      return [];
    }
    const ungrantedShares = snapshot.poolDilutedShares - snapshot.poolGrantedShares;

    return [
      {
        name: 'Ungranted Pool',
        percentage: parseFloat(
          ((ungrantedShares * 100) / (snapshot.poolDilutedShares || 1)).toFixed(2),
        ),
      },
      {
        name: 'Granted Pool',
        percentage: parseFloat(
          ((snapshot.poolGrantedShares * 100) / (snapshot.poolDilutedShares || 1)).toFixed(2),
        ),
      },
    ];
  }, [snapshot]);

  const handleCellClick = (index: number) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const data = variant === PoolGraphVariant.EQUITY ? equityGraphData : grantedGraphData;

  const isMobile = !isDesktop && !isTablet;

  return (
    <div className={twMerge('flex h-full', isDesktop || isTablet ? 'w-[50%]' : 'w-[100%]')}>
      <div
        className={twMerge('flex w-full items-center justify-center gap-9', isMobile && 'flex-col')}
      >
        <div className={twMerge(isTablet ? 'size-[130px]' : 'size-[214px]')}>
          {data.every((item) => item.percentage === 0) ? (
            <EmptyPositionGraph />
          ) : (
            <ResponsiveContainer height="100%" width="100%">
              <PieChart>
                <Pie
                  cx="50%"
                  cy="50%"
                  data={data}
                  dataKey="percentage"
                  innerRadius="50%"
                  outerRadius="100%"
                >
                  {data.map((entry, index) => (
                    <Cell
                      fill={
                        activeIndex === index
                          ? colors[variant].activeColors[
                              index % colors[variant].activeColors.length
                            ]
                          : colors[variant].fillColors[index % colors[variant].fillColors.length]
                      }
                      fillOpacity={1}
                      key={entry.name}
                      onClick={() => handleCellClick(index)}
                      stroke={
                        colors[variant].strokeColors[index % colors[variant].strokeColors.length]
                      }
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>
        <div
          className={twMerge(
            'flex max-w-[108px] flex-col gap-6',
            isMobile && 'max-w-full flex-row justify-center gap-4',
          )}
        >
          {data.map((item, index) => (
            <div className="flex w-[108px] flex-col gap-2" key={`${item.name}_${index}`}>
              <span
                className="text-label-md font-[600] text-gray-600"
                style={{
                  color: activeIndex !== null && activeIndex !== index ? '#98A2B3' : '#344054',
                }}
              >
                {item.name}
              </span>
              <div className="flex items-center gap-2">
                <div
                  className="h-[5px] w-3 rounded"
                  style={{
                    backgroundColor:
                      activeIndex === index
                        ? colors[variant].activeColors[index % colors[variant].activeColors.length]
                        : colors[variant].fillColors[index % colors[variant].fillColors.length],
                    border:
                      activeIndex === index
                        ? `1px solid ${colors[variant].activeColors[index % colors[variant].activeColors.length]}`
                        : `1px solid ${colors[variant].fillColors[index % colors[variant].fillColors.length]}`,
                  }}
                />
                <span
                  className="whitespace-nowrap text-sm font-[450] text-gray-700"
                  style={{
                    color:
                      activeIndex !== null
                        ? activeIndex === index
                          ? colors[variant].activeColors[
                              index % colors[variant].activeColors.length
                            ]
                          : '#98A2B3'
                        : '#344054',
                  }}
                >
                  {item.percentage}%
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
