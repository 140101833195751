import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { CloseIcon, CustomizeIcon, ExportIcon, SearchIcon } from '../../../assets/icons';
import { AlertDialogWrapper } from '../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { Input } from '../../../components/Input';
import { SnapshotHooks, useDebounce, useEventOutsideElement, useModalState } from '../../../hooks';
import { StringKey } from '../../../lang';
import { capTableStore } from '../../../storage/capTableStore';
import {
  Column,
  DetailsSortVariant,
  detailsSortVariantTitleMap,
  Snapshot,
  ViewBy,
} from '../../../types/snapshot.types';
import { DetailsCustomizeView } from './DetailsCustomizeView';
import { Table } from './TableVariants/Table';

export type TableProps = {
  visibleColumns: Column[];
  viewBy: Record<ViewBy, boolean>;
  companyId: string;
  valuationId: string;
  type: DetailsSortVariant;
  snapshotByDate?: Snapshot;
  isSelectedSnapshotByDate: boolean;
  search: string;
  setEmptySearchResult: (state: boolean) => void;
  isEmptySearchResult: boolean;
};

export type DetailsProps = {
  companyId: string;
  valuationId: string;
  className?: string;
  snapshotByDate?: Snapshot;
  isSelectedSnapshotByDate: boolean;
  snapshotId: string;
};

export type CustomView = Record<
  DetailsSortVariant,
  {
    viewBy: Record<ViewBy, boolean>;
    columns: Column[];
  }
>;

const customViewFieldList = Object.values(Column);

const defaultCustomView = {
  [DetailsSortVariant.BY_SHARE_CLASS]: {
    columns: customViewFieldList,
    viewBy: {
      [ViewBy.STAKEHOLDERS]: true,
      [ViewBy.TYPE]: true,
    },
  },
  [DetailsSortVariant.BY_STAKEHOLDER]: {
    columns: customViewFieldList,
    viewBy: {
      [ViewBy.STAKEHOLDERS]: true,
      [ViewBy.TYPE]: true,
    },
  },
};

export const DesktopDetails: FC<DetailsProps> = ({
  companyId,
  valuationId,
  className,
  snapshotByDate,
  isSelectedSnapshotByDate,
  snapshotId,
}) => {
  const [search, setSearch] = useState<null | string>(null);
  const [isEmptySearchResult, setEmptySearchResult] = useState(false);

  const [isOpenSearch, setOpenSearch] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const [isFocusedInput, setInputFocus] = useState(false);

  const [selectedDetailsSortVariant, setDetailsSortVariant] = useState<DetailsSortVariant>(
    DetailsSortVariant.BY_STAKEHOLDER,
  );
  const [customView, setCustomView] = useState<CustomView>({
    [DetailsSortVariant.BY_SHARE_CLASS]:
      capTableStore.get()?.[DetailsSortVariant.BY_SHARE_CLASS] ||
      defaultCustomView[DetailsSortVariant.BY_SHARE_CLASS],
    [DetailsSortVariant.BY_STAKEHOLDER]:
      capTableStore.get()?.[DetailsSortVariant.BY_STAKEHOLDER] ||
      defaultCustomView[DetailsSortVariant.BY_STAKEHOLDER],
  });

  const { mutateExport } = SnapshotHooks.useExport({ companyId, snapshotId });

  const { isOpen, toggler, handleOpenModal, handleCloseModal, handleSuccessModal } =
    useModalState<CustomView>({
      onSuccess: (viewSettings) => {
        if (!viewSettings) return;
        setCustomView(viewSettings);
      },
    });

  const { debounceCallback } = useDebounce({
    callback: () => {
      invalidateQuery();
    },
  });

  useEffect(() => {
    if (!search) {
      setEmptySearchResult(false);
    }
  }, [search]);

  const handleSearch = useCallback(
    (value: string) => {
      setSearch(value);
      debounceCallback(value);
    },
    [debounceCallback],
  );
  useEventOutsideElement(inputRef, 'click', () => !search && setOpenSearch(false));

  const invalidateQuery = useCallback(() => {}, []);

  return (
    <>
      <AlertDialogWrapper control={{ open: isOpen, onOpenChange: toggler }}>
        <DetailsCustomizeView
          onCancel={handleCloseModal}
          onSuccess={handleSuccessModal}
          selectedForTab={selectedDetailsSortVariant}
          viewSettings={customView}
        />
      </AlertDialogWrapper>

      <div className={twMerge('flex flex-col rounded-md px-4 pb-4 shadow-sm', className)}>
        <span className="p-4 text-base font-semibold text-gray-700">Details</span>
        <div className="flex h-fit w-full flex-col rounded-md shadow-sm">
          <div className="flex w-full items-center justify-between p-4">
            <div className="flex h-9 divide-x divide-gray-200 rounded border border-gray-200">
              {detailsSortVariantTitleMap.map(([filterKey, filter]) => (
                <Button
                  className={twMerge(
                    'h-full w-fit rounded-none px-4 text-sm font-[450] text-[#858593]',
                    selectedDetailsSortVariant === filterKey &&
                      'bg-brand-25 text-sm font-[550] text-[#172335]',
                  )}
                  key={filterKey}
                  onClick={() => setDetailsSortVariant(filterKey)}
                  styleType="NONE"
                >
                  {filter}
                </Button>
              ))}
            </div>
            <div className="relative flex gap-3">
              <div className="flex items-center justify-end" ref={inputRef}>
                <Input
                  onBlur={() => setInputFocus(false)}
                  onChange={handleSearch}
                  onFocus={() => {
                    setInputFocus(true);
                  }}
                  placeholder={search ? '' : <AppFormattedMessage id={StringKey.SEARCH} />}
                  value={search || ''}
                  wrapperClassName={twMerge(
                    'transition-all duration-500 w-[200px] overflow-hidden max-h-9',
                    isOpenSearch ? 'delay-500' : 'w-0',
                  )}
                />
                {isOpenSearch && typeof search === 'string' && (
                  <div
                    className="absolute mr-10 cursor-pointer"
                    onClick={() => {
                      setSearch('');
                      invalidateQuery();
                    }}
                  >
                    <CloseIcon />
                  </div>
                )}
                <Button
                  className={twMerge(
                    'size-9 border-[1px] border-gray-100 bg-gray-50 transition-all duration-300',
                    isOpenSearch
                      ? 'h-9 rounded-l-none rounded-br-none border-x-0 border-t-0 border-b-black'
                      : 'delay-500',
                    isFocusedInput && 'border-b-[2px] border-b-brand-700 transition-none',
                  )}
                  onClick={() => {
                    setOpenSearch((prev) => !prev);
                    setSearch(null);
                    invalidateQuery();
                  }}
                >
                  <SearchIcon iconColor={isFocusedInput ? '#2565C8' : '#344054'} />
                </Button>
              </div>
              <Button
                className="flex size-9 gap-1 border-[1px] border-gray-100 bg-gray-25 px-3 py-[6px] lg:w-fit"
                onClick={() =>
                  mutateExport({
                    viewByColumns: Object.values(DetailsSortVariant).reduce(
                      (acc, curr) => ({ ...acc, [curr]: customView[curr].viewBy }),
                      {},
                    ) as Record<DetailsSortVariant, Record<ViewBy, boolean>>,

                    viewColumns: Object.values(DetailsSortVariant).reduce(
                      (acc, curr) => ({ ...acc, [curr]: customView[curr].columns }),
                      {},
                    ) as Record<DetailsSortVariant, Column[]>,
                  })
                }
              >
                <ExportIcon className="rotate-180" />
                <span className="text-sm font-[450] text-gray-700 max-lg:hidden">
                  <AppFormattedMessage id={StringKey.EXPORT} />
                </span>
              </Button>

              <Button
                className="flex size-9 gap-1 border-[1px] border-gray-100 bg-gray-25 px-3 py-[6px] lg:w-fit"
                onClick={handleOpenModal}
              >
                <CustomizeIcon />
                <span className="text-sm font-[450] text-gray-700 max-lg:hidden">
                  Customize View
                </span>
              </Button>
            </div>
          </div>
          <div className="w-full overflow-x-auto">
            <Table
              companyId={companyId}
              isEmptySearchResult={isEmptySearchResult}
              isSelectedSnapshotByDate={isSelectedSnapshotByDate}
              search={search || ''}
              setEmptySearchResult={setEmptySearchResult}
              snapshotByDate={snapshotByDate}
              type={selectedDetailsSortVariant}
              valuationId={valuationId}
              viewBy={customView[selectedDetailsSortVariant].viewBy}
              visibleColumns={customView[selectedDetailsSortVariant].columns}
            />
          </div>
        </div>
      </div>
    </>
  );
};
