import React, { FC, useCallback, useEffect } from 'react';
import {
  Control,
  useFieldArray,
  UseFormResetField,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { CalendarIcon, CloseIcon } from '../../../../../assets/icons';
import Button from '../../../../../components/Button';
import { FormInput } from '../../../../../components/Input';
import { useFormat } from '../../../../../hooks';
import { SharePlanType } from '../../../../../types/pool-plans.types';
import { DatePicker } from '../../../PoolForm/DatePicker';
import { GrantCombobox, StakeholderCombobox, TableHeaderItem } from '../FormComponents';
import { FormSchema } from '../validation';

export type PhantomProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  resetField: UseFormResetField<FormSchema>;
};

export const Phantom: FC<PhantomProps> = ({ control, setFormData, companyId, resetField }) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const { format } = useFormat();

  const {
    fields: phantomItemsFields,
    append: phantomItemsAppend,
    remove: phantomItemsRemove,
  } = useFieldArray({
    control,
    name: 'stepOne.phantom.phantomItems',
  });

  const handleRemovePhantomItems = useCallback(
    (index: number) => {
      phantomItemsRemove(index);
    },
    [phantomItemsRemove],
  );

  const handleAppendPhantomItems = useCallback(() => {
    const defaultDate = new Date();
    phantomItemsAppend({
      stakeholder: { id: '', fullName: '' },
      grant: { id: '', exercisedCount: 0, vestedSharesCount: 0 },
      date: defaultDate,
      sharePrice: 0,
      phantomShares: 0,
    });
  }, [phantomItemsAppend]);

  const handleStakeholderChange = (index: number) => {
    resetField(`stepOne.phantom.phantomItems.${index}.grant`);
  };

  const showRemoveButton = phantomItemsFields.length > 1;

  const calculateBalance = useCallback(
    (index: number) => {
      const vestedShares = stepOne?.phantom?.phantomItems?.[index]?.grant?.vestedSharesCount || 0;
      const exercisedShares = stepOne?.phantom?.phantomItems?.[index]?.grant?.exercisedCount || 0;
      const value = stepOne?.phantom?.phantomItems?.[index]?.phantomShares || 0;
      const balance = Math.max(vestedShares - exercisedShares - value, 0);

      return balance.toLocaleString('en-US');
    },
    [stepOne?.phantom?.phantomItems],
  );

  useEffect(() => {
    if (phantomItemsFields.length === 0) {
      handleAppendPhantomItems();
    }
  }, [handleAppendPhantomItems, phantomItemsFields.length]);

  return (
    <>
      <div className="flex w-[1340px] flex-col rounded-lg border-[1px] border-gray-100 bg-white">
        <div
          className={twMerge(
            'grid',
            showRemoveButton
              ? 'grid-cols-[repeat(6,_minmax(199px,_1fr))_repeat(1,48px)]'
              : 'grid-cols-[repeat(6,_minmax(199px,_1fr))]',
          )}
        >
          <TableHeaderItem showTooltip text="Stakeholder" />
          <TableHeaderItem showTooltip text="Grant" />
          <TableHeaderItem showTooltip text="Exercise date" />
          <TableHeaderItem text="Share price" variant="dark" />
          <TableHeaderItem text="Phantom Shares" variant="dark" />
          <TableHeaderItem text="Total Payout" variant="dark" />
          {showRemoveButton && <TableHeaderItem text="" />}
        </div>
        {phantomItemsFields.map((field, index) => (
          <div
            className={twMerge(
              'grid border-b-[1px] border-b-gray-100',
              showRemoveButton
                ? 'grid-cols-[repeat(6,_minmax(199px,_1fr))_repeat(1,48px)]'
                : 'grid-cols-[repeat(6,_minmax(199px,_1fr))]',
            )}
            key={field.id}
          >
            <div className="px-4 pb-10 pt-7">
              <StakeholderCombobox
                companyId={companyId}
                control={control}
                handleStakeholderChange={() => handleStakeholderChange(index)}
                name={`stepOne.phantom.phantomItems.${index}.stakeholder`}
              />
            </div>
            <div className="px-4 pb-10 pt-7">
              <GrantCombobox
                companyId={companyId}
                control={control}
                name={`stepOne.phantom.phantomItems.${index}.grant`}
                sharePlanType={SharePlanType.PHANTOM}
                stakeholderId={stepOne?.phantom?.phantomItems?.[index]?.stakeholder?.id || ''}
              />
            </div>
            <div className="px-4 pb-10 pt-7">
              <DatePicker
                onSelect={(date) => setFormData(`stepOne.phantom.phantomItems.${index}.date`, date)}
                wrapperClassName="w-full"
              >
                <FormInput
                  autoComplete="off"
                  control={control}
                  customValue={(value) => {
                    return value && format(value, 'dd/MM/yyyy');
                  }}
                  icon={<CalendarIcon className="size-5" iconColor="#344054" />}
                  inputClassName="h-10"
                  name={`stepOne.phantom.phantomItems.${index}.date`}
                  wrapperClassName="w-full"
                />
              </DatePicker>
            </div>
            <div className="flex px-4 pb-10 pt-7">
              <FormInput
                control={control}
                iconBeforeInput={<span className="text-sm font-[450] text-gray-700">$</span>}
                inputClassName="h-10"
                name={`stepOne.phantom.phantomItems.${index}.sharePrice`}
                numberOnly
                shouldFormatNumber
                wrapperClassName="w-full"
              />
            </div>
            <div className="flex flex-col gap-2 px-4 pb-4 pt-7">
              <FormInput
                control={control}
                inputClassName="h-10"
                name={`stepOne.phantom.phantomItems.${index}.phantomShares`}
                numberOnly
                shouldFormatNumber
                wrapperClassName="w-full"
              />
              <span className="text-xs font-[450] text-gray-600">
                Available: {calculateBalance(index)}
              </span>
            </div>
            <div className="flex flex-col gap-2 px-4 pb-10 pt-7">
              <div className="max-w-[199px] rounded-t-[4px] bg-gray-50 px-3 py-2">
                <span className="block w-full overflow-hidden truncate text-ellipsis whitespace-nowrap text-sm font-[550] text-forest-600">
                  ${' '}
                  {(
                    (stepOne?.phantom?.phantomItems?.[index]?.phantomShares || 0) *
                    (stepOne?.phantom?.phantomItems?.[index]?.sharePrice || 0)
                  ).toLocaleString('en-US')}
                </span>
              </div>
            </div>
            {showRemoveButton && (
              <div>
                <Button
                  className="flex h-full w-full items-center justify-center"
                  onClick={() => handleRemovePhantomItems(index)}
                  styleType="NONE"
                >
                  <CloseIcon />
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>

      {phantomItemsFields.length < 10 && (
        <div className="sticky bottom-[76px] z-10 w-full bg-white">
          <Button
            className="w-fit px-4 py-2 text-sm font-[450] text-brand-700 underline disabled:border-transparent disabled:bg-transparent"
            onClick={handleAppendPhantomItems}
            styleType="NONE"
          >
            + Add Exercising
          </Button>
        </div>
      )}
    </>
  );
};
