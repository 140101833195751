import React, { FC, ReactNode } from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import {
  AddContributorIcon,
  CloseCircleIconSmall,
  CrossIcon,
  EditIcon,
  GrantAccessIcon,
  RevokeAccessIcon,
  SendInviteIcon,
  TrashBinIcon,
} from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { Checkbox } from '../../../components/Checkbox';
import { Sheet, SheetContent } from '../../../components/Sheet';
import { useSelectedCompany } from '../../../hooks';
import { StringKey } from '../../../lang';
import { Stakeholder, StakeholderType } from '../../../types/stakeholderTypes';
import { getStakeholderCommonActions } from '../StakeholderPermissions';
import { SelectedStakeholder } from '../Stakeholders';

const mergeTrueFields = <T extends { [key: string]: boolean }>(arr: T[]): Partial<T> => {
  let result: Partial<T> = {};

  if (arr.length === 0) {
    return result;
  }

  result = { ...arr[0] };

  Object.keys(result).forEach((key) => {
    const allTrue = arr.every((obj) => obj[key] === true);
    if (!allTrue) {
      delete result[key];
    }
  });

  return result;
};

export type BulkActionProps = {
  title: ReactNode;
  plural?: string;
  icon: ReactNode;
  onClick: () => void;
  className?: string;
};

const BulkAction: FC<BulkActionProps> = ({ icon, onClick, title, className, plural }) => (
  <Button
    className={twMerge(
      'flex h-5 gap-2 text-nowrap pl-3 text-sm font-[450] text-gray-700',
      className,
    )}
    onClick={onClick}
    styleType="NONE"
  >
    {icon}
    {title}
    {plural}
  </Button>
);

const useBulkStakeholderActions = (stakeholders: SelectedStakeholder[], isDemo: boolean) => {
  const data = stakeholders.map((stakeholder) => {
    const possibleActions = getStakeholderCommonActions({ ...stakeholder, isDemo });
    return possibleActions;
  });

  return mergeTrueFields(data);
};

type ActionProps = { id: string[]; onSuccess: () => void };

export type BulkActionsModalProps = {
  handleSuccess: () => void;
  selectedStakeholderIds: string[];
  invalidateQuery: () => void;
  onRemoveSelectAll: () => void;
  stakeholders: Stakeholder[];
  isOpenModal: boolean;
  handleDelete: (props: ActionProps) => void;
  handleMakeContributor: (props: ActionProps) => void;
  handleRemoveContributorRole: (props: ActionProps) => void;
  handleResendInvitation: (props: ActionProps) => void;
  handleCancelInvitation: (props: ActionProps) => void;
  handleRevokeAccess: (props: ActionProps) => void;
  handleOpenTerminateModal: (data?: Stakeholder[] | undefined) => void;
  handleOpenEditTerminationModal: (data?: Stakeholder[] | undefined) => void;
  handleGrantAccess: (props: ActionProps) => void;
  handleSendInvitation: (props: ActionProps) => void;
};

const BulkActionsModal: FC<BulkActionsModalProps> = ({
  isOpenModal,
  selectedStakeholderIds,
  stakeholders,
  handleCancelInvitation,
  handleDelete,
  handleGrantAccess,
  handleMakeContributor,
  handleRemoveContributorRole,
  handleResendInvitation,
  handleRevokeAccess,
  handleSendInvitation,
  handleOpenTerminateModal,
  handleOpenEditTerminationModal,
  handleSuccess,
  onRemoveSelectAll,
  invalidateQuery,
}) => {
  const { selectedCompany } = useSelectedCompany();
  const selectedStakeholders = stakeholders.filter(({ id }) => selectedStakeholderIds.includes(id));
  const isPlural = selectedStakeholderIds.length > 1;
  const accountId = selectedCompany?.stakeholder?.id || '';
  const isAllSelectedStakeholdersTerminated = selectedStakeholders.every(
    (stakeholder) => stakeholder.isTerminated,
  );
  const isSomeSelectedStakeholdersTerminated = selectedStakeholders.some(
    (stakeholder) => stakeholder.isTerminated,
  );

  const {
    canDelete,
    canInvite,
    canTerminate,
    cancelInvitation,
    grantAccess,
    makeContributor,
    resendInvitation,
    revokeAccess,
    revokeContributor,
    canEditTerminate,
  } = useBulkStakeholderActions(selectedStakeholders, selectedCompany?.isDemo || false);

  const hasInvalidStakeholders = selectedStakeholders.some(
    (stakeholder) =>
      stakeholder.type !== StakeholderType.EMPLOYEE &&
      stakeholder.type !== StakeholderType.FOUNDER &&
      stakeholder.type !== StakeholderType.ADVISOR,
  );

  return (
    <Sheet modal={false} open={isOpenModal}>
      <SheetContent
        className="m-auto flex h-fit w-fit justify-center border-transparent bg-transparent p-0 pb-6 shadow-none"
        side="BOTTOM"
      >
        <div className="flex h-10 items-center justify-center divide-x divide-gray-100 rounded-[40px] bg-white px-4 py-2 shadow-2xl">
          <div
            className="flex h-5 items-center gap-3 text-nowrap pr-3 text-sm font-[450] text-gray-700"
            onClick={onRemoveSelectAll}
          >
            <Checkbox checked={selectedStakeholderIds.length > 0} />
            <AppFormattedMessage
              id={StringKey.ROWS_SELECTED}
              values={{
                count: selectedStakeholderIds.length,
              }}
            />
          </div>
          {canInvite && (
            <BulkAction
              icon={<SendInviteIcon />}
              onClick={() => {
                handleSendInvitation({
                  id: selectedStakeholderIds,
                  onSuccess: () => {
                    invalidateQuery();
                    toast.success(
                      isPlural
                        ? 'Stakeholders successfully invited'
                        : 'Stakeholder successfully invited',
                    );
                    handleSuccess();
                  },
                });
              }}
              title={isPlural ? 'Invite Stakeholders' : 'Invite Stakeholder'}
            />
          )}

          {resendInvitation && (
            <BulkAction
              icon={<SendInviteIcon className="size-4" />}
              onClick={() => {
                handleResendInvitation({
                  id: selectedStakeholderIds,
                  onSuccess: () => {
                    invalidateQuery();
                    toast.success(
                      isPlural
                        ? 'Invitations successfully resent'
                        : 'Invitation successfully resent',
                    );
                    handleSuccess();
                  },
                });
              }}
              title={
                isPlural ? (
                  'Resend Invitations'
                ) : (
                  <AppFormattedMessage id={StringKey.RESEND_INVITATION} />
                )
              }
            />
          )}
          {grantAccess && (
            <BulkAction
              icon={<GrantAccessIcon className="size-4" />}
              onClick={() => {
                handleGrantAccess({
                  id: selectedStakeholderIds,
                  onSuccess: () => {
                    invalidateQuery();
                    toast.success('Access successfully granted');
                    handleSuccess();
                  },
                });
                handleSuccess();
              }}
              title={<AppFormattedMessage id={StringKey.GRANT_ACCESS} />}
            />
          )}

          {makeContributor && (
            <BulkAction
              icon={<AddContributorIcon className="size-4" />}
              onClick={() => {
                handleMakeContributor({
                  id: selectedStakeholderIds,
                  onSuccess: () => {
                    invalidateQuery();
                    toast.success(
                      isPlural
                        ? 'Contributor roles successfully granted'
                        : 'Contributor role successfully granted',
                    );
                    handleSuccess();
                  },
                });
                handleSuccess();
              }}
              title={
                <>
                  <AppFormattedMessage id={StringKey.MAKE_CONTRIBUTOR} />
                  {isPlural ? 's' : ''}
                </>
              }
            />
          )}
          {canEditTerminate && isAllSelectedStakeholdersTerminated && !hasInvalidStakeholders && (
            <BulkAction
              icon={<EditIcon />}
              onClick={() => {
                handleOpenEditTerminationModal(selectedStakeholders);
                handleSuccess();
              }}
              title="Edit Termination"
            />
          )}
          {cancelInvitation && (
            <BulkAction
              className="text-fireside-500"
              icon={<CloseCircleIconSmall className="size-4" iconColor="#F04438" />}
              onClick={() => {
                handleCancelInvitation({
                  id: selectedStakeholderIds,
                  onSuccess: () => {
                    invalidateQuery();
                    toast.success(
                      isPlural
                        ? 'Invitations successfully cancelled'
                        : 'Invitation successfully cancelled',
                    );
                    handleSuccess();
                  },
                });
                handleSuccess();
              }}
              title={
                <>
                  <AppFormattedMessage id={StringKey.CANCEL_INVITATION} />
                  {isPlural ? 's' : ''}
                </>
              }
            />
          )}
          {revokeAccess && !selectedStakeholderIds.includes(accountId) && (
            <BulkAction
              className="text-fireside-500"
              icon={<RevokeAccessIcon className="size-4" iconColor="#F04438" />}
              onClick={() => {
                handleRevokeAccess({
                  id: selectedStakeholderIds,
                  onSuccess: () => {
                    invalidateQuery();
                    toast.success(
                      isPlural
                        ? 'Stakeholders access successfully revoked'
                        : 'Stakeholder access successfully revoked',
                    );
                    handleSuccess();
                  },
                });
                handleSuccess();
              }}
              title={<AppFormattedMessage id={StringKey.REVOKE_ACCESS} />}
            />
          )}
          {revokeContributor && !selectedStakeholderIds.includes(accountId) && (
            <BulkAction
              className="text-fireside-500"
              icon={<RevokeAccessIcon className="size-4" iconColor="#F04438" />}
              onClick={() => {
                handleRemoveContributorRole({
                  id: selectedStakeholderIds,
                  onSuccess: () => {
                    invalidateQuery();
                    toast.success(
                      isPlural
                        ? 'Contributor roles successfully revoked'
                        : 'Contributor role successfully revoked',
                    );
                    handleSuccess();
                  },
                });
                handleSuccess();
              }}
              plural={'s'}
              title={
                isPlural ? (
                  <AppFormattedMessage id={StringKey.REVOKE_CONTRIBUTOR_ROLE} />
                ) : (
                  <AppFormattedMessage id={StringKey.REVOKE_CONTRIBUTOR_ROLE} />
                )
              }
            />
          )}
          {canTerminate && !isSomeSelectedStakeholdersTerminated && !hasInvalidStakeholders && (
            <BulkAction
              className="text-fireside-500"
              icon={<CrossIcon className="size-4" iconColor="#F04438" />}
              onClick={() => {
                handleOpenTerminateModal(selectedStakeholders);
                handleSuccess();
              }}
              plural={'s'}
              title={
                isPlural ? (
                  <AppFormattedMessage id={StringKey.TERMINATE_STAKEHOLDER} />
                ) : (
                  <AppFormattedMessage id={StringKey.TERMINATE_STAKEHOLDER} />
                )
              }
            />
          )}
          {canDelete && !selectedStakeholderIds.includes(accountId) && (
            <BulkAction
              className="text-fireside-500"
              icon={<TrashBinIcon className="size-4" iconColor="#F04438" />}
              onClick={() => {
                handleDelete({
                  id: selectedStakeholderIds,
                  onSuccess: () => {
                    invalidateQuery();
                    toast.success(
                      isPlural ? 'Successfully deleted' : 'Stakeholder successfully deleted',
                    );
                    handleSuccess();
                  },
                });
                handleSuccess();
              }}
              title={
                <>
                  <AppFormattedMessage
                    id={StringKey.DELETE_STAKEHOLDER}
                    values={{ count: selectedStakeholderIds.length }}
                  />
                </>
              }
            />
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default BulkActionsModal;
