import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { EventUnion } from '../../types/events.types';
import { useAppQuery } from '../useAppQuery';
import { useAppMutation } from '../useAppMutation';

export const useMarkResolved = ({
  companyId,
  onSuccess,
}: {
  companyId: string;
  onSuccess?: () => void;
}) => {
  const { invalidateQueries } = useQueryClient();
  const { mutate: markAsResolved } = useAppMutation(
    [QueryKey.MARK_NOTIFICATION_AS_RESOLVED, companyId],
    {
      mutationFn: (notificationId: string) =>
        ApiService.post({
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'notifications', notificationId, 'mark-resolved'],
        }),
      onSuccess: () => {
        onSuccess?.();
        invalidateQueries({ queryKey: [QueryKey.GET_NOTIFICATIONS] });
      },
    },
  );

  return {
    markAsResolved,
  };
};
