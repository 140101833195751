import React, { FC, ReactNode, useCallback, useState } from 'react';

import { CloseModalButton } from '../../../components/CloseModalButton';
import { Sheet, SheetContent } from '../../../components/Sheet';
import { useReactForm, ValuationHooks, VestingTaskHooks } from '../../../hooks';
import { SharePlanType, sharePlanTypeTitle } from '../../../types/pool-plans.types';
import { StepOne } from './StepOne';
import { ChildrenFormProps } from './type';
import { FormSchema, formSchema } from './validation';

export type ExerciseFormProps = {
  isOpen: boolean;
  handleClose: () => void;
  companyId: string;
  invalidateQuery: () => void;
  type: SharePlanType;
  sharePlanId: string;
};

const formSteps: ((props: ChildrenFormProps) => ReactNode)[] = [(props) => <StepOne {...props} />];

export const ExerciseForm: FC<ExerciseFormProps> = ({
  isOpen,
  companyId,
  handleClose,
  invalidateQuery,
  type,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    getFieldState,
    setError,
    clearErrors,
    resetField,
    formState: { errors },
  } = useReactForm({
    schema: formSchema,
  });

  const { create: exercise } = VestingTaskHooks.useExercise();
  const { create: expire } = VestingTaskHooks.useExpire();
  const { valuation } = ValuationHooks.useCurrent({ companyId });

  const submitHandler = useCallback((data: FormSchema) => {
    const { stepOne } = data;
  }, []);

  const handleCloseModal = useCallback(() => {
    reset();
    handleClose();
    setCurrentStep(1);
  }, [reset, handleClose]);

  const handleNextStep = useCallback(() => {
    const nextStep = currentStep + 1;
    if (nextStep === Object.keys(formSteps).length + 1) {
      handleSubmit(submitHandler)();
      return;
    }
    if (nextStep > Object.keys(formSteps).length) return;

    setCurrentStep(nextStep);
  }, [currentStep, handleSubmit, submitHandler]);

  const handlePrevStep = useCallback(() => {
    const prevStep = currentStep - 1;
    if (prevStep < 1) return;
    setCurrentStep(prevStep);
  }, [currentStep]);

  return (
    <Sheet open={isOpen}>
      <SheetContent
        className="h-fit max-h-[720px] w-[90%] max-w-[1422px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="CENTER"
      >
        <div className="flex h-full w-full flex-col gap-8 rounded-2xl bg-gray-25 p-8">
          <div className="flex h-fit w-full items-center justify-between">
            <span className="text-xl font-semibold text-gray-700">
              Request Exercise - {sharePlanTypeTitle[type]}
            </span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>
          <div className="flex h-full flex-col gap-8">
            {formSteps[currentStep - 1]({
              companyId,
              formData: getValues,
              nextFormStep: handleNextStep,
              prevFormStep: handlePrevStep,
              setFormData: setValue,
              control,
              clearErrors,
              handleCloseModal,
              setError,
              filedState: getFieldState,
              errors,
              type,
              resetField,
              reset,
              valuation,
            })}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
