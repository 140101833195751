import { FC } from 'react';
import { Control, UseFormResetField, UseFormSetValue, useWatch } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { ListIcon } from '../../../../../../assets/icons';
import { EmptySearchList } from '../../../../../../components/EmptySearchList';
import Loader from '../../../../../../components/Loader';
import { SharePlan as SharePlanHooks } from '../../../../../../hooks';
import { Valuation } from '../../../../../../types/events.types';
import { SharePlan } from '../../../../../../types/pool-plans.types';
import { ExerciseModalItem } from '../../../ExerciseModal';
import { FormSchema } from '../../validation';
import { Hurdle } from './Hurdle';

export type ApproveHurdlePlanListProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  resetField: UseFormResetField<FormSchema>;
  valuation: Valuation;
};

type ApproveHurdlePlanListItemProps = {
  sharePlan: SharePlan;
  companyId: string;
  setSelectedHurdlePlanId: (id: string) => void;
};

const ApproveHurdlePlanListItem: FC<ApproveHurdlePlanListItemProps> = ({
  companyId,
  sharePlan: { id, hardleValue, name },
  setSelectedHurdlePlanId,
}) => {
  const { approveHurdleGrants } = SharePlanHooks.useSharePlanGrantItems({
    companyId,
    sharePlanId: id,
  });
  return (
    <ExerciseModalItem
      description={
        <>
          <span>Hurdle Value: ${hardleValue?.toLocaleString('en-US')}</span>
          <span className="size-[3px] w-full bg-gray-300" />
          <span>{approveHurdleGrants.length} grants associated to this plan</span>
        </>
      }
      onClick={() => setSelectedHurdlePlanId(id)}
      title={name}
    >
      <ListIcon className="-scale-x-100" />
    </ExerciseModalItem>
  );
};

export const ApproveHurdlePlanList: FC<ApproveHurdlePlanListProps> = ({
  companyId,
  setFormData,
  control,
  resetField,
  valuation,
}) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const { approveHurdleSharePlans, isLoading } = SharePlanHooks.useApproveHurdleSharePlans({
    companyId,
  });

  if (isLoading) return <Loader />;

  if (!stepOne?.hurdle?.planId)
    return (
      <div
        className={twMerge(
          'flex h-fit max-h-[150px] w-full flex-col divide-y divide-gray-200 overflow-y-auto rounded-xl bg-gray-50 px-4',
          approveHurdleSharePlans.length === 0 && 'centered max-h-[350px]',
        )}
      >
        {approveHurdleSharePlans.length === 0 ? (
          <EmptySearchList
            className="my-10"
            customDescriptionText=""
            customTitleText="You have no plans for approve"
          />
        ) : (
          approveHurdleSharePlans.map((sharePlan) => (
            <ApproveHurdlePlanListItem
              companyId={companyId}
              key={sharePlan.id}
              setSelectedHurdlePlanId={(id) => setFormData('stepOne.hurdle.planId', id)}
              sharePlan={sharePlan}
            />
          ))
        )}
      </div>
    );

  return (
    <Hurdle
      companyId={companyId}
      control={control}
      resetField={resetField}
      setFormData={setFormData}
      valuation={valuation}
    />
  );
};
