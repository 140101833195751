import { FC, useEffect } from 'react';

import { useLocale } from '../../../../hooks';
import { stakeholderTypeTranslation } from '../../../../translations/stakeholderTranslation';
import { Column, DetailsSortVariant } from '../../../../types/snapshot.types';
import { StakeholderType } from '../../../../types/stakeholderTypes';
import { filterByAllFields } from '../../../../utils/filterByAllFields';
import { toRound } from '../../../../utils/getRoundedNumber';
import { toNumber } from '../../../../utils/toNumber';
import { ShareClassTableItem } from '../TableVariants/Items/ShareClassTableItem';
import { StakeholderTableItem } from '../TableVariants/Items/StakeholderTableItem';
import { SnapshotViewByProps } from './type';

export const SnapshotViewByAll: FC<SnapshotViewByProps> = ({
  snapshot: { stats },
  viewBy,
  visibleColumns,
  type,
  search,
  setEmptySearchResult,
  dynamicShareClassColumns,
}) => {
  const { messagesLocale } = useLocale();

  const stakeholdersFilteredData = filterByAllFields(stats.stakeholders, search);
  const stakeholderTypesFilteredData = filterByAllFields(stats.stakeholdersTypes, search);
  const shareClassesFilteredData = filterByAllFields(stats.shareClasses, search);

  useEffect(() => {
    if (
      (stakeholderTypesFilteredData.length === 0 && type === DetailsSortVariant.BY_STAKEHOLDER) ||
      (shareClassesFilteredData.length === 0 && type === DetailsSortVariant.BY_SHARE_CLASS)
    ) {
      setEmptySearchResult(true);
    }
  }, [
    setEmptySearchResult,
    shareClassesFilteredData.length,
    stakeholderTypesFilteredData.length,
    type,
  ]);

  //// NEED TO REFACTOR !! NEED TO ADD CORRECT BACKEND (ENDPOINTS FOR ALL VIEWS IN DETAILS TABLE)
  switch (type) {
    case DetailsSortVariant.BY_STAKEHOLDER:
      const issuedTotal =
        stakeholderTypesFilteredData.reduce((acc, curr) => (acc += curr.issuedPercentage || 0), 0) *
        100;

      const dilutedTotal =
        stakeholderTypesFilteredData.reduce(
          (acc, curr) => (acc += curr.dilutedPercentage || 0),
          0,
        ) * 100;

      const votingTotal =
        stakeholderTypesFilteredData.reduce((acc, curr) => (acc += curr.votingPercentage || 0), 0) *
        100;

      const noIssuedTotal = stakeholderTypesFilteredData.reduce(
        (acc, curr) => (acc += curr.issued || 0),
        0,
      );
      const dilutedSharesTotal = stakeholderTypesFilteredData.reduce(
        (acc, curr) => (acc += curr.diluted || 0),
        0,
      );

      const votingRightTotal = stakeholderTypesFilteredData.reduce(
        (acc, curr) => (acc += curr.votingRight || 0),
        0,
      );

      const shareClassesTotal = stats.shareClasses
        .filter(({ name }) => name !== 'Common Stock')
        .map(({ name }) =>
          stats.stakeholders.reduce(
            (acc, curr) =>
              (acc += filterByAllFields(curr.shareClasses, search)
                .filter(({ name: stakeholderShareClassName }) => name === stakeholderShareClassName)
                .reduce((acc, curr) => (acc += curr.shares), 0)),
            0,
          ),
        );

      const totalShares = stakeholderTypesFilteredData.reduce(
        (stakeholdersAcc, { shareClasses }) =>
          stakeholdersAcc +
          filterByAllFields(shareClasses, search).reduce(
            (shareClassAcc, { shares }) => shareClassAcc + toNumber(shares),
            0,
          ),
        0,
      );

      const totalShareClasses = stakeholderTypesFilteredData.reduce(
        (stakeholdersAcc, { shareClasses }) =>
          stakeholdersAcc + filterByAllFields(shareClasses, search).length,
        0,
      );

      const averageSharePrice = toNumber(totalShares / totalShareClasses);

      const commonStockShareClassTotal = stakeholdersFilteredData.reduce(
        (acc, curr) =>
          (acc += filterByAllFields(curr.shareClasses, search)
            .filter(({ name }) => name === 'Common Stock')
            .reduce((acc, curr) => (acc += curr.shares), 0)),
        0,
      );

      const investmentTotal = 0;
      return (
        <>
          {stakeholderTypesFilteredData
            .filter(
              (stakeholder) =>
                stakeholder.shareClasses.reduce(
                  (sharesTotal, { shares }) => sharesTotal + shares,
                  0,
                ) > 0,
            )
            .map(
              (
                {
                  diluted,
                  dilutedPercentage,
                  issued,
                  issuedPercentage,
                  type,
                  votingPercentage,
                  shareClasses: stakeholderShareClasses,
                  votingRight,
                },
                i,
              ) => {
                const shareClasses = filterByAllFields(stakeholderShareClasses, search);

                const otherShareClasses = dynamicShareClassColumns.map(
                  (columnsName) =>
                    stakeholderShareClasses.find(({ name }) => name === columnsName)?.shares || 0,
                );
                return (
                  <StakeholderTableItem
                    fields={{
                      [Column.NAME]: messagesLocale[stakeholderTypeTranslation[type]],
                      [Column.STAKEHOLDER_NAME]: null,
                      [Column.TYPE]: null,
                      [Column.ISSUED]: toRound(issuedPercentage * 100),
                      [Column.DILUTED]: toRound(dilutedPercentage * 100),
                      [Column.VOTING]: toRound(votingPercentage * 100),
                      [Column.NO_ISSUED]: issued,
                      [Column.DILUTED_SHARES]: diluted,
                      [Column.COMMON_STOCK]:
                        shareClasses.find(({ name }) => name === 'Common Stock')?.shares || 0,
                      [Column.OTHER_SHARE_CLASSES]: otherShareClasses,
                      [Column.INVESTMENT]: '-',
                      [Column.AVERAGE_SHARE_PRICE]: toNumber(
                        shareClasses.reduce((acc, { shares }) => (acc += shares), 0) /
                          shareClasses.length,
                      ),
                      [Column.VOTING_RIGHTS]: toNumber(votingRight).toLocaleString('en-US'),
                    }}
                    key={`${i}_${issued}${type}`}
                    search={search}
                    shareClassesCount={stats.shareClasses.length}
                    stakeholders={stakeholdersFilteredData.filter(
                      (stakeholder) =>
                        stakeholder.type === type &&
                        stakeholder.shareClasses.reduce(
                          (sharesTotal, { shares }) => sharesTotal + shares,
                          0,
                        ) > 0,
                    )}
                    viewBy={viewBy}
                    visibleColumns={visibleColumns}
                  />
                );
              },
            )}
          <StakeholderTableItem
            fields={{
              [Column.NAME]: 'Equity Pools Grantable',
              [Column.STAKEHOLDER_NAME]: null,
              [Column.TYPE]: null,
              [Column.ISSUED]: null,
              [Column.DILUTED]: null,
              [Column.VOTING]: null,
              [Column.NO_ISSUED]: null,
              [Column.DILUTED_SHARES]:
                stats.pools?.reduce((acc, el) => acc + toNumber(el.dilluted), 0) || 0,
              [Column.COMMON_STOCK]: null,
              [Column.OTHER_SHARE_CLASSES]: stats.shareClasses.map(() => ''),
              [Column.INVESTMENT]: null,
              [Column.AVERAGE_SHARE_PRICE]: null,
              [Column.VOTING_RIGHTS]: null,
            }}
            search={search}
            shareClassesCount={stats.shareClasses.length}
            stakeholders={stats.pools?.map((pool) => ({
              id: pool.id,
              name: pool.name,
              diluted: pool.dilluted,
              dilutedPercentage: 0,
              invested: 0,
              issued: 0,
              issuedPercentage: 0,
              shareClasses: [],
              type: StakeholderType.VCS,
              votingPercentage: 0,
              votingRight: 0,
            }))}
            viewBy={viewBy}
            visibleColumns={visibleColumns}
          />
          <StakeholderTableItem
            className="font-[550] text-gray-800"
            fields={{
              [Column.NAME]: 'Total',
              [Column.STAKEHOLDER_NAME]: null,
              [Column.TYPE]: null,
              [Column.ISSUED]: issuedTotal.toLocaleString('en-US'),
              [Column.DILUTED]: dilutedTotal.toLocaleString('en-US'),
              [Column.VOTING]: votingTotal.toLocaleString('en-US'),
              [Column.NO_ISSUED]: noIssuedTotal.toLocaleString('en-US'),
              [Column.DILUTED_SHARES]: dilutedSharesTotal.toLocaleString('en-US'),
              [Column.COMMON_STOCK]: commonStockShareClassTotal.toLocaleString('en-US'),
              [Column.OTHER_SHARE_CLASSES]: shareClassesTotal,
              [Column.INVESTMENT]: investmentTotal.toLocaleString('en-US'),
              [Column.AVERAGE_SHARE_PRICE]: averageSharePrice,
              [Column.VOTING_RIGHTS]: votingRightTotal.toLocaleString('en-US'),
            }}
            search={search}
            viewBy={viewBy}
            visibleColumns={visibleColumns}
          />
        </>
      );

    case DetailsSortVariant.BY_SHARE_CLASS:
      const shareClassIssuedTotal =
        shareClassesFilteredData.reduce((acc, curr) => (acc += curr.issuedPercentage || 0), 0) *
        100;

      const shareClassDilutedTotal =
        shareClassesFilteredData.reduce((acc, curr) => (acc += curr.dilutedPercentage || 0), 0) *
        100;

      const shareClassVotingTotal =
        shareClassesFilteredData.reduce((acc, curr) => (acc += curr.votingPercentage || 0), 0) *
        100;

      const shareClassNoIssuedTotal = shareClassesFilteredData.reduce(
        (acc, curr) => (acc += curr.issued || 0),
        0,
      );
      const shareClassDilutedSharesTotal = shareClassesFilteredData.reduce(
        (acc, curr) => (acc += curr.diluted || 0),
        0,
      );

      const shareClassVotingRightTotal = shareClassesFilteredData.reduce(
        (acc, curr) => (acc += curr.votingRight || 0),
        0,
      );

      const shareClassTotalShares = stakeholderTypesFilteredData.reduce(
        (stakeholdersAcc, { shareClasses }) =>
          stakeholdersAcc +
          filterByAllFields(shareClasses, search).reduce(
            (shareClassAcc, { shares }) => shareClassAcc + toNumber(shares),
            0,
          ),
        0,
      );

      const shareClassTotalShareClasses = stakeholderTypesFilteredData.reduce(
        (stakeholdersAcc, { shareClasses }) =>
          stakeholdersAcc + filterByAllFields(shareClasses, search).length,
        0,
      );

      const shareClassAverageSharePrice = toNumber(
        shareClassTotalShares / shareClassTotalShareClasses,
      );

      const shareClassInvestmentTotal = shareClassesFilteredData.reduce(
        (acc, { investment }) => (acc += investment),
        0,
      );

      return (
        <>
          {shareClassesFilteredData.map(
            ({
              diluted,
              dilutedPercentage,
              issued,
              issuedPercentage,
              investment,
              name,
              votingPercentage,
              votingRight,
              id,
            }) => (
              <ShareClassTableItem
                fields={{
                  [Column.SHARE_CLASS_NAME]: null,
                  [Column.STAKEHOLDER_NAME]: null,
                  [Column.TYPE]: null,
                  [Column.NAME]: name,
                  [Column.ISSUED]: issuedPercentage * 100 || 0,
                  [Column.DILUTED]: dilutedPercentage * 100 || 0,
                  [Column.VOTING]: votingPercentage * 100 || 0,
                  [Column.NO_ISSUED]: issued.toLocaleString('en-US'),
                  [Column.DILUTED_SHARES]: diluted.toLocaleString('en-US'),
                  [Column.INVESTMENT]: investment.toLocaleString('en-US'),
                  [Column.AVERAGE_SHARE_PRICE]: toNumber(
                    stakeholdersFilteredData.reduce(
                      (acc, { shareClasses }) =>
                        (acc += toNumber(
                          filterByAllFields(shareClasses, search).reduce(
                            (acc, { shares }) => (acc += shares),
                            0,
                          ) / filterByAllFields(shareClasses, search).length,
                        )),
                      0,
                    ) /
                      stakeholdersFilteredData.filter(({ shareClasses }) =>
                        filterByAllFields(shareClasses, search).find(
                          (stakeholderShareClass) => stakeholderShareClass.id === id,
                        ),
                      ).length,
                  ),

                  [Column.VOTING_RIGHTS]: toNumber(votingRight).toLocaleString('en-US'),
                  [Column.NO_OF_STAKEHOLDERS]: stakeholdersFilteredData.filter(
                    ({ shareClasses }) =>
                      filterByAllFields(shareClasses, search).find(
                        (stakeholderShareClass) => stakeholderShareClass.id === id,
                      ) &&
                      shareClasses.reduce((sharesTotal, { shares }) => sharesTotal + shares, 0) > 0,
                  ).length,
                }}
                key={id}
                stakeholders={stakeholdersFilteredData.filter((stakeholder) =>
                  filterByAllFields(stakeholder.shareClasses, search).find(
                    (shareClass) =>
                      shareClass.id === id &&
                      stakeholder.shareClasses.reduce(
                        (sharesTotal, { shares }) => sharesTotal + shares,
                        0,
                      ) > 0,
                  ),
                )}
                viewBy={viewBy}
                visibleColumns={visibleColumns}
              />
            ),
          )}

          <ShareClassTableItem
            fields={{
              [Column.STAKEHOLDER_NAME]: null,
              [Column.TYPE]: null,
              [Column.SHARE_CLASS_NAME]: null,
              [Column.NO_OF_STAKEHOLDERS]: null,
              [Column.NAME]: 'Equity Pools Grantable',
              [Column.ISSUED]: 0,
              [Column.DILUTED]: 0,
              [Column.VOTING]: 0,
              [Column.NO_ISSUED]: 0,
              [Column.DILUTED_SHARES]:
                stats.pools?.reduce((acc, el) => acc + toNumber(el.dilluted), 0) || 0,
              [Column.INVESTMENT]: '-',
              [Column.AVERAGE_SHARE_PRICE]: 0,
              [Column.VOTING_RIGHTS]: 0,
            }}
            stakeholders={stats.pools?.map((pool) => ({
              id: pool.id,
              name: pool.name,
              diluted: pool.dilluted,
              dilutedPercentage: 0,
              invested: 0,
              issued: 0,
              issuedPercentage: 0,
              shareClasses: [],
              type: StakeholderType.VCS,
              votingPercentage: 0,
              votingRight: 0,
            }))}
            viewBy={viewBy}
            visibleColumns={visibleColumns}
          />
          <ShareClassTableItem
            className="font-[550] text-gray-800"
            fields={{
              [Column.STAKEHOLDER_NAME]: null,
              [Column.TYPE]: null,
              [Column.SHARE_CLASS_NAME]: null,
              [Column.NO_OF_STAKEHOLDERS]: stakeholdersFilteredData.reduce(
                (acc, { shareClasses }) =>
                  (acc +=
                    filterByAllFields(shareClasses, search).length > 0
                      ? filterByAllFields(shareClasses, search).length
                      : 0),
                0,
              ),
              [Column.NAME]: 'Total',
              [Column.ISSUED]: shareClassIssuedTotal.toLocaleString('en-US'),
              [Column.DILUTED]: shareClassDilutedTotal.toLocaleString('en-US'),
              [Column.VOTING]: shareClassVotingTotal.toLocaleString('en-US'),
              [Column.NO_ISSUED]: shareClassNoIssuedTotal.toLocaleString('en-US'),
              [Column.DILUTED_SHARES]: shareClassDilutedSharesTotal.toLocaleString('en-US'),
              [Column.INVESTMENT]: shareClassInvestmentTotal.toLocaleString('en-US'),
              [Column.AVERAGE_SHARE_PRICE]: shareClassAverageSharePrice,
              [Column.VOTING_RIGHTS]: shareClassVotingRightTotal.toLocaleString('en-US'),
            }}
            viewBy={viewBy}
            visibleColumns={visibleColumns}
          />
        </>
      );

    default:
      return <></>;
  }
};
