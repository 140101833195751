import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { HttpException } from '../../exceptions';
import { ApiService } from '../../services';
import { ShareClass, ShareClassOrder, ShareClassSortBy } from '../../types/share-classes.types';
import { useAppQuery } from '../useAppQuery';

export enum ShareClassesFilters {
  IN_USE = 'in-use',
  NOT_USED = 'not-used',
}

export type FilterVariant = 'all' | ShareClassesFilters;

export const useShareClasses = ({
  companyId,
  filter = 'all',
  search,
  orderBy,
  sortBy,
}: {
  companyId: string;
  filter?: FilterVariant;
  search?: string;
  orderBy?: ShareClassOrder | null;
  sortBy?: ShareClassSortBy | null;
}) => {
  const queryClient = useQueryClient();
  const {
    data: shareClasses,
    isLoading,
    refetch,
  } = useAppQuery<ShareClass[], HttpException>({
    queryKey: [
      QueryKey.GET_SHARE_CLASSES,
      companyId,
      {
        filter,
        sortBy,
        orderBy,
      },
    ],
    queryFn: () =>
      ApiService.get(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'share-classes'] },
        {
          queryParams: {
            search: search || '',
            filter: filter === 'all' ? '' : filter,
            ...(orderBy && sortBy
              ? {
                  sortBy,
                  sortOrder: orderBy,
                }
              : {}),
          },
        },
      ),
    enabled: !!companyId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SHARE_CLASSES] });
  }, [queryClient]);

  return {
    invalidateQuery,
    shareClasses: shareClasses || [],
    isLoading,
    refetch,
  };
};
