import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useExpire = () => {
  const { mutate: create, isPending } = useAppMutation([QueryKey.EXPIRE], {
    mutationFn: ({
      companyId,
      data,
    }: {
      companyId: string;
      data: { vestingTaskId: string; value: number }[];
    }) =>
      ApiService.post(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'expire'] },
        { body: data },
      ),
  });

  return { create, isPending };
};
