import { FC, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { SwitchIcon } from '../../../assets/icons';
import Button from '../../../components/Button';
import { Snapshot } from '../../../types/snapshot.types';
import { GraphVariant } from '../variables';
import { CapTableGraph } from './CapTableGraph';
import { EmptyCapTableGraph } from './EmptyCapTableGraph';

export type CapTableGraphWrapperProps = {
  wrapperClassName?: string;
  selectedGraphVariant: GraphVariant;
  isTablet: boolean;
  isDesktop: boolean;
  snapshot?: Snapshot;
  toggleGraphVariant: () => void;
};

export const CapTableGraphWrapper: FC<CapTableGraphWrapperProps> = ({
  wrapperClassName,
  selectedGraphVariant,
  isTablet,
  isDesktop,
  snapshot,
  toggleGraphVariant,
}) => {
  const data = useMemo(() => {
    if (!snapshot?.stats?.stakeholdersTypes) {
      return [];
    }

    const totalIssuedPercentage = snapshot.stats.stakeholdersTypes.reduce(
      (total, stakeholderType) => total + stakeholderType.issuedPercentage,
      0,
    );

    const totalVotingPercentage = snapshot.stats.stakeholdersTypes.reduce(
      (total, stakeholderType) => total + stakeholderType.votingPercentage,
      0,
    );

    return snapshot.stats.stakeholdersTypes.map(({ type, issuedPercentage, votingPercentage }) => ({
      type,
      issuedPercentage: (issuedPercentage * 100) / (totalIssuedPercentage || 1),
      votingPercentage: (votingPercentage * 100) / (totalVotingPercentage || 1),
    }));
  }, [snapshot?.stats?.stakeholdersTypes]);

  return (
    <div
      className={twMerge(
        'flex min-h-full flex-col items-center rounded-lg bg-gray-50 p-1',
        wrapperClassName,
      )}
    >
      <div className="flex w-full items-center justify-between gap-1 px-4">
        <span className="w-full py-3 text-left text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
          {selectedGraphVariant === GraphVariant.OWNERSHIP ? 'Ownership' : 'Voting Rights'}{' '}
          percentage
        </span>
        <Button
          className="flex w-fit items-center gap-1 whitespace-nowrap"
          onClick={toggleGraphVariant}
          styleType="NONE"
        >
          <SwitchIcon />
          <span className="text-xs font-[500] text-gray-700">
            {isTablet || isDesktop
              ? selectedGraphVariant === GraphVariant.OWNERSHIP
                ? 'Switch to Voting Rights'
                : 'Switch to Ownership'
              : 'Switch'}
          </span>
        </Button>
      </div>
      {data.length > 1 ? (
        <CapTableGraph
          data={data}
          isDesktop={isDesktop}
          isTablet={isTablet}
          variant={selectedGraphVariant}
        />
      ) : (
        <EmptyCapTableGraph isDesktop={isDesktop} isTablet={isTablet} />
      )}
    </div>
  );
};
